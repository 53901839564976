import * as actionTypes from "../actionTypes"



export const handleCampaign=(data)=>({
    type:actionTypes.CAMPAIGN_DETAIL,
    payload:{data}
})


export const handleSuccess=(data)=>({
    type:actionTypes.UPDATE_CAMPAIGN_DETAIL_SUCCESS,
    payload:{data}
})



export const handleFail=(data)=>({
    type:actionTypes.UPDATE_CAMPAIGN_DETAIL_FAIL,
    payload:{data}
})

export const loaderStart=()=>({
    type:actionTypes.CAMPAIGN_DETAIL_START
})


export const loaderOff=()=>({
    type:actionTypes.CAMPAIGN_DETAIL_OFF
})


export const fetchCampaign=()=>({
    type:actionTypes.FETCH_CAMPAIGN_DETAIL,
})

export const fetchCampaignSuccess=(data)=>({
    type:actionTypes.UPDATE_FETCH_CAMPAIGN_DETAIL_SUCCESS,
    payload:{data}
})

export const fetchCampaignFail=(data)=>({
    type:actionTypes.UPDATE_FETCH_CAMPAIGN_DETAIL_FAIL,
    payload:{data}
})


export const fetchTimezone=()=>({
    type:actionTypes.FETCH_TIMEZONE
})


export const fetchSimCards=()=>({
    type:actionTypes.FETCH_SIM_CARDS
})

export const updateFetchedSimCardSuccess=(data)=>({
    type:actionTypes.UPDATE_FETCH_SIM_CARDS_SUCCESS,
    payload:{data}
})

export const updateFetchedSimCardFail=(data)=>({
    type:actionTypes.UPDATE_FETCH_SIM_CARDS_FAIL,
    payload:{data}
})