import * as actionTypes from "../actionTypes"
import { authApi, postApiRequest } from "../actions/api"
import * as API from "../../ApiUrl"
import { loaderStart, loaderOff, updateEditRuleSuccess, updateEditRuleFail, editRuleStart, editRuleFinish } from "../actions/rule"
import history from "../../helpers/history"
import { Notification } from 'rsuite'


export const fetchRules = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.FETCH_RULE) {
        let id = action.payload.data;
        let data = {
            node_id: id
        }
        let onSuccess = actionTypes.FETCH_RULE_SUCCESS;
        let onError = actionTypes.FETCH_RULE_FAIL;
        dispatch(loaderStart())
        dispatch(authApi('GET', API.FETCH_RULES, data, onSuccess, onError, null, null))
    }
}

export const fetchRuleSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.FETCH_RULE_SUCCESS) {
        let data = action.payload;
        if (data) {
            dispatch({ type: actionTypes.UPDATE_FETCH_RULE_SUCCESS, payload: { data: data.data } })
        }
        dispatch(loaderOff())
    }
}

export const fetchRuleFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.FETCH_RULE_FAIL) {
        let data = action.payload;
        if (data) {
            dispatch({ type: actionTypes.UPDATE_FETCH_RULE_FAIL, payload: { data } })
        }
        dispatch(loaderOff())
    }
}


export const editRule = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.EDIT_RULE) {
        let data = action.payload.data;
        let onSuccess = actionTypes.EDIT_RULE_SUCCESS;
        let onError = actionTypes.EDIT_RULE_FAIL;
        dispatch(authApi('PATCH', API.FETCH_RULES, null, onSuccess, onError, data, null))
        dispatch(editRuleStart())
    }
}

export const editRuleSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.EDIT_RULE_SUCCESS) {
        let data = action.payload;
        const openNotification = () => {
            Notification.success({
                title: "Update Success.",
                description: "Your changes have been saved Successfully. "
            })
        }
        openNotification()
        if (data) {
            dispatch({ type: actionTypes.UPDATE_FETCH_RULE_SUCCESS, payload: { data: data.data } })
        }
        dispatch(editRuleFinish())
    }
}

export const editRuleFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.EDIT_RULE_FAIL) {
        let data = action.payload;
        const openNotification = () => {
            Notification.error({
                title: "Whoopps.",
                description: data.msg,
                duration: 20000
            })
        }
        openNotification()
        if (data) {
            dispatch({ type: actionTypes.UPDATE_FETCH_RULE_FAIL, payload: { data } })
        }
        dispatch(editRuleFinish())
    }
}


export const ruleMdl = [
    fetchRules,
    fetchRuleSuccess,
    fetchRuleFail,
    editRule,
    editRuleSuccess,
    editRuleFail
]