import React from 'react';
import { Switch, Redirect, BrowserRouter } from 'react-router-dom';

import { RouteWithLayout,ProtectedRoute } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  ProductList as ProductListView,
  UserList as UserListView,
  Typography as TypographyView,
  Icons as IconsView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  CreateCampaignV2
} from './views';
import { AudioScriptV2, UploadExternal, TextToSpeech, Example, AudioScriptView } from "./views/Audio-Script-V2"

import CampaignDetail from "../src/views/Campaign/campaignDetail/campaignDetail"
import CampaignAdd from "./views/Campaign/campaignAdd/campaignAdd"
import SimCardView from "./views/SimCard/SimCardView/SimCardView"
import SimCardAdd from "./views/SimCard/SimCardAdd/SimCardAdd"
// import AudioScriptView from "./views/AudioScript/AudioScriptView/AudioScriptView"
import AudioScriptAdd from "./views/AudioScript/AudioScriptAdd/AudioScriptAdd"
import CreateCampaign from "./views/Campaign/campaignAdd/createCampaign"
import FlowChart from "./views/Flow-Chart/FlowChart"
import Result from "./views/Result/Result"
const Routes = () => {
  return (

    <Switch>
      <Redirect
        exact
        from="/"
        to="/campaign"
      />
      {/* <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      /> */}
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <ProtectedRoute
        component={UserListView}
        exact
        layout={MainLayout}
        path="/campaign"
      />
      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
      />
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />

      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={CampaignDetail}
        exact layout={MainLayout}
        path="/campaign-details"
      />
      <RouteWithLayout
        component={SimCardView}
        exact layout={MainLayout}
        path="/simcardview"
      />
      <RouteWithLayout
        component={CreateCampaign}
        exact layout={MainLayout}
        path="/campaign-add-v2"
      />
      <RouteWithLayout
        component={CreateCampaignV2}
        exact layout={MainLayout}
        path="/campaign-add"
      />
      <RouteWithLayout
        component={SimCardAdd}
        exact layout={MainLayout}
        path="/simCardAdd"
      />
      {/* <RouteWithLayout
        component={AudioScriptView}
        exact layout={MainLayout}
        path="/audioScriptView"
      /> */}
      <RouteWithLayout
        component={AudioScriptAdd}
        exact layout={MainLayout}
        path="/audioScriptAdd"
      />

      <RouteWithLayout
        component={FlowChart}
        exact layout={MainLayout}
        path="/flow-chart"
      />
      <RouteWithLayout
        component={AudioScriptView}
        exact layout={MainLayout}
        path="/audio-script"
      />
      <RouteWithLayout
        component={AudioScriptV2}
        exact layout={MainLayout}
        path="/audio-script/add-script"
      />
      <RouteWithLayout
        component={UploadExternal}
        exact layout={MainLayout}
        path="/audio-script/upload-external"
      />
      <RouteWithLayout
        component={TextToSpeech}
        exact layout={MainLayout}
        path="/audio-script/text-to-speech"
      />
      <RouteWithLayout
        component={Example}
        exact layout={MainLayout}
        path="/audio-script/text-to-speech-with-datalink"
      />
      <RouteWithLayout
        component={Result}
        exact layout={MainLayout}
        path="/result/:campaign_id"
      />
      <Redirect to="/not-found" />
    </Switch>

  );
};

export default Routes;
