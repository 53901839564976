import { api } from "./_api"
import { postApi } from "./_postApi"
import { authApi } from "./_authApi"
import { flowMdl } from "./_FlowChart"
import { audioMdl } from "./_Audio"
import { campMdl } from "./_Start"
import { ruleMdl } from "./_Rule"
import { resultMdl } from "./_Result"
import { langMdl } from "./_Language"
import { voicesMdl } from "./_Voices"
import { changeMdl } from "./_ChangeAsync.js"
import { campaignMdl } from "./_Campaign"
export const Middleware = [
    authApi,
    postApi,
    api,
    ...flowMdl,
    ...audioMdl,
    ...campMdl,
    ...ruleMdl,
    ...resultMdl,
    ...langMdl,
    ...voicesMdl,
    ...changeMdl,
    ...campaignMdl
]