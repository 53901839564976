/** @format */

import * as actionTypes from "../actionTypes";
import { updateObject } from "./utility";

const initialState = {
  loading: false,
  data: null,
  error: null,
  timezone: null,
};

const updateFail = (action, state) => {
  return updateObject(state, {
    error: action.payload.data,
  });
};

const updateSuccess = (action, state) => {
  return updateObject(state, {
    data: action.payload.data,
  });
};
const updateTimezone = (action, state) => {
  return updateObject(state, {
    timezone: action.payload.data,
  });
};

const LoaderStart = (action, state) => {
  return updateObject(state, {
    loading: true,
  });
};
const LoaderOff = (action, state) => {
  return updateObject(state, {
    loading: false,
  });
};

const fetchCampaignSuccess = (action, state) => {
  return updateObject(state, {
    data: action.payload.data,
  });
};

const resetFlow = (action, state) => {
  return updateObject(state, {
    data: null,
    timezone: null,
  });
};

const StartReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CAMPAIGN_DETAIL_SUCCESS:
      return updateSuccess(action, state);
    case actionTypes.UPDATE_FETCH_TIMEZONE_SUCCESS:
      return updateTimezone(action, state);
    case actionTypes.UPDATE_CAMPAIGN_DETAIL_FAIL:
      return updateFail(action, state);
    case actionTypes.CAMPAIGN_DETAIL_START:
      return LoaderStart(action, state);
    case actionTypes.CAMPAIGN_DETAIL_OFF:
      return LoaderOff(action, state);
    case actionTypes.UPDATE_FETCH_CAMPAIGN_DETAIL_SUCCESS:
      return fetchCampaignSuccess(action, state);
    case actionTypes.RESET_FLOW_REDUX:
      return resetFlow(action, state);
    default:
      return state;
  }
};

export default StartReducer;
