import React, { Component } from 'react'
import { Select } from 'antd';
import { Row, Col, Input, Button, Collapse, Switch, Tag } from 'antd';
// import campaigndetail from "../../../assets/campaingAdd/campaign.module.css"
import * as API from '../../../ApiUrl';
import 'react-h5-audio-player/lib/styles.css'; import campaigndetail from "../../../assets/campaignDetail/campaignDetail.module.css"
import { CloseOutlined, CheckOutlined, UploadOutlined } from '@ant-design/icons';
import { NoPickup, TasksProgress, Budget, TotalProfit, NotIntrested } from '../../Dashboard/components';
import { flexbox } from '@material-ui/system';
import { Table } from 'antd';
import Loader from 'react-loader-spinner'
import axios from "axios";
import { Progress } from "rsuite"
import AudioPlayer from 'react-h5-audio-player';

import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  LinearProgress
} from '@material-ui/core';
const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

export class campaignDetail extends Component {
  constructor() {
    super();
    this.state = {
      size: 'default',
      check_db: false,
      check_script: false,
      loading: false,
      check_scriptType: true,
      campaignInfo: '',
      tableData: '',
      callResponse: '',
      token: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.scriptChange = this.scriptChange.bind(this);
  }
  async componentDidMount() {
    const token = await localStorage.getItem('token');
    const detail = await JSON.parse(localStorage.getItem('campDetail'));
    console.log('token', this.props.location.state.campaignInfo);
    this.setState({ token: token, campaignInfo: detail })
    console.log('details', this.state.campaignInfo);
    this.getCampaignLine()
    this.getCallResponse()
  }
  getCallResponse = () => {
    let data = {
      campaign: this.state.campaignInfo.key
    }
    const config = {
      headers: { "Authorization": `Bearer ${this.state.token}` }
    }
    axios.post(API.CALL_RESPONSE, data, config)
      .then(res => {
        console.log('callrespone', res);
        this.setState({ callResponse: res.data })
      })
      .catch(err => {
        console.log('errorcall', err);

      })
  }

  getCampaignLine = () => {
    this.showLoader()
    fetch(API.GET_CAMPAIGN_LINE + '?' + 'campaign' + '=' + this.state.campaignInfo.key, {
      headers: {
        "Authorization": `Bearer ${this.state.token}`,
        "Content-type": "application/json;",
      },
    })
      .then(res => res.json())
      .then(res => {
        console.log('table dtat', res);
        for (let resp of res) {
          let aud = []
          aud.push(resp.call_recording_file)
          resp.audio = aud
          console.log('setSimDate', resp);
        }
        this.setState({ tableData: res })
        this.hideLoader();
      })
  }
  showLoader = () => {
    this.setState({ loading: true })
  }
  hideLoader = () => {
    this.setState({ loading: false })
  }
  handleChange(event) {
    console.log('dat va', event)
    if (event == 'Qilin') {
      this.setState({ check_db: true });
    } else {
      this.setState({ check_db: false });
    }

  }
  scriptChange = (event) => {
    console.log('hahahah', event)
    if (event == 'Audio') {
      this.setState(
        {
          check_script: true,
          check_scriptType: false
        })


    } else {
      this.setState(
        {
          check_script: false,
          check_scriptType: true

        })
    }
  }
  render() {
    let { data, searchInput } = this.state;
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    const columns = [
      {
        title: 'Campaign Name',
        dataIndex: 'campaign_name',
        key: 1,
        sorter: (a, b) => a.campaign_name.length - b.campaign_name.length,
        sortOrder: sortedInfo.columnKey === 'campaign_name' && sortedInfo.order,
        ellipsis: true,
        width: 200
      },
      {
        title: 'Lead Id',
        dataIndex: 'lead_id',
        key: 2,
        sorter: (a, b) => a.lead_id - b.lead_id,
        sortOrder: sortedInfo.columnKey === 'lead_id' && sortedInfo.order,
        ellipsis: true,
        width: 200
      },
      {
        title: 'Customer Name',
        dataIndex: 'name',
        key: 3,
        sorter: (a, b) => a.name - b.name,
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: 'Mobile No.',
        dataIndex: 'phone',
        key: 4,
        sorter: (a, b) => a.phone - b.phone,
        sortOrder: sortedInfo.columnKey === 'phone' && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: 'Status',
        key: 5,
        dataIndex: 'status',
        width: 150
      },
      {
        title: 'Response(Keyword)',
        key: 5,
        dataIndex: 'actual_user_response',
      },
      {
        title: 'Response(Audio)',
        key: 5,
        dataIndex: 'audio',
        width: 300,
        render: audio => (
          <span>
            {
              audio.map(tag => {
                return (
                  <AudioPlayer
                    autoPlay={false}
                    src={audio}
                    onPlay={e => console.log("onPlay")}
                    style={{ borderRadius: 10, width: '100%' }}
                  />
                );
              })}
          </span>
        )
      },



    ]
    return (
      <div>
        {this.state.loading &&
          <div className='row'>
            <div className='col' style={{ zIndex: 1000, height: '100%', marginRight: 8, background: 'rgba(0,0,0,0.2)', position: 'fixed', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Loader
                type="Audio"
                color="#F35D5E"
                height={100}
                width={100}

                style={{ marginRight: '10%' }}
              />
              {/* <Progress.Line strokeColor="#F35D5E" strokeWidth="1" /> */}
            </div>
          </div>
        }
        <div  >
          <div style={{ marginLeft: 10, marginTop: '2%' }}>
            <h3 style={{ color: '#F35D5E' }}>Campaign Detail</h3>
          </div>
          <div style={{ marginLeft: '3%', marginTop: 30 }}>
            <Row>
              <Col span={12}>
                <div>
                  <Row>
                    <Col span={12}>
                      <p><span style={{ fontWeight: '900', fontSize: 17 }}>Campaign Name:</span><span style={{ marginLeft: 5 }}> {this.state.campaignInfo.name}</span> </p>
                    </Col>
                    <Col span={12}>
                      <p><span style={{ fontWeight: '900', fontSize: 17 }}>Total Records:</span><span style={{ marginLeft: 5 }}> {this.state.campaignInfo.totalRecords}</span></p>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col span={12}>
                      <p><span style={{ fontWeight: '900', fontSize: 17 }}>Script:</span><span style={{ marginLeft: 5 }}>{this.state.campaignInfo.script}</span></p>
                    </Col>
                    <Col span={12}>
                      <p><span style={{ fontWeight: '900', fontSize: 17 }}>Status:</span><span style={{ marginLeft: 5 }}> {this.state.campaignInfo.status}</span></p>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col span={12}>
                      <p><span style={{ fontWeight: '900', fontSize: 17 }}>Start Date:</span> <span style={{ marginLeft: 5 }}>{this.state.campaignInfo.startDate}</span></p>
                    </Col>
                    <Col span={12}>
                      <p> <span style={{ fontWeight: '900', fontSize: 17 }} >End Date:</span><span style={{ marginLeft: 5 }}>{this.state.campaignInfo.endDate}</span></p>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col span={12}>
                      <p><span style={{ fontWeight: '900', fontSize: 17 }}>Start Time:</span><span style={{ marginLeft: 5 }}> {this.state.campaignInfo.start_call}</span></p>
                    </Col>
                    <Col span={12}>
                      <p> <span style={{ fontWeight: '900', fontSize: 17 }} >End Time:</span><span style={{ marginLeft: 5 }}>{this.state.campaignInfo.end_call}</span></p>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col span={12}>
                      <p><span style={{ fontWeight: '900', fontSize: 17 }}>Response:</span><span style={{ marginLeft: 5 }}> {this.state.campaignInfo.response}</span></p>
                    </Col>
                    <Col span={12}>
                      {/* <p> <span style={{fontWeight:'900', fontSize:17}} >Response Type:</span><span style={{marginLeft:5}}>{this.state.campaignInfo.keywords}</span></p> */}
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={8}>
                <div style={{ display: 'flex', height: '120%', justifyContent: 'center', width: '150%' }} >
                  <div style={{
                    backgroundColor: 'black',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    paddingBottom: 15,
                    borderRadius: 15,
                    height: '75%',
                    width: '70%'
                  }}>

                    {/* <ReactAudioPlayer
                     src= {this.state.audioSrc}
                      controls
                    />  */}
                    <AudioPlayer
                      autoPlay={false}
                      src={this.state.campaignInfo.script_url}
                      onPlay={e => console.log("onPlay")}
                      style={{ width: '85%', borderRadius: 10 }}
                    />
                  </div>
                </div>
              </Col>
            </Row>


          </div>
          <div style={{ marginTop: 20, padding: 20 }}>
            <div>
              <Row>

                <Col span={4} style={{ marginRight: 0, marginBottom: 10 }}>
                  <TotalProfit name={'No. of Calls'} dataBase={this.state.callResponse.total_call} />
                </Col>
                <Col span={5} style={{ marginRight: 0, marginBottom: 10 }}>
                  {/* <TotalUsers name = {'No. of Calls'} dataBase = '1250'/> */}
                  <TasksProgress name={'interested'} dataBase={this.state.callResponse.interested} />
                </Col>
                <Col span={5} style={{ marginRight: 0, marginBottom: 10 }}>
                  <NoPickup name={'No pickup'} dataBase={this.state.callResponse.no_pickup} />
                </Col>
                <Col span={5} style={{ marginRight: 0, marginBottom: 10 }}>
                  <Budget name={'Hungup'} dataBase={this.state.callResponse.hungup} />
                </Col>
                <Col span={5} style={{ marginRight: 0, marginBottom: 10 }}>
                  <NotIntrested name={'Not interested'} dataBase={this.state.callResponse.no_interested} />
                </Col>
              </Row>
            </div>

            {/* <div>
                        <Row >
                         
                        <Col span={10} style={{marginRight:10,marginBottom:10}}>
                          <NotIntrested name = {'Not interested'} dataBase = '470'/>
                          </Col>
                          <Col span={10} style={{marginRight:10,marginBottom:10}}>
                          <TasksProgress name = {'interested'} dataBase = '770'/>
                          
                          </Col>
                          
                        </Row>
                      </div> */}
          </div>

          <div style={{ marginTop: '3%' }}>
            <Table columns={columns}
              dataSource={this.state.tableData}
              onRow={this.onClickRow}

              // onChange={this.handleChange}  
              pagination={{ pageSize: 10 }}
            />
          </div>
        </div>

      </div>

    );
  }
}

export default campaignDetail
