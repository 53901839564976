import React, { useEffect, useState } from 'react'
import { SelectOption, UploadExternal, TextToSpeech, TextToSpeechDL, AudioScriptView } from './index'
import history from "../../helpers/history"

export default function AudioScriptV2(props) {

    const [showModal, setShowModal] = useState(false)
    const [selectOption, setOption] = useState(0)
    const [comingFromFlow, setComingFromFlow] = useState(false)

    useEffect(() => {
        setShowModal(true)
        if (history.location.state !== undefined && history.location.state.flow) {
            setComingFromFlow(true)
        }
    }, [])




    return (
        <div className="audio-script-container">
            {/* <AudioScriptView show={showModal} setShow={setShowModal} /> */}
            {selectOption == 0 && <SelectOption show={showModal} setShow={setShowModal} setOption={setOption} />}
            {selectOption === 1 && <UploadExternal show={showModal} setOption={setOption} comingFromFlow={comingFromFlow} />}
            {selectOption === 2 && <TextToSpeech show={showModal} setOption={setOption} comingFromFlow={comingFromFlow} />}
            {selectOption === 3 && <TextToSpeechDL show={showModal} setOption={setOption} comingFromFlow={comingFromFlow} />}
        </div>
    )
}
