import * as actionTypes from "../actionTypes"



export const createCampaign = (data) => ({
    type: actionTypes.CREATE_CAMPAIGN,
    payload: { data }
})


export const updateCreateCampaignSuccess = (data) => ({
    type: actionTypes.UPDATE_CREATE_CAMPAIGN_SUCCESS,
    payload: { data }
})



export const updateCreateCampaignFail = (data) => ({
    type: actionTypes.UPDATE_CREATE_CAMPAIGN_FAIL,
    payload: { data }
})

export const createCampaignStart = () => ({
    type: actionTypes.CREATE_CAMPAIGN_START
})


export const createCampaignFinish = () => ({
    type: actionTypes.CREATE_CAMPAIGN_FINISH
})


export const duplicateCampaign = (data) => ({
    type: actionTypes.DUPLICATE_CAMPAIGN,
    payload: { data }
})

export const editCampaign = (data) => ({
    type: actionTypes.EDIT_CAMPAIGN,
    payload: { data }
})

// export const updateDuplicateCampaignSuccess = (data) => ({
//     type: actionTypes.UPDATE_DUPLICATE_CAMPAIGN_SUCCESS,
//     payload: { data }
// })

// export const updateDuplicateCampaignFail = (data) => ({
//     type: actionTypes.UPDATE_DUPLICATE_CAMPAIGN_FAIL,
//     payload: { data }
// })