import React, { useEffect, useState } from 'react'
import { Input, TagPicker, Button } from "rsuite"
import { connect } from "react-redux"
import * as action from "../../../../redux/actions/rule"
function EditRule({ selectedNode, item, _editRule, loading, setOption, _fetchRules }) {
    const [keyword, setKeyword] = useState('')
    const [tag, setTag] = useState([])
    const [defaultData, setDefaultData] = useState([])

    useEffect(() => {
        setKeyword(item.keyword)
        setTag(item.user_input)
        if (item.user_input.length > 0) {
            let d = [];
            item.user_input.map((item) => {
                let data = {
                    label: item,
                    value: item
                }
                defaultData.push(data)
            })
            setTag(d)
        }
    }, [])

    const goBack = () => {
        setOption(0)
        _fetchRules(selectedNode.id)
    }

    const editRule = () => {
        let data = {
            rule_type: "Voice Response",
            nodes: [
                {
                    node_id: item.node_id,
                    keyword: keyword,
                    user_input: tag.length > 0 ? tag : item.user_input,
                }
            ]
        }
        _editRule(data)
    }

    return (
        <div className="edit-rule" >
            <h4>Edit Rule</h4>
            <div className="edit-rule__form" >
                <div className="input-field" >
                    <label>Keyword</label>
                    <Input size="lg" block value={keyword} onChange={(v) => setKeyword(v)} />
                </div>

                <div className="input-field" >
                    <label>Synonyms</label>
                    <TagPicker data={defaultData} creatable onChange={(v) => setTag(v)} block defaultValue={tag} valueKey={'label'} />
                </div>
                <div className="input-field" >
                    <Button appearance="primary" block size="lg" onClick={editRule} loading={loading} >Save</Button>
                </div>
                <div className="input-field" >
                    <Button appearance="ghost" block size="lg" onClick={goBack}>Back</Button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.RuleReducer.loading
    }
}


const mapDispatchToProps = dispatch => {
    return {
        _editRule: (data) => dispatch(action.editRule(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditRule)