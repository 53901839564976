/** @format */

import * as actionTypes from "../actionTypes";
import React from "react";
import { apiRequest, authApi } from "../actions/api";
import * as API from "../../ApiUrl";
import {
  updateAudioSuccess,
  updateAudioFail,
  ttsLoaderStart,
  ttsLoaderOff,
  updateTextToSpeechSuccess,
  updateTextToSpeechFail,
  loaderStart,
  loaderOff,
  updateFetchScriptSuccess,
  updateFetchScriptFail,
  updateTTSWDLSuccess,
  updateTTSWDLFail,
  ttswdlStart,
  ttswdlOff,
  fetchAudioLoaderStart,
  fetchAudioLoaderOff,
} from "../actions/audio";
import { store } from "../store/index";
import { Notification } from "rsuite";

const showNotification = (type,title,msg) => {
  return Notification[type]({
    title: title,
    description:msg,
  });
}

export const handleAudio = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.UPLOAD_AUDIO) {
    let data = action.payload.data;
    var audios = document.createElement("audio");

    let audioData = {};
    console.log("Upload File: ", data);
    let reader = new FileReader();
    reader.readAsDataURL(data.blobFile);
    reader.onload = (result) => {
      audios.src = result.target.result;
      audios.addEventListener("loadedmetadata", () => {
        audioData.timeStamp = audios.duration.toFixed(2);
      });
      console.log("New Audio: ", result);
      audioData.src = result.target.result;
      audioData.size = result.total;
      dispatch({ type: actionTypes.ADD_AUDIO_REDUX, payload: audioData });
    };
  }
};

export const uploadAudio = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.UPLOAD_AUDIO_BACKEND) {
    let state = store.getState();
    let data = action.payload.data;
    let option = action.payload.option;
    let onSuccess = actionTypes.UPLOAD_AUDIO_SUCCESS;
    let onError = actionTypes.UPLOAD_AUDIO_FAIL;
    if (option == 1) {
      let lang = action.payload.data.lang;
      let audioData = state.AudioReducer.tempAudio;
      console.log("Aduioooo Post ;", data.file);
      console.log("Audio Data;", audioData);
      const formData = new FormData();
      formData.append("audio_file", data.file);
      formData.append("name", data.name);
      formData.append("length_in_seconds", parseInt(audioData.timeStamp));
      // formData.append("lang_code", lang.code);
      // formData.append("lang_name", lang.label);
      formData.append("audio_type", "Recorded");

      dispatch(
        authApi(
          "POST",
          API.UPLOAD_AUDIO,
          null,
          onSuccess,
          onError,
          formData,
          null
        )
      );
    }

    //Text to speech
    if (option == 2) {
      dispatch(
        authApi("POST", API.UPLOAD_AUDIO, null, onSuccess, onError, data, null)
      );
      dispatch(ttsLoaderStart());
    }

    //Text to speech with Data Link
    if (option == 3) {
      dispatch(
        authApi("POST", API.UPLOAD_AUDIO, null, onSuccess, onError, data, null)
      );
      dispatch(ttsLoaderStart());
    }
  }
};

export const handleAudioSuccess = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.UPLOAD_AUDIO_SUCCESS) {
    let data = action.payload;
    const openNotification = () => {
      return Notification.success({
        title: "Success !",
        description: "Audio Script Uploaded Successfuly.",
      });
    };

    if (data) {
      dispatch(updateAudioSuccess(data));
      openNotification();
    }
    dispatch(ttsLoaderOff());
  }
};

export const handleAudioFail = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.UPLOAD_AUDIO_FAIL) {
    const openNotification = () => {
      return Notification.error({
        title: "Whopps !",
        description: "Something went wrong!.",
      });
    };
    openNotification();
    dispatch(ttsLoaderOff());
  }
};

//Fetch Script

export const handleFetchScript = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.FETCH_SCRIPT) {
    let onSuccess = actionTypes.FETCH_SCRIPT_SUCCESS;
    let onError = actionTypes.FETCH_SCRIPT_FAIL;
    dispatch(
      authApi("GET", API.UPLOAD_AUDIO, null, onSuccess, onError, null, null)
    );
  }
};

export const handleFetchScriptSuccess = ({ dispatch }) => (next) => (
  action
) => {
  next(action);
  if (action.type === actionTypes.FETCH_SCRIPT_SUCCESS) {
    let data = action.payload;
    if (data) {
      dispatch(updateFetchScriptSuccess(data));
    }
  }
};

export const handleFetchScriptFail = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.FETCH_SCRIPT_FAIL) {
    let error = action.payload;
    if (error) {
      dispatch(updateFetchScriptFail(error));
    }
  }
};

export const sendAudioScript = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.ADD_SCRIPT) {
    let data = action.payload.data;
    let onSuccess = actionTypes.ADD_SCRIPT_SUCCESS;
    let onError = actionTypes.ADD_SCRIPT_FAIL;
    dispatch(
      authApi("POST", API.ADD_SCRIPT, null, onSuccess, onError, data, null)
    );
    dispatch(loaderStart());
  }
};

export const sendAudioScriptSuccess = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.ADD_SCRIPT_SUCCESS) {
    let data = action.payload;
    const openNotification = () => {
      return Notification.success({
        title: "Success!",
        description:"Audio script attached succesfully",
      });
    };
    if (data) {
      openNotification();
    }
    dispatch(loaderOff());
  }
};

export const sendAudioScriptFail = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.ADD_SCRIPT_FAIL) {
    let data = action.payload;
    if (data) {
      showNotification('error','Opps',data.msg)
    }
    dispatch(loaderOff());
  }
};

export const textToSpeech = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.TEXT_TO_SPEECH) {
    let data = action.payload.data;
    let onSuccess = actionTypes.TEXT_TO_SPEECH_SUCCESS;
    let onError = actionTypes.TEXT_TO_SPEECH_FAIL;
    dispatch({ type: actionTypes.RESET_TTS_REDUCER })
    dispatch(
      authApi("POST", API.TEXT_TO_SPEECH, null, onSuccess, onError, data, null)
    );
    dispatch(ttsLoaderStart());
  }
};

export const textToSpeechSuccess = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.TEXT_TO_SPEECH_SUCCESS) {
    let data = action.payload;
    if (data) {
      dispatch(updateTextToSpeechSuccess(data));
    }
    dispatch(ttsLoaderOff());
  }
};

export const textToSpeechFail = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.TEXT_TO_SPEECH_FAIL) {
    let error = action.payload;
    if (error) {
      const openNotification = () => {
        return Notification.error({
          title: "Whopps !",
          description: error.msg,
        });
      };
      openNotification();
      dispatch(updateTextToSpeechFail(error));
    }
    dispatch(ttsLoaderOff());
  }
};

export const ttswdl = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.TTSWDL) {
    let data = action.payload.data;
    let onSuccess = actionTypes.TTSWDL_SUCCESS;
    let onError = actionTypes.TTSWDL_FAIL;
    dispatch(authApi("POST", API.TTSWDL, null, onSuccess, onError, data, null));
    dispatch(ttswdlStart());
  }
};

export const ttswdlSuccess = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.TTSWDL_SUCCESS) {
    let data = action.payload;
    if (data) {
      dispatch(updateTTSWDLSuccess(data));
    }
    dispatch(ttswdlOff());
  }
};

export const ttswdlFail = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.TTSWDL_FAIL) {
    let error = action.payload;
    console.log("Error: ", error);
    if (error) {
      dispatch(updateTTSWDLFail(error));
    }
    dispatch(ttswdlOff());
  }
};

//------------------Fetch Audio Flow -----------------

export const fetchAudioFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.FETCH_SELECTED_AUDIO_FLOW) {
    let id = action.payload.data;
    let onSuccess = actionTypes.FETCH_SELECTED_AUDIO_SUCCESS;
    let onError = actionTypes.FETCH_SELECTED_AUDIO_FAIL;
    let data = {
      node_id: id,
    };
    dispatch(
      authApi("GET", API.ADD_SCRIPT, data, onSuccess, onError, null, null)
    );
    dispatch(fetchAudioLoaderStart());
  }
};

export const fetchAudioFlowSuccess = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.FETCH_SELECTED_AUDIO_SUCCESS) {
    let data = action.payload;
    if (data) {
      dispatch({
        type: actionTypes.UPDATE_FETCH_SELECTED_AUDIO_SUCCESS,
        payload: { data },
      });
    }
    dispatch(fetchAudioLoaderOff());
  }
};

export const fetchAudioFlowFail = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.FETCH_SELECTED_AUDIO_FAIL) {
    let data = action.payload;
    if (data) {
      dispatch({
        type: actionTypes.UPDATE_FETCH_SELECTED_AUDIO_FAIL,
        payload: { data },
      });
    }
    dispatch(fetchAudioLoaderOff());
  }
};

export const audioMdl = [
  handleAudio,
  handleAudioSuccess,
  handleAudioFail,
  uploadAudio,
  handleFetchScript,
  handleFetchScriptSuccess,
  handleFetchScriptFail,
  sendAudioScript,
  sendAudioScriptSuccess,
  sendAudioScriptFail,
  textToSpeech,
  textToSpeechFail,
  textToSpeechSuccess,
  ttswdl,
  ttswdlSuccess,
  ttswdlFail,
  fetchAudioFlow,
  fetchAudioFlowSuccess,
  fetchAudioFlowFail,
];
