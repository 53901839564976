import React, { Component } from "react";
import "rsuite/lib/styles/index.less";
import "./App.css";
import { Router, Redirect, bro } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Chart } from "react-chartjs-2";
import { ThemeProvider } from "@material-ui/styles";
import validate from "validate.js";
import * as API from "./ApiUrl";
import { chartjs } from "./helpers";
import theme from "./theme";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/scss/index.scss";
import validators from "./common/validators";
import Routes from "./Routes";
import history from "./helpers/history";
const browserHistory = createBrowserHistory();
Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
});

validate.validators = {
  ...validate.validators,
  ...validators,
};

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: true,
    };
  }
  async componentWillMount() {
    const localToken = await localStorage.getItem("token");
    console.log("token", localToken);

    if (localToken) {
      console.log("this props: ");
      console.log("Redirecting You the Dashboard!!");
      if (localToken) {
        fetch(`${API.USER_PROFILE}`, {
          headers: {
            Authorization: `Bearer ${localToken}`,
            "Content-type": "application/json;",
          },
        })
          .then((res) => res.json())
          .then((res) => {
            console.log("user info", res);

            if (
              res.code == "token_not_valid" ||
              res.code == "token_not_valid"
            ) {
              console.log("token expire");
              this.setState({ login: false });
              console.log("token expire", this.state.login);
            } else {
              this.setState({ login: true });
              localStorage.setItem("user", JSON.stringify(res));
            }
          });
      }
    } else {
      // localStorage.clear();
      this.setState({ login: false });
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <Routes />
          {!this.state.login && <Redirect to="/sign-in" />}
        </Router>
      </ThemeProvider>
    );
  }
}
