import React, { Component } from 'react'
import 'react-table-6/react-table.css'
import { makeStyles } from '@material-ui/styles';
import {Link} from "react-router-dom";
import {Modal, Button, Row, Col, Form} from 'react-bootstrap';
import validator from "validator";
import ReactDOM from "react-dom";
import ReactCustomFlagSelect from "react-custom-flag-select";
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";
import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
// import { FLAGS } from "./consts";
const find = (arr, obj) => {
  const res = [];
  arr.forEach(o => {
    let match = false;
    Object.keys(obj).forEach(i => {
      if (obj[i] === o[i]) {
        match = true;
      }
    });
    if (match) {
      res.push(o);
    }
  });
  return res;
};

const FLAG_SELECTOR_OPTION_LIST = [
  {
    id: "1",
    name: "US",
    displayText: "US(1)",
    locale: "en-US",
    flag: require('../../../image/flags/us.svg') 
  },
  {
    id: "86",
    name: "中国",
    displayText: "中国(86)",
    locale: "zh-CN",
    // flag: require('../../../image/flags/uk.svg')
  }
];
const DEFAULT_AREA_CODE = FLAG_SELECTOR_OPTION_LIST[0].id;

class SimCardAdd extends Component { 
    constructor(){
        super();
        this.state = {
          areaCode: DEFAULT_AREA_CODE,
          phone: "",
          hasPhoneError: true,
          validate: false,
         showOtp: false
        };
     
      }
     
      handleButtonClick(e, row) {
      if(row.OtpStatus == 'unverified'){
        this.setState({showModal:true})
        console.log('haha', this.state.showModal );
      } 
      }
    
      render() {
        const { areaCode, phone, validate } = this.state;
        const currentItem = find(FLAG_SELECTOR_OPTION_LIST, { id: areaCode })[0];
        return (
         <div>hi</div>
        );
      }
}

export default SimCardAdd;
