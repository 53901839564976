/** @format */

//To request api
export const API_REQUEST = "API_REQUEST";
export const POST_API_REQUEST = "POST_API_REQUEST";
export const AUTH_API_REQUEST = "AUTH_API_REQUEST";
//--------------To show the loading state in UI----------
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const FETCH_FLOW_DETAIL = "FETCH_FLOW_DETAIL";
export const FETCH_FLOW_DETAIL_SUCCESS = "FETCH_FLOW_DETAIL_SUCCESS";
export const FETCH_FLOW_DETAIL_FAIL = "FETCH_FLOW_DETAIL_FAIL";
export const UPDATE_FLOW_DETAIL_SUCCESS = "UPDATE_FLOW_DETAIL_SUCCESS";
export const UPDATE_FLOW_DETAIL_FAIL = "UPDATE_FLOW_DETAIL_FAIL";

export const FLOW_DETAIL = "FLOW_DETAIL";
export const FLOW_DETAIL_POST_SUCCESS = "FLOW_DETAIL_POST_SUCCESS";
export const FLOW_DETAIL_POST_FAIL = "FLOW_DETAIL_POST_FAIL";

export const FLOW_LOADER_START = "FLOW_LOADER_START";
export const FLOW_LOADER_OFF = "FLOW_LOADER_OFF";
export const CREATE_NODE = "CREATE_NODE";
export const CREATE_NODE_SUCCESS = "CREATE_NODE_SUCCESS";
export const CREATE_NODE_FAIL = "CREATE_NODE_FAIL";
export const UPDATE_NODE_SUCCESS = "UPDATE_NODE_SUCCESS";
export const UPDATE_NODE_FAIL = "UPDATE_NODE_FAIL";
export const UPDATE_NODE_REDUX = "UPDATE_NODE_REDUX";
export const UPDATE_NODE_REDUX_SUCCESS = "UPDATE_NODE_REDUX_SUCCESS";
export const NODE_START = "NODE_START";
export const NODE_OFF = "NODE_OFF";

export const RESET_FLOW = "RESET_FLOW";
export const RESET_FLOW_REDUX = "RESET_FLOW_REDUX";

// To Upload Audio

export const UPLOAD_AUDIO = "UPLOAD_AUDIO";
export const UPLOAD_AUDIO_START = "UPLOAD_AUDIO_START";
export const UPLOAD_AUDIO_OFF = "UPLOAD_AUDIO_OFF";
export const UPLOAD_AUDIO_SUCCESS = "UPLOAD_AUDIO_SUCCESS";
export const UPLOAD_AUDIO_FAIL = "UPLOAD_AUDIO_FAIL";
export const UPDATE_AUDIO_SUCCESS = "UPDATE_AUDIO_SUCCESS";
export const UPDATE_AUDIO_FAIL = "UPDATE_AUDIO_FAIL";
export const ADD_AUDIO_REDUX = "ADD_AUDIO_REDUX";
export const AUDIO_UPLOAD_PERCENT = "AUDIO_UPLOAD_PERCENT";
export const UPLOAD_AUDIO_BACKEND = "UPLOAD_AUDIO_BACKEND";

// Start Flow

export const CAMPAIGN_DETAIL = "CAMPAIGN_DETAIL";
export const CAMPAIGN_DETAIL_START = "CAMPAIGN_DETAIL_START";
export const CAMPAIGN_DETAIL_OFF = "CAMPAIGN_DETAIL_OFF";
export const CAMPAIGN_DETAIL_SUCCESS = "CAMPAIGN_DETAIL_SUCCESS";
export const CAMPAIGN_DETAIL_FAIL = "CAMPAIGN_DETAIL_FAIL";
export const UPDATE_CAMPAIGN_DETAIL_SUCCESS = "UPDATE_CAMPAIGN_DETAIL_SUCCESS";
export const UPDATE_CAMPAIGN_DETAIL_FAIL = "UPDATE_CAMPAIGN_DETAIL_FAIL";

export const FETCH_CAMPAIGN_DETAIL = "FETCH_CAMPAIGN_DETAIL";
export const FETCH_CAMPAIGN_DETAIL_START = "FETCH_CAMPAIGN_DETAIL_START";
export const FETCH_CAMPAIGN_DETAIL_OFF = "FETCH_CAMPAIGN_DETAIL_OFF";
export const FETCH_CAMPAIGN_DETAIL_SUCCESS = "FETCH_CAMPAIGN_DETAIL_SUCCESS";
export const FETCH_CAMPAIGN_DETAIL_FAIL = "FETCH_CAMPAIGN_DETAIL_FAIL";
export const UPDATE_FETCH_CAMPAIGN_DETAIL_SUCCESS =
  "UPDATE_FETCH_CAMPAIGN_DETAIL_SUCCESS";
export const UPDATE_FETCH_CAMPAIGN_DETAIL_FAIL =
  "UPDATE_FETCH_CAMPAIGN_DETAIL_FAIL";

export const FETCH_SCRIPT = "FETCH_SCRIPT";
export const FETCH_SCRIPT_START = "FETCH_SCRIPT_START";
export const FETCH_SCRIPT_OFF = "FETCH_SCRIPT_OFF";
export const FETCH_SCRIPT_SUCCESS = "FETCH_SCRIPT_SUCCESS";
export const FETCH_SCRIPT_FAIL = "FETCH_SCRIPT_FAIL";
export const UPDATE_FETCH_SCRIPT_SUCCESS = "UPDATE_FETCH_SCRIPT_SUCCESS";
export const UPDATE_FETCH_SCRIPT_FAIL = "UPDATE_FETCH_SCRIPT_FAIL";

//post script from audio activitity
export const ADD_SCRIPT = "ADD_SCRIPT";
export const ADD_SCRIPT_START = "ADD_SCRIPT_START";
export const ADD_SCRIPT_OFF = "ADD_SCRIPT_OFF";
export const ADD_SCRIPT_SUCCESS = "ADD_SCRIPT_SUCCESS";
export const ADD_SCRIPT_FAIL = "ADD_SCRIPT_FAIL";
export const UPDATE_ADD_SCRIPT_SUCCESS = "UPDATE_ADD_SCRIPT_SUCCESS";
export const UPDATE_ADD_SCRIPT_FAIL = "UPDATE_ADD_SCRIPT_FAIL";



//-------------------- Get the supported languages----------------------
export const GET_LANGUAGE = "GET_LANGUAGE"
export const GET_LANGUAGE_START = "GET_LANGUAGE_START"
export const GET_LANGUAGE_FINISH = "GET_LANGUAGE_FINISH"
export const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS"
export const GET_LANGUAGE_FAIL = "GET_LANGUAGE_FAIL"
export const UPDATE_GET_LANGUAGE_FAIL = "UPDATE_GET_LANGUAGE_FAIL"
export const UPDATE_GET_LANGUAGE_SUCCESS = "UPDATE_GET_LANGUAGE_SUCCESS"

//text to speech

export const TEXT_TO_SPEECH = "TEXT_TO_SPEECH";
export const TEXT_TO_SPEECH_START = "TEXT_TO_SPEECH_START";
export const TEXT_TO_SPEECH_OFF = "TEXT_TO_SPEECH_OFF";
export const TEXT_TO_SPEECH_SUCCESS = "TEXT_TO_SPEECH_SUCCESS";
export const TEXT_TO_SPEECH_FAIL = "TEXT_TO_SPEECH_FAIL";
export const UPDATE_TEXT_TO_SPEECH_SUCCESS = "UPDATE_TEXT_TO_SPEECH_SUCCESS";
export const UPDATE_TEXT_TO_SPEECH_FAIL = "UPDATE_TEXT_TO_SPEECH_FAIL";
export const RESET_TTS_REDUCER = "RESET_TTS_REDUCER";

//------------------------Get Voices----------------------
export const GET_VOICES = "GET_VOICES"
export const GET_VOICES_START = "GET_VOICES_START"
export const GET_VOICES_FINISH = "GET_VOICES_FINISH"
export const GET_VOICES_SUCCESS = "GET_VOICES_SUCCESS"
export const GET_VOICES_FAIL = "GET_VOICES_FAIL"
export const UPDATE_GET_VOICES_FAIL = "UPDATE_GET_VOICES_FAIL"
export const UPDATE_GET_VOICES_SUCCESS = "UPDATE_GET_VOICES_SUCCESS"
export const RESET_VOICE = "RESET_VOICE"

//-----------MISECELLENIOUS--------------------------
export const HANDLE_CHANGE = "HANDLE_CHANGE"
//text to speech with data link
export const TTSWDL = "TTSWDL";
export const TTSWDL_START = "TTSWDL_START";
export const TTSWDL_OFF = "TTSWDL_OFF";
export const TTSWDL_SUCCESS = "TTSWDL_SUCCESS";
export const TTSWDL_FAIL = "TTSWDL_FAIL";
export const UPDATE_TTSWDL_SUCCESS = "UPDATE_TTSWDL_SUCCESS";
export const UPDATE_TTSWDL_FAIL = "UPDATE_TTSWDL_FAIL";

//Delete Node

export const DELETE_NODE = "DELETE_NODE";
export const DELETE_NODE_START = "DELETE_NODE_START";
export const DELETE_NODE_OFF = "DELETE_NODE_OFF";
export const DELETE_NODE_SUCCESS = "DELETE_NODE_SUCCESS";
export const DELETE_NODE_FAIL = "DELETE_NODE_FAIL";

export const FETCH_SELECTED_AUDIO_FLOW = "FETCH_SELECTED_AUDIO_FLOW";
export const FETCH_SELECTED_AUDIO_FLOW_START =
  "FETCH_SELECTED_AUDIO_FLOW_START";
export const FETCH_SELECTED_AUDIO_FLOW_OFF = "FETCH_SELECTED_AUDIO_FLOW_OFF";
export const FETCH_SELECTED_AUDIO_SUCCESS = "FETCH_SELECTED_AUDIO_FLOW_SUCCESS";
export const FETCH_SELECTED_AUDIO_FAIL = "FETCH_SELECTED_AUDIO_FLOW_FAIL";
export const UPDATE_FETCH_SELECTED_AUDIO_FAIL =
  "UPDATE_FETCH_SELECTED_AUDIO_FLOW_FAIL";
export const UPDATE_FETCH_SELECTED_AUDIO_SUCCESS =
  "UPDATE_FETCH_SELECTED_AUDIO_FLOW_SUCCESS";

//fetch timezone

export const FETCH_TIMEZONE = "FETCH_TIMEZONE";
export const FETCH_TIMEZONE_SUCCESS = "FETCH_TIMEZONE_SUCCESS";
export const FETCH_TIMEZONE_FAIL = "FETCH_TIMEZONE_FAIL";
export const UPDATE_FETCH_TIMEZONE_FAIL = "UPDATE_FETCH_TIMEZONE_FAIL";
export const UPDATE_FETCH_TIMEZONE_SUCCESS = "UPDATE_FETCH_TIMEZONE_SUCCESS";

export const FETCH_RULE = "FETCH_RULE";
export const FETCH_RULE_START = "FETCH_RULE_START";
export const FETCH_RULE_OFF = "FETCH_RULE_OFF";
export const FETCH_RULE_SUCCESS = "FETCH_RULE_SUCCESS";
export const FETCH_RULE_FAIL = "FETCH_RULE_FAIL";
export const UPDATE_FETCH_RULE_FAIL = "UPDATE_FETCH_RULE_FAIL";
export const UPDATE_FETCH_RULE_SUCCESS = "UPDATE_FETCH_RULE_SUCCESS";



export const FETCH_RESULT = "FETCH_RESULT";
export const FETCH_RESULT_START = "FETCH_RESULT_START";
export const FETCH_RESULT_OFF = "FETCH_RESULT_OFF";
export const FETCH_RESULT_SUCCESS = "FETCH_RESULT_SUCCESS";
export const FETCH_RESULT_FAIL = "FETCH_RESULT_FAIL";
export const UPDATE_FETCH_RESULT_FAIL = "UPDATE_FETCH_RESULT_FAIL";
export const UPDATE_FETCH_RESULT_SUCCESS = "UPDATE_FETCH_RESULT_SUCCESS";


export const EDIT_RULE = "EDIT_RULE";
export const EDIT_RULE_START = "EDIT_RULE_START";
export const EDIT_RULE_FINISH = "EDIT_RULE_FINISH";
export const EDIT_RULE_SUCCESS = "EDIT_RULE_SUCCESS";
export const EDIT_RULE_FAIL = "EDIT_RULE_FAIL";
export const UPDATE_EDIT_RULE_SUCCESS = "UPDATE_EDIT_RULE_SUCCESS";
export const UPDATE_EDIT_RULE_FAIL = "UPDATE_EDIT_RULE_FAIL";


export const RESET_RESULT = "RESET_RESULT"
export const RESET_RESULT_REDUX = "RESET_RESULT_REDUX"




export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const CREATE_CAMPAIGN_START = "CREATE_CAMPAIGN_START";
export const CREATE_CAMPAIGN_FINISH = "CREATE_CAMPAIGN_FINISH";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_FAIL = "CREATE_CAMPAIGN_FAIL";
export const UPDATE_CREATE_CAMPAIGN_FAIL = "UPDATE_CREATE_CAMPAIGN_FAIL";
export const UPDATE_CREATE_CAMPAIGN_SUCCESS = "UPDATE_CREATE_CAMPAIGN_SUCCESS";
export const RESET_CREATE_CAMPAIGN = "RESET_CREATE_CAMPAIGN"


export const DUPLICATE_CAMPAIGN = "DUPLICATE_CAMPAIGN"
export const DUPLICATE_CAMPAIGN_START = "DUPLICATE_CAMPAIGN_START"
export const DUPLICATE_CAMPAIGN_FINISH = "DUPLICATE_CAMPAIGN_FINISH"
export const DUPLICATE_CAMPAIGN_SUCCESS = "DUPLICATE_CAMPAIGN_SUCCESS"
export const DUPLICATE_CAMPAIGN_FAIL = "DUPLICATE_CAMPAIGN_FAIL"
export const UPDATE_DUPLICATE_CAMPAIGN_SUCCESS = "UPDATE_DUPLICATE_CAMPAIGN_SUCCESS"
export const UPDATE_DUPLICATE_CAMPAIGN_FAIL = "UPDATE_DUPLICATE_CAMPAIGN_FAIL"


export const EDIT_CAMPAIGN = "EDIT_CAMPAIGN";
export const EDIT_CAMPAIGN_START = "EDIT_CAMPAIGN_START";
export const EDIT_CAMPAIGN_FINISH = "EDIT_CAMPAIGN_FINISH";
export const EDIT_CAMPAIGN_SUCCESS = "EDIT_CAMPAIGN_SUCCESS";
export const EDIT_CAMPAIGN_FAIL = "EDIT_CAMPAIGN_FAIL";
export const UPDATE_EDIT_CAMPAIGN_FAIL = "UPDATE_EDIT_CAMPAIGN_FAIL";
export const UPDATE_EDIT_CAMPAIGN_SUCCESS = "UPDATE_EDIT_CAMPAIGN_SUCCESS";


export const FETCH_OVERALL_RESULT = "FETCH_OVERALL_RESULT";
export const FETCH_OVERALL_RESULT_START = "FETCH_OVERALL_RESULT_START";
export const FETCH_OVERALL_RESULT_FINISH = "FETCH_OVERALL_RESULT_FINISH";
export const FETCH_OVERALL_RESULT_SUCCESS = "FETCH_OVERALL_RESULT_SUCCESS";
export const FETCH_OVERALL_RESULT_FAIL = "FETCH_OVERALL_RESULT_FAIL";
export const UPDATE_OVERALL_RESULT_SUCCESS = "UPDATE_OVERALL_RESULT_SUCCESS"
export const UPDATE_OVERALL_RESULT_FAIL = "UPDATE_OVERALL_RESULT_FAIL"


export const FETCH_SIM_CARDS= "FETCH_SIM_CARDS";
export const FETCH_SIM_CARDS_START= "FETCH_SIM_CARDS_START";
export const FETCH_SIM_CARDS_FINISH= "FETCH_SIM_CARDS_FINISH";
export const FETCH_SIM_CARDS_SUCCESS= "FETCH_SIM_CARDS_SUCCESS";
export const FETCH_SIM_CARDS_FAIL= "FETCH_SIM_CARDS_FAIL";
export const UPDATE_FETCH_SIM_CARDS_FAIL= "UPDATE_FETCH_SIM_CARDS_FAIL";
export const UPDATE_FETCH_SIM_CARDS_SUCCESS= "UPDATE_FETCH_SIM_CARDS_SUCCESS";