/** @format */

import React, { Component } from "react";
import { Row, Table, Input, Select, Upload, message } from "antd";
import campaign from "../../../assets/campaingAdd/campaign.module.css";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import ReactAudioPlayer from "react-audio-player";
import Speech from "react-speech";
import { Redirect } from "react-router-dom";
import * as Language from "../../AudioScript/AudioScriptAdd/LanguageCode";
import * as timeZoneCode from "./Timzone";
import * as gax from "google-gax";
import * as API from "../../../../src/ApiUrl";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import * as XLSX from "xlsx";
import readXlsxFile from "read-excel-file";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import { Button } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const { Option } = Select;
var time = [];
time = timeZoneCode.timeZone;
var timeZoneArray = [];
for (let i = 0; i < time.length; i++) {
  timeZoneArray.push(<Option key={time[i].utc}>{time[i].text}</Option>);
}
var lang = [];
lang = Language.isoLangs;
var languageCode = [];
for (let i = 0; i < lang.length; i++) {
  languageCode.push(<Option key={lang[i].code}>{lang[i].name}</Option>);
}
var csvFile = [];
var bufferString;
var campaignData;
var campaignInvalidData = [];
var dataSet = false;
const MyModel = (props) => (
  <Modal
    show={props.isHiddenAudio}
    onHide={props.onCloseAudio}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title>Instructions for filling the Excel / CSV</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h6>Field Descriptions :</h6>
      <ul style={{ marginLeft: 15 }}>
        <li>Campaign : Name of the campaign (Required Field)</li>
        <li>
          Lead : Unique Lead ID to identify the lead in your system ( Optional
          Field)
        </li>
        <li>Name : Name of the lead (Required Field)</li>
        <li>
          Number - Phone number of the lead WITHOUT ISD Code ( eg 0987654321 for
          Vietnam) (Required Field)
        </li>
      </ul>
      <h6>Other points:</h6>
      <ul style={{ marginLeft: 15 }}>
        <li>Please do not change the order of columns in the excel / CSV</li>
        <li>Please do not change the heading in the excel / CSV</li>
      </ul>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={props.onCloseAudio}>Close</Button>
    </Modal.Footer>
  </Modal>
);

const get = () => {
  console.log("checkone3");
  dataSet = true;
  console.log("checkone3", dataSet);
};

class CreateCamppaign extends Component {
  constructor() {
    super();
    this.state = {
      tableData: "",
      fileType: true,
      successRecord: false,
      token: "",
      loading: false,
      campaignSuccess: false,
      totalCampaign: 0,
      totalCampaignByName: 0,
      successCampaign: 0,
      instructionModel: false,
      campaignName: "",
      errorMsg: false,
    };

    this.getData = this.getData.bind(this);
  }
  showLoader = () => {
    this.setState({ loading: true });
  };
  hideLoader = () => {
    this.setState({ loading: false });
  };
  notify = () => {
    toast.warn("Uploaded File Does not Contain any Valid Data", {
      position: "top-center",
    });
  };
  async componentDidMount() {
    this.setState({ campaignSuccess: false });
    const token = await localStorage.getItem("token");
    console.log("token", token);
    this.setState({ token: token });
  }
  handleChange = (e) => {
    e.preventDefault();
    e.persist();
    console.log("Fileeee excle: ", e);
    this.setState({ fileType: false, successRecord: true });
    var fileReader = new FileReader();
    fileReader.onload = function(event) {
      console.log("enent:", event);
      var data = event.target.result;

      var workbook = XLSX.read(data, {
        type: "binary",
      });
      workbook.SheetNames.forEach((sheet) => {
        let rowObject = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[sheet]
        );
        let jsonObject = JSON.stringify(rowObject);
        console.log(JSON.parse(jsonObject));
        campaignData = JSON.parse(jsonObject);
        let total = campaignData.length;
        console.log("total", total);
      });
    };
    fileReader.readAsBinaryString(e.target.files[0]);
  };
  showModal = (e) => {
    console.log("show ho jaaaa");
    this.setState({ instructionModel: true });
  };

  hideModal = (e) => {
    this.setState({ instructionModel: false });
  };
  instruction = () => {
    this.showModal();
  };
  createCampaign = (r) => {
    if (this.state.campaignName == "") {
      this.setState({ errorMsg: true });
    } else {
      if (this.state.tableData) {
        this.showLoader();
        console.log("table hai", this.state.tableData);
        let myArray = this.state.tableData.filter((e) => {
          return e.detail == undefined;
        });

        console.log("after filter", myArray);
        if (myArray.length !== 0) {
          let data1 = {
            campaign: this.state.campaignName,
            data: myArray,
          };
          const config = {
            headers: { Authorization: `Bearer ${this.state.token}` },
          };
          console.log("data to upload: ", data1);
          // axios
          //   .post(API.CREATE_CAMPAIGN_LINE, data1, config)
          //   .then((res) => {
          //     this.hideLoader();
          //     this.hideLoader();
          //     this.setState({ campaignSuccess: true });
          //     Swal.fire({
          //       icon: "success",
          //       title: "Campaign Created Successfully",
          //       showConfirmButton: false,
          //       timer: 1500,
          //     });
          //   })
          //   .catch((err) => {
          //     this.hideLoader();
          //     console.log("error", err.response?.data.msg);
          //     Swal.fire({
          //       icon: "error",
          //       title: "Oops...",
          //       text: err.response.data.msg,
          //     });
          //   });
        } else {
          this.notify();
          this.hideLoader();
        }
      }
    }
  };
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      this.setState({ errorMsg: false });
      return;
    }
  };
  campaignNameChange = (e) => {
    console.log("csmpaign name", e.target.value);
    this.setState({ campaignName: e.target.value });
  };
  getData = () => {
    campaignInvalidData = [];
    if (this.state.successRecord === true) {
      for (let d of campaignData) {
        console.log(/^[6-9]\d{9}$/.test(d.number));
        // if(/^[6-9]\d{9}$/.test(d.number) === false) {
        // d.detail = 'Phone Number Formate is not Correct'
        // campaignInvalidData.push(d)
        // console.log('invalid data', campaignInvalidData);
        // }
      }
      let total = campaignData.length;
      this.setState({ totalCampaign: total });
      console.log(campaignData.length, campaignInvalidData.length);

      let invalidCampaignLength =
        campaignData.length - campaignInvalidData.length;
      this.setState({
        tableData: campaignData,
        successCampaign: invalidCampaignLength,
        totalCampaignByName: 1,
        successRecord: false,
      });
      console.log("invalid", invalidCampaignLength);
      console.log("total", total);
    }
  };
  dowloadFormate = () => {
    window.open("/api/campaigns/excel_file_format/");
  };
  dowloadFormateCsv = () => {
    window.open("/api/campaigns/csv_file_format/");
  };
  render() {
    if (this.state.campaignSuccess == true) {
      console.log("hhhh");
      return <Redirect to="/campaign" />;
    }
    let { data, searchInput } = this.state;
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    const columns = [
      // {
      //   title: 'Campaign Name',
      //   dataIndex: 'campaign',
      //   key: 1,
      //   sorter: (a, b) => a.campaign.length - b.campaign.length,
      //   sortOrder: sortedInfo.columnKey === 'campaign' && sortedInfo.order,
      //   ellipsis: true,
      // },
      {
        title: "First Name",
        dataIndex: "first_name",
        key: 3,
        sorter: (a, b) => a.first_name - b.first_name,
        sortOrder: sortedInfo.columnKey === "first_name" && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: "Last Name",
        dataIndex: "last_name",
        key: 3,
        sorter: (a, b) => a.last_name - b.last_name,
        sortOrder: sortedInfo.columnKey === "last_name" && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: "Lead Id",
        dataIndex: "lead",
        key: 2,
        sorter: (a, b) => a.lead - b.lead,
        sortOrder: sortedInfo.columnKey === "lead" && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: "Mobile No.",
        dataIndex: "number",
        key: 4,
        sorter: (a, b) => a.number - b.number,
        sortOrder: sortedInfo.columnKey === "number" && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: "Exception Detail",
        key: 5,
        dataIndex: "detail",
      },
    ];
    return (
      <div>
        {this.state.loading && (
          <div className="row">
            <div
              className="col"
              style={{
                zIndex: 1000,
                height: "100%",
                marginRight: 8,
                background: "rgba(0,0,0,0.2)",
                position: "fixed",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader
                type="Audio"
                color="#F35D5E"
                height={100}
                width={100}
                style={{ marginRight: "10%" }}
              />
            </div>
          </div>
        )}
        <div style={{ marginLeft: 10, marginTop: 30 }}>
          <h3 style={{ color: "#F35D5E" }}>Create Campaign</h3>
        </div>
        <div
          className="row"
          style={{ marginLeft: 30, marginTop: 40, marginRight: 0 }}
        >
          <div className="col">
            <span>Upload Excel/CSV file:</span>
            <Input
              type="file"
              className="form-control"
              onChange={this.handleChange}
              placeholder="Upload"
              style={{ marginLeft: 18 }}
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
          </div>
          <div className="col">
            {/* <span>Campaign Name:</span> */}
            <Input
              className="form-control"
              onChange={this.campaignNameChange}
              placeholder="Campaign Name"
              style={{ marginLeft: 18, marginTop: 23 }}
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
          </div>
          {/* <div className='col'>
        <div style={{marginTop:25}}>
        <Select
                          showSearch
                          style={{ width: '100%', marginLeft:8 }}
                          placeholder="Select a TimeZone"
                          optionFilterProp="children"
                          // onChange={this.langCodeSelect}
                          // onFocus={onFocus}
                          // onBlur={onBlur}
                          // onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {timeZoneArray}
                        </Select>
        </div>
      </div> */}
          {/* <div className='col'>
        <div style={{marginTop:25}}>
        <Select
                          showSearch
                          style={{ width: '100%' }}
                          placeholder="Select a Language"
                          optionFilterProp="children"
                          // onChange={this.langCodeSelect}
                          // onFocus={onFocus}
                          // onBlur={onBlur}
                          // onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {languageCode}
                        </Select>
        </div>
      </div> */}
          <div className="col">
            <Button
              color="primary"
              variant="contained"
              style={{
                backgroundColor: "#F35D5E",
                color: "white",
                marginTop: 20,
                width: "75%",
              }}
              onClick={this.instruction}
            >
              Instructions
            </Button>
          </div>
        </div>
        <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
          <div
            style={{
              marginLeft: "4%",
              marginTop: 8,
              borderBottom: "1px solid",
              cursor: "pointer",
              width: "fit-content",
              color: "blueviolet",
            }}
          >
            <span onClick={this.dowloadFormate}>Download Excel Template</span>
          </div>
          <div
            style={{
              marginLeft: 46,
              marginTop: 8,
              borderBottom: "1px solid",
              cursor: "pointer",
              width: "fit-content",
              color: "blueviolet",
            }}
          >
            <span onClick={this.dowloadFormateCsv}>Download CSV Template</span>
          </div>
        </div>
        <div
          className="row"
          style={{ marginLeft: 40, marginTop: 30, marginRight: 0 }}
        >
          <div className="col">
            <p style={{ fontSize: 20, fontWeight: 500 }}>
              Total Campaign:{" "}
              <span style={{ marginLeft: 8 }}>
                {this.state.totalCampaignByName}
              </span>
            </p>
          </div>
          <div className="col">
            <p style={{ fontSize: 20, fontWeight: 500 }}>
              Success Record:{" "}
              <span style={{ marginLeft: 8 }}>
                {this.state.successCampaign}/{this.state.totalCampaign}
              </span>
            </p>
          </div>
          <div className="col">
            <div>
              <Button
                color="primary"
                variant="contained"
                style={{ backgroundColor: "#F35D5E", color: "white" }}
                onClick={this.getData}
                disabled={this.state.fileType}
              >
                Fetch Data
              </Button>
            </div>
          </div>
          <div className="col">
            <div>
              <Button
                color="primary"
                variant="contained"
                style={{ backgroundColor: "#F35D5E", color: "white" }}
                onClick={this.createCampaign}
                disabled={this.state.fileType}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <Table
            columns={columns}
            dataSource={this.state.tableData}
            onRow={this.onClickRow}
            // onChange={this.handleChange}
            pagination={{ pageSize: 10 }}
          />
          <ToastContainer />
          <MyModel
            isHiddenAudio={this.state.instructionModel}
            onCloseAudio={this.hideModal}
          />
        </div>
        <div>
          <Snackbar
            open={this.state.errorMsg}
            autoHideDuration={6000}
            onClose={this.handleClose}
          >
            <Alert severity="error">Please Enter Campaign Name</Alert>
          </Snackbar>
        </div>
      </div>
    );
  }
}

export default CreateCamppaign;
