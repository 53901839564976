/** @format */

import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, Badge, Hidden, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputIcon from "@material-ui/icons/Input";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import history from "../../../../helpers/history";

const useStyles = makeStyles((theme) => ({
  root: {
    // boxShadow: "none",
    backgroundColor: "#FCFCFC"
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;
  const classes = useStyles();

  const [notifications] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const loggout = () => {
    setAnchorEl(null);
    localStorage.removeItem("token");
    history.push("/sign-in");
  };

  return (
    <AppBar
      {...rest}
      // style={{ backgroundColor: "#f35d5e" }}
      className={clsx(classes.root, 'main-header')}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            style={{ width: 100, borderRadius: 5 }}
            alt="Logo"
            src={require("../../../../image/Logo.png")}
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton>
            <Badge
              badgeContent={notifications.length}

              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton className={classes.signOutButton} >
            <InputIcon onClick={handleClick} />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
            {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
            <MenuItem onClick={loggout}>Logout</MenuItem>
          </Menu>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  history: PropTypes.object,
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
