import React, { useState, useEffect } from "react";
import { Link as RouterLink, withRouter, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import validate from "validate.js";
import { makeStyles } from "@material-ui/styles";
import { Grid, Button, TextField, Link, Typography } from "@material-ui/core";
import * as API from "../../ApiUrl";
import axios from "axios";
import { Facebook as FacebookIcon, Google as GoogleIcon } from "icons";
import Swal from "sweetalert2";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Button as But } from "rsuite";
import { images } from "../../constant";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const { logo } = images;

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  grid: {
    height: "100%",
  },
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  quote: {
    // backgroundColor: theme.palette.neutral,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  quoteInner: {
    textAlign: "center",
    flexBasis: "600px",
  },
  quoteText: {
    height: 146,
    width: 425,
    marginTop: 16,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
    paddingBottom: 15,
    // marginBottom: -30
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },

  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 20,
  },
  logo: {
    width: 300,
  },
  loginContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    fontSize: 28,
    fontWeight: "500",
    color: "#1B0C0C",
    // paddingBototm:20
  },
  imageContainer: {
    borderColor: "#F35D5E",
    paddingBototm: 10,
  },
  subHeading: {
    paddingTop: 10,
    fontSize: 20,
    fontWeight: "300",
    color: "#1B0C0C",
    marginLeft: 20,
  },
}));

const SignIn = (props) => {
  const { history } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    signinSuccfull: false,
    errorMsg: "",
    error: false,
    loading: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  // const handleBack = () => {
  //   history.goBack();
  // };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSignIn = (event) => {
    event.preventDefault();
    console.log("form", formState.values.email);
    axios
      .post(API.LOGIN_API_URI, {
        // API me login password jaaa raha hai
        email: formState.values.email,
        password: formState.values.password,
      })
      .then(async (res) => {
        // agar sahi hua to Backend se Access Token milegaa
        console.log("res", res);
        if (res.status === 200) {
          const token = await res.data.access; //token milaaa
          // const expirationDate = new Date(new Date().getTime() + 3600 *1000);
          const err = undefined;
          const localToken = localStorage.setItem("token", token);
          setFormState((formState) => ({
            ...formState,
            signinSuccfull: true,
          }));
          console.log("sign", formState.signinSuccfull);
          history.push("/campaign");
          const Toast = Swal.mixin({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Signed in successfully",
          });
        } else {
          console.log("errorrr", res);
        }
      })
      .catch((err) => {
        console.log("Login Error", err.response);
        setFormState((formState) => ({
          ...formState,
          error: true,
          errorMsg: err.response?.data.detail,
        }));
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFormState((formState) => ({
      ...formState,
      error: false,
    }));
  };
  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      {/* {formState.loading && 
       <div>
       <Loader type="ThreeDots" color="#F35D5E" height="100" width="100" />
       </div>
      } */}
      {/* {!formState.loading &&
      

      } */}

      <div className="row">
        <div className="col-4">
          <div className={classes.logoContainer}>
            <p className={classes.heading}>Welcome to</p>
            <div className={classes.imageContainer}>
              <img alt="Logo" src={logo.logo} className={classes.logo} />
            </div>
          </div>
        </div>
        <div className="col-8">
          <div className={classes.loginContainer}>
            <div className={classes.content}>
              <div className={classes.contentBody}>
                <form className={classes.form} onSubmit={handleSignIn}>
                  <Typography className={classes.title} variant="h2">
                    Sign in
                  </Typography>
                  <Grid
                    className={classes.socialButtons}
                    container
                    spacing={2}
                  ></Grid>
                  <Typography
                    align="center"
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="body1"
                  ></Typography>
                  <TextField
                    className={classes.textField}
                    style={{ marginBottom: 15 }}
                    error={hasError("email")}
                    fullWidth
                    helperText={
                      hasError("email") ? formState.errors.email[0] : null
                    }
                    label="Email address"
                    name="email"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.email || ""}
                    variant="outlined"
                  />
                  <div></div>
                  <TextField
                    className={classes.textField}
                    error={hasError("password")}
                    fullWidth
                    helperText={
                      hasError("password") ? formState.errors.password[0] : null
                    }
                    label="Password"
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={formState.values.password || ""}
                    variant="outlined"
                  />

                  <But
                    className={classes.signInButton}
                    appearance="primary"
                    disabled={!formState.isValid}
                    block
                    size="lg"
                    type="submit"
                    // onclick = {signinNow}
                  >
                    Sign in now
                  </But>
                  <div className={classes.root}>
                    <Snackbar
                      open={formState.error}
                      autoHideDuration={6000}
                      onClose={handleClose}
                    >
                      <Alert severity="error">{formState.errorMsg}</Alert>
                    </Snackbar>
                  </div>
                  <Typography color="textSecondary" variant="body1">
                    Don't have an account?{" "}
                    <Link component={RouterLink} to="/sign-up" variant="h6">
                      <But appearance="link">Sign up</But>
                    </Link>
                  </Typography>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object,
};

export default withRouter(SignIn);
