/** @format */

export const logo = {
  logo: require("../image/Logo.png"),
};

export const photos = {
  audioConversion: require("../image/audioConversion.png"),
  brace1: require("../image/brace1.png"),
  demo: require("../image/demo.png"),
  demoPink: require("../image/demoPink.png"),
  voice: require("../image/microphone.svg"),
  demoArt: require("../image/demoArt.png"),
  demoArt2: require("../image/demoArt2.png"),
  start: require("../image/play.svg"),
  volume: require("../image/volume-down.svg"),
  rule: require("../image/automation.svg"),
  hungup: require("../image/hang-up.svg"),
  speak: require("../image/speak.svg"),
  voice: require('../image/voice-mail.svg'),
  phone1: require('../image/phone-1.svg'),
  phone2: require('../image/phone-2.svg'),
  phone3: require('../image/phone-3.svg'),
  automation1: require('../image/automation1.svg'),
  voiceRecording: require('../image/voice-recording.svg'),
  schoolbell: require('../image/school-bell.svg'),
  csv: require('../image/csv.svg'),
  blocked: require('../image/blocked.svg'),
  add: require('../image/add.svg'),
  play: require('../image/play-button.svg'),
  pause: require('../image/pause.svg'),
  chat: require('../image/chat.svg'),
  attention: require('../image/attention.svg'),
  success: require('../image/success.svg'),
  man: require('../image/man.svg'),
  woman: require('../image/woman.svg'),
  dialpad: require('../image/dial-pad.svg'),
  excl: require('../image/excl.svg'),
  externalAudio: require('../image/hard-disk.svg'),
  tts: require('../image/megaphone.svg'),
  dataLink: require('../image/data-link.svg'),
  info:require('../image/info.svg')
};

export default {
  logo,
  photos,
};
