import * as actionTypes from "../actionTypes"
import { authApi, postApiRequest } from "../actions/api"
import * as API from "../../ApiUrl"
import { updateCreateCampaignSuccess, updateCreateCampaignFail, createCampaignStart, createCampaignFinish } from "../actions/_campaign"
import history from "../../helpers/history"
import { Notification } from 'rsuite'


export const createCampaign = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.CREATE_CAMPAIGN) {
        let data = action.payload.data;
        let onSuccess = actionTypes.CREATE_CAMPAIGN_SUCCESS;
        let onError = actionTypes.CREATE_CAMPAIGN_FAIL;
        dispatch({ type: actionTypes.RESET_CREATE_CAMPAIGN })
        dispatch(authApi('POST', API.CREATE_CAMPAIGN_LINE, null, onSuccess, onError, data, null))
        dispatch(createCampaignStart())
    }
}

export const createCampaignSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.CREATE_CAMPAIGN_SUCCESS) {
        let data = action.payload;
        const openNotification = () => {
            Notification.success({
                title: "Success",
                description: "Campaign has been created Successfully!"
            })
        }
        if (data) {
            dispatch(updateCreateCampaignSuccess(data))
            openNotification()
            history.push('/campaign')
        }
        dispatch(createCampaignFinish())
    }
}

export const createCampaignFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.CREATE_CAMPAIGN_FAIL) {
        let data = action.payload;
        const openNotification = (desc) => {
            Notification.error({
                title: "Whoops",
                description: desc ? desc : "Something Went Wrong!"
            })
        }
        if (data) {
            dispatch(updateCreateCampaignFail(data))
            openNotification(data.msg)
        }
        dispatch(createCampaignFinish())
    }
}


export const duplicateCampaign = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.DUPLICATE_CAMPAIGN) {
        let data = action.payload.data;
        let onSuccess = actionTypes.DUPLICATE_CAMPAIGN_SUCCESS;
        let onError = actionTypes.DUPLICATE_CAMPAIGN_FAIL;
        dispatch({ type: actionTypes.RESET_CREATE_CAMPAIGN })
        dispatch(authApi('POST', API.DUPLICATE_CAMPAIGN, null, onSuccess, onError, data, null))
        dispatch(createCampaignStart())
    }
}

export const duplicateCampaignSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.DUPLICATE_CAMPAIGN_SUCCESS) {
        let data = action.payload;
        const openNotification = () => {
            Notification.success({
                title: "Success",
                description: "Campaign has been Duplicated Successfully!"
            })
        }
        if (data) {
            dispatch(updateCreateCampaignSuccess(data))
            openNotification()
            history.push('/campaign')
        }
        dispatch(createCampaignFinish())
    }
}

export const duplicateCampaignFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.DUPLICATE_CAMPAIGN_FAIL) {
        let data = action.payload;
        const openNotification = (desc) => {
            Notification.error({
                title: "Whoops",
                description: desc ? desc : "Something Went Wrong!"
            })
        }
        if (data) {
            dispatch(updateCreateCampaignFail(data))
            openNotification(data.msg)
        }
        dispatch(createCampaignFinish())
    }
}

export const editCampaign = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.EDIT_CAMPAIGN) {
        let data = action.payload.data;
        let onSuccess = actionTypes.EDIT_CAMPAIGN_SUCCESS;
        let onError = actionTypes.EDIT_CAMPAIGN_FAIL;
        dispatch({ type: actionTypes.RESET_CREATE_CAMPAIGN })
        dispatch(authApi('POST', API.EDIT_CAMPAIGN, null, onSuccess, onError, data, null))
        dispatch(createCampaignStart())
    }
}


export const editCampaignSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.EDIT_CAMPAIGN_SUCCESS) {
        let data = action.payload;
        const openNotification = () => {
            Notification.success({
                title: "Success",
                description: "Campaign has been updated Successfully!"
            })
        }
        if (data) {
            dispatch(updateCreateCampaignSuccess(data))
            openNotification()
            history.push('/campaign')
        }
        dispatch(createCampaignFinish())
    }
}

export const editCampaignFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.EDIT_CAMPAIGN_FAIL) {
        let data = action.payload;
        const openNotification = (desc) => {
            Notification.error({
                title: "Whoops",
                description: desc ? desc : "Something Went Wrong!"
            })
        }
        if (data) {
            dispatch(updateCreateCampaignFail(data))
            openNotification(data.msg)
        }
        dispatch(createCampaignFinish())
    }
}


export const campaignMdl = [
    createCampaign,
    createCampaignSuccess,
    createCampaignFail,
    duplicateCampaign,
    duplicateCampaignSuccess,
    duplicateCampaignFail,
    editCampaign,
    editCampaignSuccess,
    editCampaignFail
]