import React, { Component } from 'react'
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import   "../../../assets/simCardView/simCardView.module.css";
import { Modal } from 'react-bootstrap';
import PhoneIcon from '@material-ui/icons/Phone';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import {Link} from "react-router-dom";
import { Table, Input, Tag, Checkbox } from 'antd';
import axios from "axios";
import * as API from "../../../ApiUrl";
import Swal from 'sweetalert2'
import { Switch, Spin } from 'antd';
import validator from "validator";
import ReactDOM from "react-dom";
import ReactCustomFlagSelect from "react-custom-flag-select";
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";
import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import Loader from 'react-loader-spinner'
var checkSwitch
var active
const find = (arr, obj) => {
  const res = [];
  arr.forEach(o => {
    let match = false;
    Object.keys(obj).forEach(i => {
      if (obj[i] === o[i]) {
        match = true;
      }
    });
    if (match) {
      res.push(o);
    }
  });
  return res;
};

const FLAG_SELECTOR_OPTION_LIST = [
  {
    id: "+91",
    name: "INDIA",
    displayText: "IN(+91)",
    locale: "en-IN",
    flag: require('../../../image/flags/india.svg') 
  },
  {
    id: "+84",
    name: "VIETNAM",
    displayText: "VN(+84)",
    locale: "vi-VN",
    flag: require('../../../image/flags/vietnam.svg') 
  },
  {
    id: "+1",
    name: "CANADA",
    displayText: "CND(+1)",
    locale: "en-CA",
    flag: require('../../../image/flags/canada.svg') 
  },
  {
    id: "+1",
    name: "US",
    displayText: "US(+1)",
    locale: "en-US",
    flag: require('../../../image/flags/canada.svg') 
  },
  {
    id: "+86",
    name: "CHINA",
    displayText: "CN(+86)",
    locale: "zh-CN",
    flag: require('../../../image/flags/cn.svg')
  }
];
const DEFAULT_AREA_CODE = FLAG_SELECTOR_OPTION_LIST[0].id;

var showModl = false;
var changStaus = false;
const originalData = [
  {  
    key:'1',
    mobileNumber: '9131811694',  
    otpStatus: ['unverified'],
    status: ['Active']  
    },
    {
    key:'2',
    mobileNumber: '9131881656',  
    otpStatus: ['verified'],
    status: ['Active']  
    },
    {
    key:'3',
    mobileNumber: '9131881632',  
    otpStatus: ['verified'],
    status: ['Active']  
    },
    {
    key:'4',
    mobileNumber: '9131881690',  
    otpStatus: ['verified'],
    status: ['Active']  
    }
    ];
    
const MyModel = (props) => (<Modal show={props.addSimModal} onHide={props.addSimClose} aria-labelledby="contained-modal-title-vcenter"
centered >
  <Modal.Header closeButton>
    <Modal.Title>
      {!props.showOtp &&
    <div>
      Add New Number
    </div>
      }
       {props.showOtp &&
    <div>
      Verify Your Number
    </div>
      }
      </Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {console.log('props', props)}
    {!props.showOtp &&
 <div>
  <div style={{ padding: "10px" }}>
            <div style={{ padding: "20px"}}>
              <form >
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      left: "0",
                      height: "45px"
                    }}
                  >
                    <ReactCustomFlagSelect
                      tabIndex={"1"} //Optional.[String or Number].Default: -1.
                      id={"areaCode"} //Optional.[String].Default: "". Input ID.
                      name={"areaCode"} //Optional.[String].Default: "". Input name.
                      value={props.current.id} //Optional.[String].Default: "".
                      disabled={false} //Optional.[Bool].Default: false.
                      showSearch={true} ////Optional.[Bool].Default: false.
                      showArrow={true} //Optional.[Bool].Default: true.
                      animate={true} //Optional.[Bool].Default: false.
                      optionList={FLAG_SELECTOR_OPTION_LIST} //Required.[Array of Object(s)].Default: [].
                      // selectOptionListItemHtml={<div>us</div>} //Optional.[Html].Default: none. The custom select options item html that will display in dropdown list. Use it if you think the default html is ugly.
                      // selectHtml={<div>us</div>} //Optional.[Html].Default: none. The custom html that will display when user choose. Use it if you think the default html is ugly.
                     
                      customStyleContainer={{ border: "none", fontSize: "12px" }} //Optional.[Object].Default: {}.
                      customStyleSelect={{ width: "80px" }} //Optional.[Object].Default: {}.
                      customStyleOptionListContainer={{
                        maxHeight: "100px",
                        overflow: "auto",
                        width: "120px"
                      }} //Optional.[Object].Default: {}.
                      onChange={areaCode => props.countryCode(areaCode)}   
                      // props.handlePhoneChange(phone);
                      // onBlur={() => {}} //Optional.[Func].Default: none.
                      // onFocus={(e) => {console.log(e)}} //Optional.[Func].Default: none.
                      // onClick={(e) => {console.log(e)}} //Optional.[Func].Default: none.
                    />
                  </div>
                  <Textbox
                    attributesInput={{
                      id: "phone",
                      placeholder: "Please enter your phone number",
                      type: "text"
                    }}
                    customStyleWrapper={{ height: "100%" }}
                    customStyleContainer={{ height: "100%" }}
                    customStyleInput={{
                      paddingTop: "0",
                      paddingBottom: "0",
                      height: "45px",
                      paddingLeft: "90px",
                      paddingRight: "20px"
                    }}
                    value={props.phone}
                    validate={props.validate}
                    validationCallback={res => props.validation(res)}
                    type="text"
                    placeholder="Please enter your phone number"
                    onChange={res => 
                      props.handlePhoneChange(res)
                    }
                    onBlur={() => {}}
                    validationOption={{
                      check: true,
                      required: true,
                      customFunc: phone => {
                        if (
                          validator.isMobilePhone(`${phone}`, props.current.locale)
                        ) {
                          return true;
                        } else {
                          return `Invalid phone format for ${props.current.locale}`;
                        }
                      }
                    }}
                  />
                </div>
                <div style={{ height: "20px" }} />
                {/* <div className="my-button my-button__red" onClick={this.submit}>
                  Submit
                </div>
                <input type="submit" style={{ display: "none" }} /> */}
              </form>
            </div>
          </div>
<div className="form-check" style ={{textAlign:'center'}}>
<Checkbox onChange={props.termCondition}> 
<span onClick = {props.term} style={{cursor:'pointer'}}>Term & conditions</span>
</Checkbox>
</div>
<div style ={{textAlign:'center'}}>
<button  className="btn btn-primary" disabled={props.buttonEnable} onClick = {props.insertSim} style = {{backgroundColor:'#F35D5E', border:'none', marginTop:5}}>Get Otp</button>
</div>
</div>
    }
  {props.showOtp &&
    <div>
  <div className="input-group mb-3">
  <div className="input-group-prepend">
    <span className="input-group-text" id="basic-addon1" style = {{color:'#F35D5E'}}><PhoneIcon  /></span>
  </div>
  <input type="tel" className="form-control" onChange = {props.verify} placeholder="Enter OTP" aria-label="Enter OTP" aria-describedby="basic-addon1"/>
</div>
{/* <div className="form-check" style ={{textAlign:'center'}}>
    <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
    <label className="form-check-label" for="exampleCheck1">Term & condition</label>
  </div> */}
  <div style ={{textAlign:'center'}}>
  <button  className="btn btn-primary"  onClick = {props.otpVerify} style = {{backgroundColor:'#F35D5E', border:'none'}}>Verify Otp</button>
  </div>
  </div>

  }
  </Modal.Body>
  <Modal.Footer>
    <Button onClick={props.addSimClose}>Close</Button>
  </Modal.Footer>
</Modal>);  
const OtpModel = (props) => (<Modal show={props.showModal} onHide={props.onClose} aria-labelledby="contained-modal-title-vcenter"
centered >
  <Modal.Header closeButton>
    <Modal.Title>
      <div>
      Verify Your Number
    </div>
      </Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div>
  <div className="input-group mb-3">
  <div className="input-group-prepend">
    <span className="input-group-text" id="basic-addon1" style = {{color:'#F35D5E'}}><PhoneIcon  /></span>
  </div>
  <input type="tel" className="form-control" onChange = {props.verify} placeholder="Enter OTP" aria-label="Enter OTP" aria-describedby="basic-addon1"/>
</div>
  <div style ={{textAlign:'center'}}>
  <button  className="btn btn-primary" onClick = {props.otpVerify} style = {{backgroundColor:'#F35D5E', border:'none'}}>Verify Otp</button>
  </div>
  </div>
  </Modal.Body>
  <Modal.Footer>
    <Button onClick={props.onClose}>Close</Button>
  </Modal.Footer>
</Modal>);  
class SimCardView extends Component { 
    constructor(){
        super();
        this.state = {
         selected: null,
         showModal: false,
         showOtp: false,
         addSimModeOpen:false,
         data: originalData,
         areaCode: DEFAULT_AREA_CODE,
         active:'',
          phone: "",
          hasPhoneError: true,
          validate: false,
          getOtpButtonEnable: true,
          checked: false,
          getSimData:'',
          rowData:'',
          checked: false,
          loading: false,
         number:'',
         token:'',
         otp:''
        };
     
      }
      async componentDidMount () {
        const token = await localStorage.getItem('token')
        const userInfo = await localStorage.getItem('user')
        console.log('token', token, userInfo);
        this.setState({token:token})
        this.getSimList()
        }
        showLoader = () => {
          this.setState({loading:true})
        }
        hideLoader = () => {
          this.setState({loading:false})
        }
        verifyOtp = () => {
          console.log('hahaOTP');
          fetch(`${API.VERIFY_OTP}`, {
            method: "POST",
            body: JSON.stringify({
              verify_code:this.state.otp,
              phone:this.state.rowData,
            }),
            headers: {
                "Authorization": `Bearer ${this.state.token}`,
                "Content-type": "application/json;",
            },
        }).then(res => res.json())
        .then(res => {
          console.log('resotp', res.msg);
          if(res.msg === 'verified') {
            this.hideModal();
            this.hideModalAddSim();
            this.getSimList();
            Swal.fire({
              icon: 'success',
              title: 'OTP Verified Successfully',
              showConfirmButton: false,
              timer: 1500
            })
          } else if (res.msg === 'Not verified') {
            this.hideModal();
            this.hideModalAddSim();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'You have entered Incorrect OTP. Please try again later'
            })
            console.log('errror'); 
          }
          
        })
        }
        verifyOtpInsert = () => {
          console.log('hahaOTP');
          fetch(`${API.VERIFY_OTP}`, {
            method: "POST",
            body: JSON.stringify({
              verify_code:this.state.otp,
              phone:this.state.phone,
            }),
            headers: {
                "Authorization": `Bearer ${this.state.token}`,
                "Content-type": "application/json;",
            },
        }).then(res => res.json())
        .then(res => {
          console.log('resotp', res.msg);
          this.hideLoader()
          if(res.msg === 'verified') {
            this.hideModal();
            this.hideModalAddSim();
            this.getSimList();
            Swal.fire({
              icon: 'success',
              title: 'OTP Verified Successfully',
              showConfirmButton: false,
              timer: 1500
            })
          } else if (res.msg === 'Not verified') {
            this.hideModal();
            this.hideModalAddSim();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'You have entered Incorrect OTP. Please try again later'
            })
            console.log('errror'); 
          }
          
        })
        }
      
      otpGet = (r) => {
        console.log('haha', r);
        fetch(`${API.GET_OTP}`, {
          method: "POST",
          body: JSON.stringify({
            phone:r.phone
          }),
          headers: {
              "Authorization": `Bearer ${this.state.token}`,
              "Content-type": "application/json;",
          },
      })
      .then(res => {
        console.log('resotp', res.status);
        if(res.status === 200) {
          if( this.state.showOtp === false) {
            this.setState({showOtp:true})
          }
        } 
      })        
      }
      
      insertSim = () => {
        this.showLoader()
        console.log('haha');
        fetch(`${API.INSERT_SIM}`, {
          method: "POST",
          body: JSON.stringify({
            phone_no:this.state.phone,
            isd_code:this.state.areaCode
          }),
          headers: {
              "Authorization": `Bearer ${this.state.token}`,
              "Content-type": "application/json;",
              'Accept': 'application/json'
          },
      })
        .then(res => res.json())
        .then(res => {
          this.hideLoader()
          if(res.msg) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: res.msg
            })
            console.log('errror'); 
          }else {
            this.hideLoader()
            if( this.state.showOtp === false) {
                    this.setState({showOtp:true})
                    this.getSimList();
                  }
          }
          
        })
      // .then(res => {
      //   console.log('resotp', res.msg);
      //   if(res.status === 200) {
      //     if( this.state.showOtp === false) {
      //       this.setState({showOtp:true})
      //       this.getSimList();
      //     }
      //   }else {
      //     console.log('error', res);
      //   } 
      // })   
      }
      getSimList = () => {
        this.showLoader();
        fetch(`${API.GET_SIM}`, {
          headers: {
              "Authorization": `Bearer ${this.state.token}`,
              "Content-type": "application/json;",
          },
      })
      .then(res => res.json())
      .then(res => {
        console.log('res', res);
        this.hideLoader();
        this.setState({getSimData: res})
        console.log('setSimDate', this.state.getSimData);
      } )   
      }
      update = (r) => {
        let activee
        if (r.status[0] === 'Active') {
          activee = false
        }else {
          activee = true
        }
        console.log('update', activee);
        fetch(API.UPDATE_SIM_STATUS + r.key + '/', {
          method: "PATCH",
          body: JSON.stringify({
            active:activee
          }),
          headers: {
              "Authorization": `Bearer ${this.state.token}`,
              "Content-type": "application/json;",
          },
      })
      .then(res => {
        if(res.status === 200) {
            changStaus =false
            this.getSimList();
        }else {
          console.log('error', res);
        } 
      })   
      }

      validationCallback = (res) => {  
        this.setState({
          hasPhoneError: res,
          validate: false
        })
        console.log('valid', res);     
        if(this.state.checked === true && res === false) {
          this.setState({getOtpButtonEnable:false})
            console.log('inside validatio' , this.state.checked , this.state.validate, this.state.getOtpButtonEnable);
              } else {
                this.setState({getOtpButtonEnable:true})
              }
      }
      onChange = (e) => {
        console.log('checked' , this.state.hasPhoneError);
        this.setState({checked: e.target.checked})
        if(e.target.checked === true && this.state.hasPhoneError === false) {
          this.setState({getOtpButtonEnable:false})
            console.log('inside' , e.target.checked , this.state.validate);
              } else {
                this.setState({getOtpButtonEnable:true})
              }
      }
      termCondi = () => {
        console.log('hahahahah12');
        window.open('https://demos.co.uk/terms-conditions/')
        
      }
      countryAreaCode = (area) => {
        this.setState({ areaCode: area });
        console.log('area', area);
        // this.handlePhoneChange(phone)
      }
      handlePhoneChange = (res) => {
        this.setState({ phone: res });
      }
    
      toggleValidating(validate) {
        this.setState({ validate });
      }
    
      // submit(e) {
      //   e.preventDefault();
      //   this.toggleValidating(true);
      //   const { hasPhoneError } = this.state;
      //   if (!hasPhoneError) {
      //     alert("You are good to go");
      //   }
      // }
    
      phonNumber = (e) => {
        this.setState({number:e.target.value})
        console.log('phoneNumber=', this.state.number);
        
      }
      verifyIt = (e) => {
        this.setState({otp:e.target.value})
        console.log('phoneNumber=', this.state.otp);
      }
      setRow = (r) => {
        this.setState({rowData: r.phone})
        console.log('heloo', this.state.rowData);
        // if(r.otpStatus[0] === "verified"){
          //   this.update(r)
        // }
          console.log('h', r.status);
        if(r.otpStatus[0] === "unverified"){
        console.log('heloo', this.state.rowData);
          this.otpGet(r)
        }
      }
      onClickRow = (r) => {        
        return {
          onClick: () => {
            console.log('rowDate', r)
            // this.setState({
            //   rowData: r.mobileNumber,
            // });
            console.log('change', changStaus );
            
            if (changStaus === true) {
              console.log('cool');
              if(r.otpStatus[0] === "verified"){
              console.log('cool1');
                this.update(r)
              }
            }
            this.setRow(r)
            if(showModl === true)
            this.setState({showModal:true})
          },
    
        };
      }
      show(r) {
        console.log('ahahahah',r)
        if (r[0] === 'unverified') {
          showModl = true;
        }
        console.log('yehi hai bawaaaa', r[0], this.state.rowData);
        // this.setState({showModal:true})
      }
      showModal = (e) => {
        this.setState({ showModal: true })
      }
      changeStatus = (r) => {
        console.log('change1', changStaus );
        console.log('kjhdfgkjdsfkjdsssssssssskjsdd', r[0]);
        
          changStaus = true;
        console.log('change2', changStaus );
        
      }
      hideModal = (e) => {

        this.setState({ showModal: false, getOtpButtonEnable:true })
        // console.log('oncloseOTP= '. this.state.getOtpButtonEnable);
        showModl = false;
        this.setState({showOtp:false})
      }
      showModalAddSim = (e) => {
        console.log('onclose= '. this.state.addSimModeOpen);
      }
      hideModalAddSim = (e) => {
        // console.log('onclose= '. this.state.addSimModeOpen);
        
        this.setState({ addSimModeOpen: false, getOtpButtonEnable:true })
        // showModl = false;
        // this.setState({showOtp:false})
      }
      addSim = (e) => {
        this.setState({ addSimModeOpen: true })
      }
    
     
      handleButtonClick(e, row) {
      if(row.OtpStatus == 'unverified'){
        this.setState({showModal:true})
        console.log('haha', this.state.showModal );
      }
      // status = (checked) => {
      //   console.log('checked');
        
      // } 
      }
    
      render(){
        const { areaCode, phone, validate } = this.state;
        const currentItem = find(FLAG_SELECTOR_OPTION_LIST, { id: areaCode })[0];
        let { data, searchInput } = this.state;
        let { sortedInfo, filteredInfo } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};
        const columns = [
          {
            title: 'Mobile Number',
            dataIndex: 'phone',
            key: 'phone',
            filteredValue: filteredInfo.phone || null,
            onFilter: (value, record) => record.phone.includes(value),
            sorter: (a, b) => a.phone.length - b.phone.length,
            sortOrder: sortedInfo.columnKey === 'phone' && sortedInfo.order,
            ellipsis: true,
          },
          {
            title: 'OTP Status',
            key: 'otpStatus',
            dataIndex: 'otpStatus',
            render: otpStatus => (
              <span >
                {
                otpStatus.map(tag => {
                  let color = tag.length > 8 ? 'volcano' : 'green';
                  // if (tag === 'Ongoing') {
                  //   color = 'volcano';
                  // }
                  return (
                    <Tag color={color} key={tag}  onClick = {e => this.show(otpStatus)}  style={{cursor: 'pointer'}}>
                     
                      {tag.toUpperCase()}
                    </Tag>

                  );
                })}
              </span>
            ),
          },
          {
            title: ' SIM Status',
            dataIndex: 'status',
            key: 'status',
            render: status => (
              
              <span >
                {  
                status.map(tag => {
                  // console.log('status', tag); 
                  // console.log('statusss', status);
                  if(tag === 'Active') {
                    checkSwitch = true
                  } else {
                    checkSwitch = false
                  }
                  return (
                    <div >
                      <Switch checkedChildren="Active" onClick = {e => this.changeStatus(status)} unCheckedChildren="Inactive" checked = {checkSwitch} onChange={this.statusChange} />
                    </div>
                  );
                })}
              </span>
            ),
          },
         
        ];
          return(
            <div >
              { this.state.loading &&
                <div className='row'>
                <div className = 'col' style={{zIndex:1000,height:'100%', marginRight: 8, background: 'rgba(0,0,0,0.2)',  position:'fixed', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Loader
                    type="Audio"
                    color="#F35D5E"
                    height={100}
                    width={100}
                    
                    style = {{marginRight:'10%'}}
                  />
                </div>
              </div>
              }
    <div style = {{padding:24}}>        
    <div >
      <Button
        color="primary"
        variant="contained"
        style = {{marginBottom:22, marginTop:10}}
        onClick = {e => this.addSim(e)}
      >
        Add Sim Card
      </Button>
          </div>
          <div>
          <Table columns={columns}
        dataSource={this.state.getSimData}
        onRow={this.onClickRow}
        
        onChange={this.handleChange}  
        pagination={{ pageSize: 10}}
        />
          </div>
      
          <div>
        <MyModel 
        addSimModal={this.state.addSimModeOpen} 
        addSimClose={this.hideModalAddSim} 
        otp = {this.otpGet}
        showOtp = {this.state.showOtp}
        change = {this.phonNumber}
        verify = {this.verifyIt}
        otpVerify = {this.verifyOtpInsert} 
        current = {currentItem}
        phone = {this.state.phone}
        hasPhoneError = {this.state.hasPhoneError}
        validate = {this.state.validate}
        handlePhoneChange = {this.handlePhoneChange}
        toggleValidating = {this.toggleValidating}
        countryCode = {this.countryAreaCode}
        insertSim = {this.insertSim}
        validation = {this.validationCallback}
        termCondition = {this.onChange}
        term ={this.termCondi}
        buttonEnable = {this.state.getOtpButtonEnable}
        />
        <OtpModel 
          showModal={this.state.showModal} 
          onClose={this.hideModal} 
          otp = {this.otpGet}
          // showOtp = {this.state.showOtp}
          change = {this.phonNumber}
          verify = {this.verifyIt}
          otpVerify = {this.verifyOtp} 
          />
          </div> 
        </div>
   </div>
           
           
          )
         
    }
}

export default SimCardView;
