import * as actionTypes from "../actionTypes"
import { authApi, postApiRequest } from "../actions/api"
import * as API from "../../ApiUrl"
import {
    handleSuccess, handleFail, loaderStart, loaderOff, updateOverallSuccess, updateOverallFail,
    overallLoaderOff, overallLoaderStart
} from "../actions/result"
import history from "../../helpers/history"
import { Alert, Notification } from 'rsuite'


export const handleFetchResult = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.FETCH_RESULT) {
        let data2 = action.payload.data;

        let query = "?";
        // if (pageNumber) query += "p=" + pageNumber;
        if (data2.limit) query += "limit=" + data2.limit;
        if (data2.searchInput) query += "&search=" + data2.searchInput;
        if (data2.offset) query += "&offset=" +data2.offset ;
        if (data2.filter) query += "&status=" +data2.filter ;
        
        console.log("fetching",data2,"ra2",query)



        let data = {
            campaign_id: data2.id
        }
        let onSuccess = actionTypes.FETCH_RESULT_SUCCESS;
        let onError = actionTypes.FETCH_RESULT_FAIL;
        let OverallOnSuccess = actionTypes.FETCH_OVERALL_RESULT_SUCCESS;
        let OverallOnFail = actionTypes.FETCH_OVERALL_RESULT_FAIL;
        dispatch(loaderStart())
        dispatch(overallLoaderStart())
        dispatch(authApi('POST', API.GET_RESULT, null, onSuccess, onError, data, null))
        dispatch(authApi('GET', `${API.OVERALL_RESULT2}${data2.id}${query}`, null,OverallOnSuccess, OverallOnFail, data, null))
    }
}

export const handleResultSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.FETCH_RESULT_SUCCESS) {
        let data = action.payload;
        if (data) {
            dispatch(handleSuccess(data))
        }
        dispatch(loaderOff())
    }
}

export const handleResultFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.FETCH_RESULT_FAIL) {
        let data = action.payload;
        const openNotification = () => {
            Notification.warning({
                title: "Result not found.",
                description: "Make sure you have created the flow for the result you want to see."
            })
        }
        if (data) {
            openNotification()
            history.push('/campaign')
            dispatch(handleFail(data))
        }
        dispatch(loaderOff())
    }
}
export const handleOverallResultSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.FETCH_OVERALL_RESULT_SUCCESS) {
        let data = action.payload;
        if (data) {
            dispatch(updateOverallSuccess(data))
        }
        dispatch(overallLoaderOff())
    }
}

export const handleOverallResultFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.FETCH_OVERALL_RESULT_FAIL) {
        let data = action.payload;
        if (data) {
            dispatch(updateOverallFail(data))
        }
        dispatch(overallLoaderOff())
    }
}



export const resultMdl = [
    handleFetchResult,
    handleResultSuccess,
    handleResultFail,
    handleOverallResultSuccess,
    handleOverallResultFail
]