import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import { images } from "../../../../constant"
const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
    // padding
  }
}));

const Topbar = props => {
  const { logo } = images
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      style={{ backgroundColor: "#f35d5e" }}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            style={{ width: 80, backgroundColor: '#F0F9FD', borderRadius: 5 }}
            alt="Logo"
            src={logo.logo}
          />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
