

function getRuleId(chart,id){
    console.log("Promise Chart",chart)
    console.log('Promise Rule ID: ',id)
    return new Promise((resolve,reject)=>{
        let arr = Object.entries(chart)
        for (let i in arr) {
            if (arr[i][0] == 'nodes') {
                let ar1 = Object.entries(arr[i][1])
                for (let j in ar1) {
                    if (ar1[j][1].type === 'Rule' && ar1[j][1].id === id) {
                        resolve({
                            ruleId:ar1[j][1].id,
                            rulePosition:ar1[j][1].position
                        })
                        break;
                    }
                }
                break;
            }
        }
        reject({msg:'No Rule Found with given id'})
    })
}




export {
    getRuleId
}


