/** @format */

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import LoadingReducer from "./LoaderReducer";
import FlowChartReducer from "./flowchartReducer";
import NodeReducer from "./nodeReducer";
import AudioReducer from "./audioReducer";
import StartReducer from "./startReducer";
import TextToSpeechReducer from "./textToSpeechReducer";
import AudioFlowReducer from "./audioFlowReducer";
import RuleReducer from "./ruleReducer";
import ResultReducer from "./resultReducer"
import LanguageReducer from "./languageReducer"
import VoicesReducer from "./voicesReducer"
import CampaignReducer from "./campaignReducer";
import SimCardReducer from "./simCardReducer";
import { reducer as formReducer } from "redux-form";


const persistConfig = {
  key: "root",
  storage,
  whitelist: ["FlowChartReducer", "NodeReducer"],
  blacklist: ["NodeReducer", "FlowChartReducer"],
  // whitelist:['LoadingReducer']
};

const nodeConfig = {
  key: "NodeReducer",
  storage,
  blacklist: ["loading"],
};

const flowConfig = {
  key: "FlowChartReducer",
  storage,
  blacklist: ["loading"],
};

const Reducers = combineReducers({
  LoadingReducer,
  FlowChartReducer: FlowChartReducer,
  NodeReducer: NodeReducer,
  StartReducer,
  AudioReducer,
  AudioFlowReducer,
  TextToSpeechReducer,
  RuleReducer,
  ResultReducer,
  LanguageReducer,
  VoicesReducer,
  CampaignReducer,
  SimCardReducer,
  form: formReducer,
});

export default Reducers;
