/** @format */

import * as actionTypes from "../actionTypes";
import { updateObject } from "./utility";

const initialState = {
  loading: false,
  audio: null,
  data: null,
  flow: null,
  error: null,
  percent: 0,
  tempAudio: null
};

const showLoader = (action, state) => {
  return updateObject(state, {
    loading: true,
  });
};

const hideLoader = (action, state) => {
  return updateObject(state, {
    loading: false,
  });
};

const updateDataSuccess = (action, state) => {
  return updateObject(state, {
    data: action.payload.data,
  });
};

const updateDataFail = (action, state) => {
  return updateObject(state, {
    error: action.payload.data,
  });
};

const addAudio = (action, state) => {
  return updateObject(state, {
    tempAudio: action.payload,
  });
};

const uploadProgress = (action, state) => {
  return updateObject(state, {
    percent: action.payload,
  });
};

const updateAudioScript = (action, state) => {
  return updateObject(state, {
    audio: action.payload.data,
  });
};

const resetFlow = (action, state) => {
  return updateObject(state, {
    loading: false,
    audio: null,
    data: null,
    flow: null,
    error: null,
    percent: 0,
    tempAudio: null
  });
};

const resetAudio = (action, state) => {
  return updateObject(state, {
    loading: false,
    audio: null,
    data: null,
    flow: null,
    error: null,
    percent: 0,
    tempAudio: null
  });
}

const AudioReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_AUDIO_START:
      return showLoader(action, state);
    case actionTypes.UPLOAD_AUDIO_OFF:
      return hideLoader(action, state);
    case actionTypes.UPDATE_AUDIO_SUCCESS:
      return updateDataSuccess(action, state);
    case actionTypes.UPDATE_AUDIO_FAIL:
      return updateDataFail(action, state);
    case actionTypes.ADD_AUDIO_REDUX:
      return addAudio(action, state);
    case actionTypes.AUDIO_UPLOAD_PERCENT:
      return uploadProgress(action, state);
    case actionTypes.UPDATE_FETCH_SCRIPT_SUCCESS:
      return updateAudioScript(action, state);
    case actionTypes.UPDATE_FETCH_SCRIPT_FAIL:
      return updateDataFail(action, state);
    case actionTypes.RESET_FLOW_REDUX:
      return resetFlow(action, state);
    case actionTypes.RESET_TTS_REDUCER:
      return resetAudio(action, state)
    // case actionTypes.UPDATE_NODE_REDUX_SUCCESS:return updateNodeRedux(action,state)
    default:
      return state;
  }
};

export default AudioReducer;
