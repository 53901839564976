import React,{useState} from 'react'
import {Input,Button} from "rsuite"
import Chance from "chance"
import { connect } from 'react-redux'
import * as action from "../../../../redux/actions/flowchart"
import { Alert } from "rsuite";
import {getRuleId} from './helper';


 function UserInput({selectedNode,flow,_updateNodeRedux,loading, chart}) {
    const [userInput,setUserInput]=useState('')
    const [nodes, setNodes] = useState(null);


    const updateNode = (e) => {
        let chance = new Chance()
        let nodeId = chance.string({ length: 36, casing: 'upper', alpha: true, numeric: true });
        let linkId = chance.string({ length: 36, casing: 'upper', alpha: true, numeric: true });
        let newNode = chart;

        getRuleId(chart,selectedNode.id)
        .then((res)=>{
            console.log('==Rule Node Found Success===',res)
            let oneNode1 = {
                id: nodeId,
                type: userInput,
                orientation: 0,
                ports: {
                    port1: {
                        id: 'port1',
                        type: 'top',
                        properties: {
                            custom: 'property'
                        },
                        position: {
                            x: 100,
                            y: 64
                        }
                    },
                    port2: {
                        id: "port2",
                        type: "bottom",
                        properties: {
                            custom: "property",
                        },
                    },
                },
                position: {
                    x: res.rulePosition.x,
                    y: res.rulePosition.y + 100
                },
                properties: {
                    custom: 'property',
                    node_type: "Rule Child",
                    rule_type: "Non Conditional Keypad Response",
                    rule_child_name:userInput,
                    rule_node_id: res.ruleId
                },
                size: {
                    width: 200,
                    height: 64
                }
    
            }
    
            let link = {
                id: linkId,
                from: {
                    nodeId: nodeId,
                    portId: 'port1'
                },
                to: {
                    nodeId: res.ruleId,
                    portId: 'port2'
                }
            }
    
            newNode = {
                ...chart,
                nodes: {
                    ...chart.nodes,
    
                    [nodeId]: oneNode1
                },
                links: {
                    ...chart.links,
                    [linkId]: link
                }
            }
            _updateNodeRedux(newNode,null,"reload")
            console.log("New Nodes User Keypad:  ", newNode);
        })
        .catch((err)=>{
            Alert.error('Something went wrong. Refreshing your session',2000)
            window.location.reload()
            console.log('====Err===',err)
        })

       
    }


    return (
        <div className="user-input" >
            <label>User Keypad Input</label>
            <Input placeholder="Enter the name for Rule Child" size="lg" block  onChange={(v)=>setUserInput(v)}  />
            <div className="button-field" >
            <Button appearance="primary" block size="lg" loading={loading} disabled={loading} onClick={updateNode}>Create</Button>
            </div>
        </div>
    )
}



const mapStateToProps = (state) => ({
    flow: state.NodeReducer.flow,
    loading: state.FlowChartReducer.loading,


})

const mapDispatchToProps = dispatch => {
    return {
        _updateNodeRedux: (data,callback,cbParam) => dispatch(action.updateNodeRedux(data,callback,cbParam)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInput)