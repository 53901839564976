import * as actionTypes from "../actionTypes"
import { authApi, postApiRequest } from "../actions/api"
import { getVoices } from "../actions/_voices"
import { ttswdl, textToSpeech } from "../actions/audio"
import { store } from "../store/index"

var globalData;

export const handleChange = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.HANDLE_CHANGE) {
        let data = action.payload.data
        globalData = data
        let a = data.selectedLang
        let postData = {
            language_code: a.code,
            gender: data.gender
        }
        dispatch(getVoices(postData))
    }
}

export const handleNext = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.UPDATE_GET_VOICES_SUCCESS) {
        const state = store.getState()
        let ttsData = state.TextToSpeechReducer.data
        let voice = state.VoicesReducer.data
        if (ttsData !== null && globalData.option == 1 && !globalData.ttswdl) {
            let data = {
                text: globalData.script,
                voice_name: voice.voices[0].original_name
            }
            dispatch(textToSpeech(data))
        }
        if (ttsData !== null && globalData.option == 2 && !globalData.ttswdl) {
            let data = {
                text: globalData.script,
                voice_name: globalData.voice.original_name

            }
            dispatch(textToSpeech(data))
        }
        //-----------------------TTSWDL----------------------
        if (ttsData !== null && globalData.option == 1 && globalData.ttswdl) {
            let data = {
                text: globalData.script,
                voice_name: voice.voices[0].original_name,
                first_name: globalData.firstName,
                last_name: globalData.lastName
            }
            dispatch(ttswdl(data))
        }
        if (ttsData !== null && globalData.option == 2 && globalData.ttswdl) {
            let data = {
                text: globalData.script,
                first_name: globalData.firstName,
                voice_name: globalData.voice.original_name,
                last_name: globalData.lastName
            }
            dispatch(ttswdl(data))
        }
    }
}

export const changeMdl = [
    handleChange,
    handleNext
]