import React, { useState, useRef, useEffect } from "react";
import {
  Input,
  Button,
  Uploader,
  Table,
  IconButton,
  Radio,
  RadioGroup,
} from "rsuite";
import { photos } from "../../../constant/images";
import * as XLSX from "xlsx";
import { connect } from "react-redux";
import * as action from "../../../redux/actions/_campaign";
import LoadingBar from "react-top-loading-bar";
import history from "../../../helpers/history";
import { BsArrowLeft } from "react-icons/all";
import axios from "axios";
function CreateCampaignV2({
  loading,
  _createCampaign,
  data,
  error,
  _editCampaign,
}) {
  const { Column, HeaderCell, Cell, Pagination } = Table;
  const [csvData, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState("");
  const [missingField, setMissingField] = useState([]);
  const [valid, setValid] = useState(false);
  const loadingRef = useRef(null);
  const [editCampaign, setEditCampaign] = useState(null);
  const [campaignType, setCampaignType] = useState("Outbound");
  const [xldata, setxldata] = useState([]);
  const chk = ["first_name", "last_name", "number"];
  // @RAJEEV RANJAN SINGH
  const [tableloading, setTableloading] = React.useState(false);
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const csvfilterdata = csvData.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  const handleFileUpload = (e) => {
    setData([]);
    console.log("File Upload: ", e);
    var fileReader = new FileReader();
    fileReader.onload = function(event) {
      console.log("enent:", event);
      var data = event.target.result;
      var workbook = XLSX.read(data, {
        type: "binary",
      });
      workbook.SheetNames.forEach((sheet) => {
        let rowObject = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[sheet]
        );
        let jsonObject = JSON.stringify(rowObject);
        console.log(JSON.parse(jsonObject));
        let n = JSON.parse(jsonObject);
        setData(JSON.parse(jsonObject));
        setHeaders(Object.keys(JSON.parse(jsonObject)[0]));
        let headerss = Object.keys(JSON.parse(jsonObject)[0]);
        console.log("Objectssss: ", Object.keys(JSON.parse(jsonObject)[0]));
        validate(headerss);
        let total = data.length;
        console.log("total", total);
        // convertHeaderName()
      });
    };
    fileReader.readAsBinaryString(e.blobFile);
  };

  const readExcel = (file) => {
    setTableloading(true);
    console.log("file loading", file);
    const { blobFile = {} } = file;
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(blobFile);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      console.log("file ", d);
      setData(d);
      setHeaders(Object.keys(d[0]));
    });
    setTableloading(false);
  };

  const downloadExcelFormat = () => {
    window.open("/api/campaigns/excel_file_format/");
  };
  const downloadCSVFormat = () => {
    window.open("/api/campaigns/csv_file_format/");
  };

  useEffect(() => {
    if (data !== null) {
      loadingRef.current.complete();
    }
    if (error !== null) {
      loadingRef.current.complete();
    }
  }, [data, error]);

  useEffect(() => {
    console.log("History Camp:", history);
    if (history.location.state !== undefined) {
      setEditCampaign(history.location.state.item);
      setName(history.location.state.item.name);
      setCampaignType(history.location.state.item.campaign_type);
    }
  }, []);
  console.log("CreateCampaignV2", editCampaign, "campaign t", campaignType);
  const createCampaign = () => {
    let data = {
      campaign: name,
      data: csvData,
      campaign_type: campaignType,
    };
    console.log("Data sending to create: ", data);
    _createCampaign(data);
    loadingRef.current.continuousStart();
  };

  const handleEditCampaign = () => {
    let data = {
      campaign_id: editCampaign.key,
      campaign_lines: csvData,
    };
    _editCampaign(data);
  };

  const validate = (header) => {
    setMissingField([]);
    let count = 0;
    let isThere = false;
    let missing = [];
    for (let i = 0; i < chk.length; i++) {
      for (let j = 0; j < header.length; j++) {
        console.log("Header: ", header[j], "Check: ", chk[i]);
        if (chk[i] == header[j]) {
          isThere = true;
          count++;
          break;
        } else {
          console.log("Not There");
          isThere = false;
        }
      }
      if (!isThere) {
        missing.push(chk[i]);
      }
    }
    if (count !== 3) {
      setMissingField(missing);
      setValidated(true);
    } else {
      setValidated(false);
    }
  };

  const convertHeaderName = (name) => {
    let lowerCase = name
      .split("_")
      .join(" ")
      .toLowerCase();
    let capatalize = lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
    return capatalize;
  };

  return (
    <div className="create-camp">
      <LoadingBar
        ref={loadingRef}
        color="#F35D5E"
        height={3}
        className="top-loading-bar"
      />
      <div className="create-camp__header">
        <BsArrowLeft size={28} onClick={() => history.goBack()} />
        <h2>{editCampaign ? "Edit Campaign" : "Create Campaign"}</h2>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label>Campaign Name</label>
          <Input
            size="lg"
            block
            placeholder="Campaign Name"
            value={name}
            disabled={editCampaign}
            onChange={(v) => setName(v)}
          />
        </div>
        <div className="col-md-4 mt-1">
          <div className="d-flex flex-column">
            <label>Select Campaign Type</label>
            {/* Radio problem fixed by rajeev ranjan  */}
            <RadioGroup
              inline
              name="radioList"
              value={campaignType}
              onChange={(value) => {
                setCampaignType(value);
              }}
            >
              <Radio
                value="Inbound"
                disabled={
                  editCampaign !== null &&
                  editCampaign.campaign_type == "Outbound"
                }
              >
                Inbound
              </Radio>
              <Radio
                value="Outbound"
                disabled={
                  editCampaign !== null &&
                  editCampaign.campaign_type == "Inbound"
                }
              >
                Outbound
              </Radio>
            </RadioGroup>
          </div>
        </div>
        <div className="col-md-4 mt-2">
          <label></label>
          <Button
            block
            size="md"
            appearance="primary"
            disabled={
              valid ||
              name == "" ||
              validated ||
              campaignType == null ||
              (campaignType == "Outbound" && csvData.length == 0)
            }
            onClick={editCampaign ? handleEditCampaign : createCampaign}
            loading={loading}
          >
            {editCampaign ? "Save" : "Create"}
          </Button>
        </div>
      </div>
      {campaignType == "Outbound" && (
        <div className="row">
          <div className="col-md-4 col-4">
            <label>Upload Excel/CSV File</label>

            <Uploader
              // onChange={(e) => handleFileUpload(e[0])}
              // value={xldata}
              autoUpload={false}
              onChange={(a) => {
                console.log(a[a.length - 1]);
                readExcel(a[a.length - 1]);
              }}
              fileListVisible={false}
            >
              <Button appearance="ghost" size="lg" block loading={tableloading}>
                <img src={photos.csv} className="csv-icon" />
                Upload
              </Button>
            </Uploader>
          </div>
          <div></div>
        </div>
      )}

      {validated && (
        <div className="info-msg-box py-3">
          <img src={photos.info} />
          <h3>
            CSV data does not have these required fields:
            {missingField.length > 0 &&
              missingField.map((item) => <span>{item} </span>)}
          </h3>
        </div>
      )}
      {campaignType == "Outbound" && (
        <div className="create-camp__table">
          <Table autoHeight data={csvfilterdata} loading={tableloading}>
            {headers.map((item, idx) => (
              <Column flexGrow={1} key={idx}>
                <HeaderCell>{convertHeaderName(item)}</HeaderCell>
                <Cell dataKey={item} />
              </Column>
            ))}
          </Table>
          <div style={{ padding: 20 }}>
            {csvfilterdata.length>0 && (
              <Pagination
                prev
                next
                first
                last
                // ellipsis
                // boundaryLinks
                maxButtons={5}
                size="xs"
                layout={["total", "-", "limit", "|", "pager", "skip"]}
                total={csvData.length}
                lengthMenu={[
                  {
                    value: 10,
                    label: 10,
                  },
                  {
                    value: 20,
                    label: 20,
                  },
                  {
                    value: 50,
                    label: 50,
                  },
                ]}
                displayLength={limit}
                activePage={page}
                onChangePage={setPage}
                onChangeLength={handleChangeLimit}
              />
            )}
          </div>
        </div>
      )}
      <div className="btn-float-box-excl">
        <a href="/api/campaigns/excel_file_format/" target="">
          <IconButton
            appearance="subtle"
            size="lg"
            icon={<img src={photos.csv} />}
            circle
          />
        </a>
      </div>
      <div className="btn-float-box-csv">
        <a href="/api/campaigns/csv_file_format/" target="">
          <IconButton
            appearance="subtle"
            size="lg"
            icon={<img src={photos.excl} />}
            circle
          />
        </a>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.CampaignReducer.loading,
    data: state.CampaignReducer.data,
    error: state.CampaignReducer.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    _createCampaign: (data) => dispatch(action.createCampaign(data)),
    _editCampaign: (data) => dispatch(action.editCampaign(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCampaignV2);
