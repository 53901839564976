import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux"
import App from './App';
import {store} from "./redux/store/index"

ReactDOM.render(
<Provider store={store} >
<App />
</Provider>
, document.getElementById('root'));

serviceWorker.unregister();
