import React, { useState } from "react";
import Mark from "mark.js";
import { options } from "views/Dashboard/components/LatestSales/chart";

const TTSWrapper = (WrapperComponent) => {
  const UpdatedComponent = (props) => {
    const [field, setField] = useState([]);
    const [text, setText] = useState("");

    const handleChange = (e) => {
      setTimeout(() => {
        let tempField = [];
        var mark = new Mark(document.querySelector(".text-input"));
        var innerText = document.querySelector(".text-input").innerHTML;
        setText(document.querySelector(".text-input").textContent);
        console.log("Mark: ", text);
        var str = innerText.toString(),
          exp = /{(\w*)\}/g,
          matched;
        let arry = [];
        console.log("String: ", str);
        while ((matched = exp.exec(str)) !== null) {
          arry.push(matched[1]);
          // if (field.length < 2) {
            setField((prevarr) => [
              ...prevarr,
              matched[1] !== "" ? matched[1] : null,
            ]);
          // }
        }
        console.log("Arrlskd: ", arry);
        console.log("Array: ", field);
        mark.mark(arry, {
          element: "span",
          className: "koky",
          caseSensitive: true,
          accuracy: {
            value: "partially",
            limiters: ["_"],
          },
        });
      }, 100);
    };

    const reset = () => {
      setField([]);
      setText("");
      var context = new Mark(document.querySelector(".text-input"));
      document.querySelector(".text-input").innerHTML = "";
      context.unmark({
        element: "span",
        className: "koky",
      });
    };

    return (
      <WrapperComponent
        {...props}
        handleChange={handleChange}
        field={field}
        setField={setField}
        text={text}
        resetFn={reset}
      />
    );
  };
  return UpdatedComponent;
};

export default TTSWrapper;
