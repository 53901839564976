import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
var userInfo
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 120,
    height: 120
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  // const user = {
  //   name: 'Qilin Lab',
  //   // avatar: '/images/avatars/avatar_11.png',
  //   // bio: 'Software pvt. ltd.'
  // };
  const [userState, setUserState] = useState({
    userIn: ''
  });
  useEffect(() => {
    const user1 = JSON.parse(localStorage.getItem('user'))
    console.log('userprofile', user1);
    if (user1) {
      setUserState(userState => ({
        ...userState,
        userIn: user1[0],
      }));
    }

  }, [userState.values]);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
      style={{ paddingBottom: 20 }}
    >
      <Avatar
        alt="Person"
        style={{ height: '70px', width: '70px' }}
        src={require('../../../../../../image/avatar.png')}
        to="/settings"
      />
      <p className="avatar-name" >Howdy!</p>
      <Typography
        className={classes.name}
        variant="h5"
      >
        {/* {userState.userIn.user} */}
      </Typography>
      <Typography variant="body2">
        {/* {user.bio} */}
      </Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
