import * as actionTypes from "../actionTypes"
import {updateObject} from "./utility"

const initialState={
    loading:false,
    data:null,
    error:null
}



const showLoader=(action,state)=>{
    return updateObject(state,{
        loading:true
    })
}


const hideLoader=(action,state)=>{
    return updateObject(state,{
        loading:false
    })
}

const updateDataSuccess=(action,state)=>{
    return{
        data:action.payload.data,
    }
}

const updateDataFail=(action,state)=>{
    return{
       error:action.payload.data,
    }
}



const FlowChartReducer=(state=initialState,action)=>{
    switch (action.type){
        case actionTypes.FLOW_LOADER_START:return showLoader(action,state);
        case actionTypes.FLOW_LOADER_OFF:return hideLoader(action,state);
        case actionTypes.UPDATE_FLOW_DETAIL_SUCCESS:return updateDataSuccess(action,state);
        case actionTypes.UPDATE_FLOW_DETAIL_FAIL:return updateDataFail(action,state)

        default:
            return state
    }
}



export default FlowChartReducer