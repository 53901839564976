import React, { useState, useEffect } from 'react'
import { Modal } from "rsuite"
import history from "../../helpers/history"
import { connect } from "react-redux"
import * as action from "../../redux/actions/flowchart"
import { photos } from "../../constant/images"
import { motion } from "framer-motion"
import { BsArrowLeft } from "react-icons/all"

function SelectOption(props) {
    const { show, setShow, setOption, _reset } = props
    // const [show,setShow]=useState(false)

    useEffect(() => {
        setShow(false)

    }, [])

    const selectOption = (id) => {
        setOption(id)
        setShow(false)
        _reset()
    }

    const animationWrapper = {
        hover: {
            scale: 1.1
        },
        tap: {
            scale: 0.3
        }
    }


    return (
        <div className="option-container">
            <div className="option-container__header" >
                <BsArrowLeft size={28} onClick={() => history.goBack()} />
                <h3>Select Any Option</h3>
            </div>
            <div className="option-container__option-box" >
                <motion.div className="option-container__option-box-singleBox" variants={animationWrapper} whileHover="hover" whileTap="tap" onClick={() => { selectOption(1) }}  >
                    <img src={photos.externalAudio} />
                    <h3>Upload External Audio</h3>
                </motion.div>
                <motion.div className="option-container__option-box-singleBox" variants={animationWrapper} whileHover="hover" whileTap="tap" onClick={() => { selectOption(2) }}  >
                    <img src={photos.tts} />
                    <h3>Text to Speech</h3>
                </motion.div>
                <motion.div className="option-container__option-box-singleBox" variants={animationWrapper} whileHover="hover" whileTap="tap" onClick={() => { selectOption(3) }} >
                    <img src={photos.dataLink} />
                    <h3>TTS Data Link</h3>
                </motion.div>
            </div>
        </div>
    )
}


const mapDispatchToProps = dispatch => {
    return {
        _reset: () => dispatch(action.resetFlowRedux())
    }
}


export default connect(null, mapDispatchToProps)(SelectOption)