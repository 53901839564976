import React, { Component } from 'react'
import { Select } from 'antd';
import { Row, Col, Input, Button, Collapse, Upload, message } from 'antd';
import campaign from "../../../assets/campaingAdd/campaign.module.css"
import { DownloadOutlined, UploadOutlined,  } from '@ant-design/icons';
import ReactAudioPlayer from 'react-audio-player';
import Speech from 'react-speech';
import * as gax from 'google-gax';

const textToSpeech = require('@google-cloud/text-to-speech');
const {GoogleAuth} = require('google-auth-library');
const fs = require('fs');
const util = require('util');
const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;
let dataBase = ['Qilin', 'Self'];
let scriptType = ['Audio', 'Write Script'];
const children = ['India', 'Bhutaan', 'Shri Lanka'];
const product = ['Speed Wave', 'Rainbow', 'WP Security'];
const industry = ['Finance', 'Telecom', 'Software'];
const channel = ['Robo Calling', 'Message', 'Email'];
 


var Check_db_upload = false ;

class campaignAdd extends Component {
  constructor(){
    super();
    this.state = {
      size: 'default',
      check_db:true,
      check_script: true,
      check_scriptType: false,
      pannel:1,
      Check_db_upload:false,
      searchInput:'',
      price:'2000',
      file:null,
      check_audio:false,
      textSpeech:'',
      fileType:false
    };
    this.handleChange=this.handleChange.bind(this);
    this.scriptChange = this.scriptChange.bind(this);
    this.checkDataBase = this.checkDataBase.bind(this);
    this.audioUpload = this.audioUpload.bind(this)
    this.textToSpeech=this.textToSpeech.bind(this);
  }
   
   props1 = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    listType: 'picture',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList, 'hello');
        message.success(`${info.file.name} file uploaded successfully`);
        // this.setState({Check_db_upload: true});
      

      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },

  };
  textToSpeech = (event) => {
    console.log('textToSpeech', event.target.value);
    this.setState({textSpeech:event.target.value})
  }
  audioUpload(e) {
    var fileInput = e.target.files[0];
    var filePath = fileInput;
    console.log('audio1', (filePath))

    if(e.target.files[0].type !== 'audio/mp3'){
        // alert('Please upload file having extensions .Mp3 only.');
        fileInput = '';
        this.setState({fileType:true, check_audio:false})
        return false;
    }else{
    console.log('audio', e.target.files[0])

      for (var i = 0; i < e.target.files.length; i++) {
        let file = e.target.files[i];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (result) => {
        // console.log('kuch to',result.currentTarget.result)
        this.setState({file:result.currentTarget.result, check_audio:true, fileType:false})
      } // reader.onload
      
    } // for
    }
    // console.log('audio', e.target.files[0])
    
  }
  checkDataBase =() => {
    console.log('see data===')
    this.setState({Check_db_upload: true});
    // Check_db_upload = true
  
  }

  
  handleChangeSearch = event => {
      let finalPrice = (event.target.value)*2
      this.setState({price:finalPrice})
  };
    
       handleChange(event) {
        console.log('dat va',event)
        if ( event == 'Self') {
          this.setState({check_db:true});
        } else {
          this.setState({check_db:false});
        }
        
        }
        pannelChange = () => {
        if(this.state.pannel ===1){
          this.setState({
            pannel:2 
          })
          console.log('pannel', this.state.pannel)
        }else if(this.state.pannel ===2) {
          this.setState({
            pannel:3 
          })
          console.log('pannel', this.state.pannel)
        }else if(this.state.pannel ===3) {
          this.setState({
            pannel:1 
          })
          console.log('pannel', this.state.pannel)
        }
        }
         scriptChange = (event) => {
          if ( event == 'Audio') {
            this.setState(
              {
                check_script:true,
                check_scriptType:false
              })
            

          } else {
            this.setState(
              {
                check_script:false,
                check_scriptType:true,
                check_audio:false

              })
          }
        }

        render(){
         
        }
        
    render() {  
    const mainstyle = {
        fontSize: '0.9em'
    };
    
    const textstyle = {
        container: {
            width: '100%'
        },
        text: {
            width: '100%',
            display: ''
        },
        play: {
            hover: {
                backgroundColor: 'black',
                color: 'white'
            },
            button: {
                padding: '4',
                fontSize: '1.0em',
                cursor: 'pointer',
                pointerEvents: 'none',
                outline: 'none',
                backgroundColor: 'inherit',
                border: 'none'
            }
        },
        stop: {
            hover: {
                backgroundColor: 'GhostWhite'
            },
            button: {
                width: '34px',
                height: '34px',
                cursor: 'pointer',
                pointerEvents: 'none',
                outline: 'none',
                backgroundColor: 'Gainsboro',
                border: 'solid 1px rgba(255,255,255,1)',
                borderRadius: 6
            }
        },
        pause: {
            hover: {
                backgroundColor: 'GhostWhite'
            },
            button: {
                width: '34px',
                height: '34px',
                cursor: 'pointer',
                pointerEvents: 'none',
                outline: 'none',
                backgroundColor: 'Gainsboro',
                border: 'solid 1px rgba(255,255,255,1)',
                borderRadius: 6
            }
        },
        resume: {
            hover: {
                backgroundColor: 'GhostWhite'
            },
            button: {
                width: '34px',
                height: '34px',
                cursor: 'pointer',
                pointerEvents: 'none',
                outline: 'none',
                backgroundColor: 'Gainsboro',
                border: 'solid 1px rgba(255,255,255,1)',
                borderRadius: 6
            }
        }
    };
    
      
      let { searchInput } = this.state;
        return (
            <div className={campaign.mainContainer}>
                <div className={campaign.secondaryContainer}>
                    <h2>Add Campaign</h2>
                    {/* {console.log('pnl=',this.state.pannel)} */}
                    <Collapse accordion activeKey={this.state.pannel} onClick = {this.pannelChange}>
                    <Panel className={campaign.panelColor} header="Basic Detail" key="1" onClick = {this.pannelChange}>
                    <div className={campaign.container}>
              <Row>
            <Col span={12}>
                <div className={campaign.dropdownContainer}>
                <label style={{paddingRight:15}}> Select Country</label>
            <Select size='large'  onChange={this.handleChange} style={{ width: 200 }} placeholder="Country">
              {
                  children.map((d,key)=>(
                    <Option key = {d} >{d}</Option>
                  ))
              }
            </Select>
                </div>
            </Col>
            <Col span={12}>
                <div  className={campaign.dropdownContainer} >
                <label style={{paddingRight:15}}>Product Name</label>
            <Select size='large'  onChange={this.handleChange} style={{ width: 200 }} placeholder="Product">
            {
                  product.map((d,key)=>(
                    <Option key = {d} >{d}</Option>
                  ))
              }
            </Select>
                </div>
            
            </Col>
          </Row>  
         
              </div>
              <div className={campaign.container}>
              <Row>
            <Col span={12}>
                <div className={campaign.dropdownContainer}>
                <label style={{paddingRight:15}}>Select Industry</label>
            <Select size='large' onChange={this.handleChange} style={{ width: 200 }} placeholder="Industry">
            {
                  industry.map((d,key)=>(
                    <Option key = {d} >{d}</Option>
                  ))
              }
            </Select>
                </div>
           
            </Col>
            <Col span={12}>
                <div  className={campaign.dropdownContainer} >
                <label style={{paddingRight:15}}>Channel</label>
            <Select size='large'  onChange={this.handleChange} style={{ width: 200 }} placeholder="Channel">
            {
                  channel.map((d,key)=>(
                    <Option key = {d} >{d}</Option>
                  ))
              }
            </Select>
                </div>
            
            </Col>
          </Row> 
           
          </div>
          <Row>
              <Col span ={22}>
              <div style={{justifyContent:'center', display:'flex'}}>
                <Button type="primary" onClick = {this.pannelChange}>Proceed</Button>
              </div>
              </Col>
            </Row>
    </Panel>
    <Panel className={campaign.panelColor} header="Select the Audience type" key="2" onClick = {this.pannelChange}>
    <div className={campaign.container}>
              <Row>
           
            <Col span={24}>
                <div  className={campaign.dropdownContainer} >
                <label style={{paddingRight:15}}>Type of Audience</label>
            <Select size='large'  onChange={this.handleChange} style={{ width: 200 }} placeholder="Type of Data Base" defaultValue={'Self'}>
            {
                  dataBase.map((d,i)=>(
                    <Option key = {d} value = {d}  >{d}</Option>
                  ))
              }
            </Select>
                </div>
            </Col>
          </Row>  
              </div>
             
              {
                
                this.state.check_db ?
                (
                  <div>
                            <div className={campaign.buttonFix}>
                                {/* <Button type="primary" style={{marginLeft:20}} shape="round" icon={<UploadOutlined />} size='default'>
                                        Upload Lead
                                    </Button> */}
                                    <p>Please Upload Your Database to Reach in the Following: <span style={{color:'cornflowerblue', borderBottom:'1px solid', cursor:'pointer'}}> <DownloadOutlined />Template</span></p>
                                    <div>
                                    <Upload {...this.props1} >
                                          <Button onClick = {this.checkDataBase}>
                                            <UploadOutlined /> Click to Upload
                                          </Button>
                                        </Upload>
                                    </div>
                                    {console.log('check upload')}
                                    {
                                      this.state.Check_db_upload &&
                                      <div>
                                      <p>Uploaded File have <span  style={{color:'cornflowerblue'}}>8000 Mathcing Data</span> </p>
                                      <div style={{justifyContent:'center', display:'flex', marginTop:10}}>
                                        {/* <Button type="primary" onClick = {this.pannelChange}>Proceed</Button> */}
                                      </div>
                                    </div>
                                    }
                                   
                            </div>        
              </div>
                ):
                (
                  <div>
                  <div  className={campaign.container}>
                  <Row>
                    <Col>
                    <div className={campaign.dataBasetype}>
                    <p>For Your Industry and Country We Found 2000 Matching.</p>
                    </div>
                    </Col>
                  </Row>
                </div>
              <div className={campaign.container}>
                <Row>
                    <Col>
                    <div className={campaign.dropdownContainer}>
                  <label style={{paddingRight:15}}> No. of Calls to Place </label>
                  <Input type='number' placeholder='1000' style={{ width: '60%' }}  onChange={this.handleChangeSearch} />
                  </div>
                    </Col>
                  </Row>
                </div>
                <div  className={campaign.container}>
                  <Row>
                    <Col>
                    <div className={campaign.dataBasetype}>
                    <p>Your Price : <span style={{fontWeight:500}}>{this.state.price} USD</span></p>
                    </div>
                    </Col>
                  </Row>
                </div>
                </div>
                )
               
              }
              <Row>
                <Col span={22}>
                <div style={{justifyContent:'center', display: 'flex', marginTop:20}}>
                <Button type="primary" onClick = {this.pannelChange}>Proceed</Button>
              </div>
                </Col>
              </Row>
              
    </Panel>
    <Panel className={campaign.panelColor} header="Select the Script for Robo Calling" key="3" onClick = {this.pannelChange}>
    <div className={campaign.container}>
            <Row style={{width:'100%'}}>
            <Col span={10}>
                <div className={campaign.dropdownContainer}>
                <label style={{paddingRight:15}}>Script Type</label>
            <Select size='large' onChange={this.scriptChange} style={{ width: 200 }} defaultValue={'Audio'} placeholder="Script Type">
              {
                scriptType.map( (d, index) => (
                  <Option key = {d} >{d}</Option>

                ))
              }
            </Select>
                </div>
            </Col>
  
                <Col span={6}>
                <div className={campaign.audioButton}>
                {this.state.check_script && 
                // <Button type="primary" shape="round" icon={<UploadOutlined />} size='default'>
                // Upload Audio
                // </Button>
                <div>
                  <div>
                   <input type="file" onChange={this.audioUpload} />
                  </div>
            {this.state.fileType &&
                  <label style={{color:'red', marginTop:10}}>Please Select only Mp3</label>    
               }
                </div>
              } 
              {console.log('check state', this.state.check_audio)}
              { this.state.check_audio &&
                <div style={{marginLeft:15}}>
                    <ReactAudioPlayer
                     src= {this.state.file}
                      controls
                    />

                 </div>
              }
               
                </div>
               

                </Col>
           
            </Row>
          </div>
          {
            this.state.check_scriptType && 
            <div className={campaign.container}>
            <Row className={campaign.widthRow}>
                <Col span={18}>
                <div  className={campaign.dropdownContainer} >
                    <label style={{marginRight:30}}>Script</label>
                <TextArea className={campaign.inputText} rows={10} placeholder="Write the script for Robo Calling" onChange={this.textToSpeech}/>
                </div>
                
                </Col>
          <div className={campaign.textSpeech} >
          <Speech
          styles={mainstyle}
          stop={true} 
          pause={true} 
          resume={true} 
            text= {this.state.textSpeech}
            voice="Google US English Male"
            pitch="0.5"
            lang="en-US-Wavenet-D"
        />
          </div>
            </Row>
        </div>
          }
          <Row>
              <Col span ={22}>
              <div style={{justifyContent:'center', display:'flex'}}>
                <Button type="primary" onClick = {this.pannelChange}>Proceed</Button>
              </div>
              </Col>
            </Row>
    </Panel>
        
          </Collapse>
          <div style={{paddingTop:20}}>
          <Button type="primary" block>
      Save
    </Button>
          </div>
              </div>
              
            </div>
         
        );
      }
}

export default campaignAdd;
