import * as actionTypes from "../actionTypes"
import { updateObject } from "./utility"

const initialState = {
    loading: false,
    data: null,
    error: null
}



const updateFail = (action, state) => {
    return updateObject(state, {
        error: action.payload.data
    })
}

const updateSuccess = (action, state) => {
    return updateObject(state, {
        data: action.payload.data
    })
}

const LoaderStart = (action, state) => {
    return updateObject(state, {
        loading: true
    })
}
const LoaderOff = (action, state) => {
    return updateObject(state, {
        loading: false
    })
}

const reset = (action, state) => {
    return updateObject(state, {
        data: null,
        error: null,
    })
}


const CampaignReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_CREATE_CAMPAIGN_SUCCESS: return updateSuccess(action, state);
        case actionTypes.UPDATE_CREATE_CAMPAIGN_FAIL: return updateFail(action, state);
        case actionTypes.CREATE_CAMPAIGN_START: return LoaderStart(action, state)
        case actionTypes.CREATE_CAMPAIGN_FINISH: return LoaderOff(action, state)
        case actionTypes.RESET_CREATE_CAMPAIGN: return reset(action, state)
        case actionTypes.DUPLICATE_CAMPAIGN_START: return LoaderStart(action, state);
        case actionTypes.DUPLICATE_CAMPAIGN_FINISH: return LoaderOff(action, state);
        case actionTypes.UPDATE_DUPLICATE_CAMPAIGN_SUCCESS: return updateSuccess(action, state);
        case actionTypes.UPDATE_DUPLICATE_CAMPAIGN_FAIL: return updateFail(action, state);
        case actionTypes.UPDATE_EDIT_CAMPAIGN_SUCCESS: return updateSuccess(action, state)
        case actionTypes.UPDATE_EDIT_CAMPAIGN_FAIL: return updateSuccess(action, state)
        default:
            return state
    }
}



export default CampaignReducer