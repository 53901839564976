/** @format */

import * as actionTypes from "../actionTypes";
import { updateObject } from "./utility";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const updateFail = (action, state) => {
  return updateObject(state, {
    error: action.payload.data,
  });
};

const updateSuccess = (action, state) => {
  return updateObject(state, {
    data: action.payload.data,
  });
};

const LoaderStart = (action, state) => {
  return updateObject(state, {
    loading: true,
  });
};
const LoaderOff = (action, state) => {
  return updateObject(state, {
    loading: false,
  });
};

const resetTTSReducer = (action, state) => {
  return updateObject(state, {
    data: null,
  });
};

const resetFlow = (action, state) => {
  return updateObject(state, {
    data: null,
  });
};

const TextToSpeechReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_TEXT_TO_SPEECH_SUCCESS:
      return updateSuccess(action, state);
    case actionTypes.UPDATE_TEXT_TO_SPEECH_FAIL:
      return updateFail(action, state);
    case actionTypes.TEXT_TO_SPEECH_START:
      return LoaderStart(action, state);
    case actionTypes.TEXT_TO_SPEECH_OFF:
      return LoaderOff(action, state);
    case actionTypes.UPDATE_TTSWDL_SUCCESS:
      return updateSuccess(action, state);
    case actionTypes.UPDATE_TTSWDL_FAIL:
      return updateFail(action, state);
    case actionTypes.TTSWDL_START:
      return LoaderStart(action, state);
    case actionTypes.TTSWDL_OFF:
      return LoaderOff(action, state);
    case actionTypes.RESET_TTS_REDUCER:
      return resetTTSReducer(action, state);
    case actionTypes.RESET_FLOW_REDUX:
      return resetFlow(action, state);
    default:
      return state;
  }
};

export default TextToSpeechReducer;
