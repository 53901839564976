/** @format */

import * as actionTypes from "../actionTypes";
import { authApi, postApiRequest } from "../actions/api";
import * as API from "../../ApiUrl";
import {
  handleSuccess,
  handleFail,
  loaderStart,
  loaderOff,
  fetchCampaignSuccess,
  fetchCampaignFail,
} from "../actions/start";
import * as trigger from "../actions/start"
import history from "../../helpers/history";
import { Alert, Notification } from "rsuite";

export const handleCampaign = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.CAMPAIGN_DETAIL) {
    let data = action.payload.data;
    let dataS = JSON.parse(localStorage.getItem("campDetail"));
    let key = dataS.key;
    let onSuccess = actionTypes.CAMPAIGN_DETAIL_SUCCESS;
    let onError = actionTypes.CAMPAIGN_DETAIL_FAIL;
    dispatch(
      authApi(
        "PATCH",
        `${API.PATCH_CAMPAIGN}${key}/`,
        null,
        onSuccess,
        onError,
        data,
        null
      )
    );
    dispatch(loaderStart());
  }
};

export const handleCampaignSuccess = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.CAMPAIGN_DETAIL_SUCCESS) {
    let data = action.payload;
    console.log("Handle Start API: ", data);
    const openNotification = () => {
      return Notification.success({
        title: "Success !",
        description: "Your changes has been updated!",
      });
    };
    if (data) {
      dispatch(handleSuccess(data));
      dispatch(loaderOff());
      openNotification();
    }
  }
};

export const handleCampaignFail = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.CAMPAIGN_DETAIL_FAIL) {
    dispatch(loaderOff());
    const openNotification = () => {
      return Notification.error({
        title: "Whopps !",
        description: "Something went wrong!.",
      });
    };
    openNotification();
  }
};

export const fetchCampaignDetail = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.FETCH_CAMPAIGN_DETAIL) {
    let data = JSON.parse(localStorage.getItem("campDetail"));
    let key = data.key;
    let onSuccess = actionTypes.FETCH_CAMPAIGN_DETAIL_SUCCESS;
    let onError = actionTypes.FETCH_CAMPAIGN_DETAIL_FAIL;
    dispatch(
      authApi(
        "GET",
        `${API.PATCH_CAMPAIGN}${key}/`,
        null,
        onSuccess,
        onError,
        null,
        null
      )
    );
    // dispatch(loaderStart())
  }
};

export const fetchCampaignDetailSuccess = ({ dispatch }) => (next) => (
  action
) => {
  next(action);
  if (action.type === actionTypes.FETCH_CAMPAIGN_DETAIL_SUCCESS) {
    let data = action.payload;
    if (data) {
      dispatch(fetchCampaignSuccess(data));
    }
  }
};

export const fetchCampaignDetailFail = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.FETCH_CAMPAIGN_DETAIL_FAIL) {
    let data = action.payload;
    if (data) {
      dispatch(fetchCampaignFail(data));
    }
    // dispatch()
  }
};

export const fetchTimezone = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.FETCH_TIMEZONE) {
    let onSuccess = actionTypes.FETCH_TIMEZONE_SUCCESS;
    let onError = actionTypes.FETCH_TIMEZONE_FAIL;
    dispatch(
      authApi("GET", API.TIMEZONE, null, onSuccess, onError, null, null)
    );
  }
};

export const fetchTimezoneSuccess = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.FETCH_TIMEZONE_SUCCESS) {
    let data = action.payload;
    if (data) {
      dispatch({
        type: actionTypes.UPDATE_FETCH_TIMEZONE_SUCCESS,
        payload: { data },
      });
    }
  }
};

export const fetchTimezoneFail = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.FETCH_TIMEZONE_FAIL) {
    let data = action.payload;
    if (data) {
      dispatch({
        type: actionTypes.UPDATE_FETCH_TIMEZONE_FAIL,
        payload: { data },
      });
    }
  }
};



export const fetchSimCards=({dispatch})=>next=>action=>{
  next(action)
  if(action.type === actionTypes.FETCH_SIM_CARDS){
    let onSuccess=actionTypes.FETCH_SIM_CARDS_SUCCESS;
    let onFail=actionTypes.FETCH_SIM_CARDS_FAIL;
    dispatch(authApi('GET',API.GET_SIMCARDS,null,onSuccess,onFail,null,null))
    dispatch({type:actionTypes.FETCH_SIM_CARDS_START})
  }
}


export const fetchSimCardsSuccess=({dispatch})=>next=>action=>{
  next(action)
  if(action.type === actionTypes.FETCH_SIM_CARDS_SUCCESS){
    let data=action.payload;
    if(data){
      dispatch(trigger.updateFetchedSimCardSuccess(data))
    }
    dispatch({type:actionTypes.FETCH_SIM_CARDS_FINISH})
  }
}

export const fetchSimCardsFail=({dispatch})=>next=>action=>{
  next(action)
  if(action.type === actionTypes.FETCH_SIM_CARDS_FAIL){
    let data=action.payload;
    if(data){
      dispatch(trigger.updateFetchedSimCardFail(data))
    }
    dispatch({type:actionTypes.FETCH_SIM_CARDS_FINISH})
  }
}

export const campMdl = [
  handleCampaign,
  handleCampaignSuccess,
  handleCampaignFail,
  fetchCampaignDetailSuccess,
  fetchCampaignDetailFail,
  fetchCampaignDetail,
  fetchTimezone,
  fetchTimezoneSuccess,
  fetchTimezoneFail,
  fetchSimCards,
  fetchSimCardsSuccess,
  fetchSimCardsFail
];
