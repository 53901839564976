import React,{useState} from 'react'
import {Sidenav,Nav,Navbar} from "rsuite"
import {FiCpu,IoIosRecording,FaSimCard} from "react-icons/all"
const Pages=[
    {
        title: 'Campaign',
        href: '/campaign',
        icon: <FiCpu />,
        id:1
      },
      {
        title: 'Audio Script',
        href: '/audioScriptView',
        icon: <IoIosRecording />,
        id:2
      },
      {
        title: 'Sim Card',
        href: '/simcardview',
        icon: <FaSimCard />,
        id:3
      },
]


    
export default function SidebarNew(props) {
    const [expanded,setExpanded]=useState(true)
    const [activeKey,setActiveKey]=useState(1)


    const handleSelect=(eventKey)=>{
        setActiveKey(eventKey)
    }


    return (
        <div>
            <Sidenav expanded={expanded} defaultOpenKeys={['3','4']} activeKey={activeKey} onSelect={handleSelect}>
                <Sidenav.Body>
                <Nav>
                    {Pages.map((item,index)=>{return(
                        <Nav.Item eventKey={item.id} icon={item.icon}>{item.title}</Nav.Item>
                    )})}
                </Nav>
                </Sidenav.Body>
            </Sidenav>
        </div>
    )
}
