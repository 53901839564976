/** @format */

import React, { useState } from "react";
import Demo from "./Demo";
import Main from "./Main";
import Example from "./Example";
import { useRef } from "react";
import { images } from "../../../constant";
import { useDimensions } from "../../../helpers/useDimensions";
import { motion, useCycle } from "framer-motion";
import { connect } from "react-redux";

const { photos } = images;
function TextToSpeechDL(props) {
  const [showMain, setMain] = useState(true);
  const { setOption, data, fData } = props;
  return (
    <div className="tts-dl-page">
      {/* <Demo/> */}
      <div className="row">
        <div className="col-md-6">
          {showMain && <Example setMain={setMain} setOption={setOption} />}
          {!showMain && <Main />}
        </div>
        <div className="col-md-6">
          <div className="tts-static">
            {data == null &&
              <motion.div
                className="demo-div"
                initial={{ y: 150, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ ease: "easeOut", duration: 0.5 }}
              >
                <img src={photos.demoArt} />
              </motion.div>}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    data: state.TextToSpeechReducer.data,
    fData: state.AudioReducer.data,
  };
};

export default connect(mapStateToProps, null)(TextToSpeechDL);
