import * as actionTypes from "../actionTypes";
import { updateObject } from "./utility";

const initialState = {
  loading: false,
  data: null,
  error: null,
  overallData: null,
  overallError: null,
  overallLoading: false,
};

const updateFail = (action, state) => {
  return updateObject(state, {
    error: action.payload.data,
  });
};

const updateSuccess = (action, state) => {
  return updateObject(state, {
    data: action.payload.data,
  });
};

const LoaderStart = (action, state) => {
  return updateObject(state, {
    loading: true,
  });
};
const LoaderOff = (action, state) => {
  return updateObject(state, {
    loading: false,
  });
};

const overallLoadingStart = (action, state) => {
  return updateObject(state, {
    overallLoading: true,
  });
};
const overallLoadingOff = (action, state) => {
  return updateObject(state, {
    overallLoading: false,
  });
};

const resetResult = (action, state) => {
  return updateObject(state, {
    data: null,
    overallData: null,
  });
};

const updateOverallSuccess = (action, state) => {
    console.log("ddd",action.payload)
  return updateObject(state, {
    overallData: action.payload.data,
    loading: false,
  });
};

const updateOverallFail = (action, state) => {
  return updateObject(state, {
    overallError: action.payload.data,
    loading: false,
  });
};

const ResultReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_FETCH_RESULT_SUCCESS:
      return updateSuccess(action, state);
    case actionTypes.UPDATE_FETCH_RESULT_FAIL:
      return updateFail(action, state);
    case actionTypes.FETCH_RESULT_START:
      return LoaderStart(action, state);
    case actionTypes.FETCH_RESULT_OFF:
      return LoaderOff(action, state);
    case actionTypes.RESET_RESULT_REDUX:
      return resetResult(action, state);
    case actionTypes.UPDATE_OVERALL_RESULT_SUCCESS:
      return updateOverallSuccess(action, state);
    case actionTypes.UPDATE_OVERALL_RESULT_FAIL:
      return updateOverallFail(action, state);
    case actionTypes.FETCH_OVERALL_RESULT_START:
      return overallLoadingStart(action, state);
    case actionTypes.FETCH_OVERALL_RESULT_FINISH:
      return overallLoadingOff(action, state);
    default:
      return state;
  }
};

export default ResultReducer;
