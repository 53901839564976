import React, { Component } from 'react'
import { Button } from '@material-ui/core';
import ReactAudioPlayer from 'react-audio-player';
import * as API from "../../../ApiUrl";
import * as Language from './LanguageCode';
import { Row, Table, Input,  Col, Upload, Select } from 'antd';
import Swal from 'sweetalert2';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Loader from 'react-loader-spinner'
import { Redirect } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
const { TextArea } = Input;
const { Option } = Select;
var uploadBoolean = false;
var enc
var lang = []
lang = Language.isoLangs
var languageCode = [];
for (let i = 0; i < lang.length; i++) {  
  languageCode.push(<Option key={lang[i].code}>{lang[i].name}</Option>);
}
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class AudioScriptAdd extends Component { 
    constructor(){
        super();
        this.state = {
         selected: null,
         textSpeech: '',
         audio:'',
         audioFile:'',
         textToSpeechAudio:'',
         textToSpeechFile:'',
         textToSpeechDuration:'',
         tableDate:'',
         duration:'',
         scriptNameByUser:'',
         langCode:'',
         fileType:true,
         check_audio:'',
         audioSrc:'',
         token:'',
         userInfo:'',
         campaignSuccess:false,
         loading:false,
         errorMsg:false
        };
    this.textToSpeech=this.textToSpeech.bind(this);
    // this.audioSrcFile =this.audioSrcFile.bind(this)
     
      }
      async componentDidMount () {
        this.setState({campaignSuccess:false})
        const token = await localStorage.getItem('token')
        const user = await JSON.parse(localStorage.getItem('user'))
        if(user) {

          this.setState({token:token})
          this.setState({userInfo:user[0]})
          console.log('token', user[0]);
        }

        }
      textToSpeech = (event) => {
        this.setState({textSpeech:event.target.value})
        console.log('textToSpeech', this.state.textSpeech);
      } 
      notify = () =>{
       toast.warn("Please check the Audio and Confirm !", {
         position: "top-center",
       });
      }
      showLoader = () => {
        this.setState({loading:true})
      }
      hideLoader = () => {
        this.setState({loading:false})
      }
      
      // insertExternalScript = () => {
      //   console.log('audiofile', this.state.audioFile);
      //   let obj = {
      //     scriptName:this.state.audioFile.name,
      //     duration:this.state.duration,
      //     date:this.state.audioFile.lastModifiedDate.toLocaleDateString(),
      //     status:['upload'],
      //     file:this.state.audioFile,
      //     src:this.state.audioSrc
      //   }
      //   myArrya.push(obj)
      //   console.log('maarry', myArrya);
      //   this.setState({tableDate:myArrya})
      // }
      langCodeSelect = (e) => {
        console.log('language', e);
        this.setState({langCode:e})  
      }
      scriptNameChange = (e) => {
        // console.log('script name', e.target.value);
        this.setState({scriptNameByUser:e.target.value})   
      }
      audioSrcFile = (e) => {
        this.setState({audioSrc:null})  
        e.preventDefault();
        if(this.state.scriptNameByUser == '' || this.state.langCode == '') {
          this.setState({errorMsg:true})
        } else {
          this.showLoader()
          fetch(API.TEXT_TO_SPEECH , {
            method: "POST",
            body: JSON.stringify({
              text:this.state.textSpeech,
              lang_code: this.state.langCode,
              name:this.state.scriptNameByUser
            }),
            headers: {
                "Authorization": `Bearer ${this.state.token}`,
                "Content-type": "application/json;",
            },
        })
        .then(res => res.json())
        .then(res => {
          this.hideLoader()
          console.log('resaudio', res);
          if(res) {
            this.setState({textToSpeechAudio:res})
            fetch(res.url)
      .then(response=> response.blob())
      .then(response => {
        let file1 = new File([response], "test.mp3", {type:'audio/mp3'});        
        console.log('created file',file1);
        this.setState({textToSpeechFile:file1})
  
      })
            this.setState({audioSrc:res.url})
            console.log('hello', this.state.textToSpeechAudio.name);
            console.log('hello', this.state.textToSpeechFile);
            this.notify();
            
          }
          
        }) 
        }      
        
      }
      insertTextToSpeechScript = (r) => {
        this.showLoader()
          const formData = new FormData(); 
      
        // Update the formData object 
        formData.append("audiofile",this.state.textToSpeechFile, this.state.textToSpeechAudio.name); 
        formData.append("name",this.state.textToSpeechAudio.name); 
        formData.append("length_in_second", this.state.textToSpeechAudio.duration); 
        formData.append("text_messge",'hello');
        formData.append("user", this.state.userInfo.user_id);
      //   for (var pair of formData.entries()) {
      //     console.log(pair[0]+ ', ' + pair[1]); 
      // }
          fetch(`${API.GET_SCRIPT}`, {
            method: "POST",
            body: formData,
            headers: {
                "Authorization": `Bearer ${this.state.token}`,
            },
        })
        .then(res => {
          this.hideLoader()
          console.log('resotp', res.status);
          if(res.status == 201) {
            this.setState({campaignSuccess:true})
            Swal.fire({
              icon: 'success',
              title: 'Audio Uplouded Successfully',
              showConfirmButton: false,
              timer: 1500
            })
          } else {
            console.log('error audio');
            
          }
        })        
        }
      insertScript = (r) => {
        console.log("Audio File sending 1 txtAudioFile:",this.state.audioFile)

      this.showLoader()
        const formData = new FormData(); 
      // Update the formData object 
      formData.append("audiofile",this.state.audioFile); 
      formData.append("name",this.state.audioFile.name); 
      formData.append("length_in_second",this.state.duration); 
      formData.append("text_messge",'hello');
      formData.append("user", this.state.userInfo.user_id);
    //   for (var pair of formData.entries()) {
    //     console.log(pair[0]+ ', ' + pair[1]); 
    // }
    console.log("Form Data: ",formData)
        fetch(`${API.GET_SCRIPT}`, {
          method: "POST",
          body: formData,
          headers: {
              "Authorization": `Bearer ${this.state.token}`,
          },
      })
      .then(res => {
        this.hideLoader()
        console.log('resotp', res.status);
        if(res.status == 201) {
          this.setState({campaignSuccess:true})
          Swal.fire({
            icon: 'success',
            title: 'Audio Uploaded Successfully',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          console.log('error audio');
          
        }
      })        
      }
      upload = () => {
        uploadBoolean = true
      }
      onClickRow = (r) => {
        return {
          onClick: () => {
            if(uploadBoolean === true){
              this.insertScript(r)
            }
          },
    
        };
      }
       handleChange = e => {
        e.preventDefault()
        console.log('file', e.target.files);
        
        var audio = document.createElement('audio');
         console.log("Audio Src: ",audio)
         if(e.target.files[0].type == 'audio/mpeg') {
          
           for (var i = 0; i < e.target.files.length; i++) {
             console.log("File in for: ",e.target.files[i])
            let file = e.target.files[i];
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (result) => {
            console.log("Audio Result: ",result)
            audio.src = result.target.result;
            audio.addEventListener('loadedmetadata', () => {
              console.log(audio.duration);
              this.setState({duration:audio.duration.toFixed(2)})
            });
            console.log('kuch to',this.state.duration)

          this.setState({audioSrc:result.currentTarget.result, check_audio:true, fileType:false})
          this.notify();
          } // reader.onload
          
        } // for
       
          this.setState({audioFile: e.target.files[0]})
          this.setState({fileType:false})
          
         } else {
          this.setState({fileType:true})
           console.log('please mp3');
           Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Uploaded File Must be of Mp3 type. Please Recheck'
          })
         }  
      
      };
      showModal = (e) => {
        console.log('show ho jaaaa');
        this.setState({ showModal: true })
      }
    
      hideModal = (e) => {
        this.setState({ showModal: false })
      }
      handleClose = (event, reason) => {
    
        if (reason === 'clickaway') {
          this.setState({errorMsg:false})
          return;
        }
      }
      render() {
        if(this.state.campaignSuccess == true) {
          return <Redirect to = '/audioScriptView' />
        }
        let { data, searchInput } = this.state;
        let { sortedInfo, filteredInfo } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};
        const columns = [
          {
            title: 'Script Name',
            dataIndex: 'scriptName',
            key: 'scriptName',
            sorter: (a, b) => a.scriptName.length - b.scriptName.length,
            sortOrder: sortedInfo.columnKey === 'scriptName' && sortedInfo.order,
            ellipsis: true,
          },
          {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            sorter: (a, b) => a.duration - b.duration,
            sortOrder: sortedInfo.columnKey === 'duration' && sortedInfo.order,
            ellipsis: true,
          },
          {
            title: 'Date of Creation',
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => a.date - b.date,
            sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order,
            ellipsis: true,
          },
          {
            title: 'Status ',
            key: 'status',
            dataIndex: 'status',
            render: action => (
              <span >
                {
                action.map(tag => {
                  return (
                    <Button style={{backgroundColor:'#F35D5E', color:'white'}} type="primary" onClick = {this.upload}>Upload</Button>
                  );
                })}
              </span>
            ),
          },
        ]
       
    
          return(

        <div>
           { this.state.loading &&
                <div className='row'>
                <div className = 'col' style={{zIndex:1000,height:'100%', marginRight: 8, background: 'rgba(0,0,0,0.2)',  position:'fixed', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Loader
                    type="Audio"
                    color="#F35D5E"
                    height={100}
                    width={100}
                    
                    style = {{marginRight:'10%'}}
                  />
                </div>
              </div>
              }
           <div style ={{marginLeft:10, marginTop:30}}>
                          <h3 style ={{color:'#F35D5E'}}>Create Script</h3>
                      </div>
                      <div className='row' style={{marginLeft:30, marginTop:30, marginRight:0}}>
                        <div >
                       <Input placeholder="Script Name" onChange={this.scriptNameChange}/>
                        </div>
                        <div style={{marginLeft:15}}>
                        <Select
                          showSearch
                          style={{ width: 200 }}
                          placeholder="Select a language"
                          optionFilterProp="children"
                          onChange={this.langCodeSelect}
                          // onFocus={onFocus}
                          // onBlur={onBlur}
                          // onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {languageCode}
                        </Select>
                        </div>
                      </div>
            <div className = 'row' style={{marginLeft:15, marginRight:0, marginTop:20}}>
                <div className = 'col'>
                <div >
                    <label style={{marginRight:30}}>Script</label>
                <TextArea  rows={10} placeholder="Write the script for Robo Calling" onChange={this.textToSpeech}/>
                </div> 
                </div>
                <div className = 'col'>
                <div style={{display:'flex', height:'100%', justifyContent:'center', alignItems:'center'}} >
                  <div style={{
                    backgroundColor:'black',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'flex-end',
                    paddingBottom:15,
                    borderRadius:15,
                    height:'75%',
                    width:'70%'
                    }}>
                      
                  {/* <ReactAudioPlayer
                     src= {this.state.audioSrc}
                      controls
                    />  */}
                    <AudioPlayer
                      autoPlay = {false}
                      src={this.state.audioSrc}
                      onPlay={e => console.log("onPlay")}
                      style={{width:'85%', borderRadius:10}}
                    />
                  </div>
          </div>
        </div>
      </div>
      <div className='row' style={{marginTop:'2%', marginRight:0}}>
      <div className='col' style={{textAlign:'center', marginTop:20}}>
        <Button onClick = {this.audioSrcFile} style={{backgroundColor:'#F35D5E', color:'white'}}>Convert</Button>
      </div>
      <div className='col' style={{textAlign:'center', marginTop:20}}>
        <Button onClick = {this.insertTextToSpeechScript} style={{backgroundColor:'#F35D5E', color:'white'}}>Confirm</Button>
      </div>
      </div>
      <div style ={{textAlign:'center', marginTop:20}}>
        <h4>OR</h4>
      </div>
      <div>
      <div style = {{display:'flex', marginTop:10, marginLeft:15}}>
      <span>Upload External Audio:</span>
      <div >
      <input type="file" accept="audio" className="form-control" onChange={this.handleChange} placeholder="Upload"  style={{marginLeft:18}} aria-label="Recipient's username" aria-describedby="basic-addon2"/>
      </div>
      <div className="input-group-append">
       <span className="input-group-text" id="basic-addon2"  style={{color:'#F35D5E', cursor:'pointer'}}><CloudUploadIcon /></span>
       <Button onClick = {this.insertScript} disabled = { this.state.fileType } style={{marginLeft:30, marginTop:2, backgroundColor:'#F35D5E', color:'white'}}>Confirm</Button>
       <ToastContainer />
     </div>
    </div>
      </div>
      <div>
                  <Snackbar open={this.state.errorMsg} autoHideDuration={6000} onClose={this.handleClose}>
                <Alert severity="error">All Field are Mandatory</Alert>
                  </Snackbar>
                </div>
      {/* <div style={{marginTop:40}}>
                  <Table columns={columns}
                    dataSource={this.state.tableDate}
                    onRow={this.onClickRow}
                    
                    // onChange={this.handleChange}  
                    pagination={{ pageSize: 10}}
                        />
                  </div> */}
  </div>
          )
         
    }
}

export default AudioScriptAdd;
