import React, { useEffect, useState } from 'react'
import { Input, Button, SelectPicker, Placeholder, Alert } from 'rsuite'
import { reduxForm, Field, change, reset } from "redux-form"
import AudioPlayer from 'react-h5-audio-player';
import * as API from "../../../ApiUrl"
import { connect } from 'react-redux'
import moment from "moment"
import history from "../../../helpers/history"
import * as action from "../../../redux/actions/audio"
const { Paragraph } = Placeholder
let Audio = (props) => {
    const { script, _fetchScript, selectedNode, _sendAudioScript, loading, audioFlow, flowLoading, error, flowLoader,
        audioLoading, prevChart, updateNodeRedux } = props
    const [audio, setAudio] = useState([])
    const [selectedScript, setSelectedScript] = useState(null)
    const [selectedAudio, setSelectedAudio] = useState(null)
    const tempData = [
        { label: "Test", value: 1 },
        { label: "Test1", value: 2 },
        { label: "Test2", value: 3 },
    ]
    useEffect(() => {
        console.log("Props: ", props)
        _fetchScript()
    }, [])

    useEffect(() => {
        console.log("Audio Script: ", script)
        if (script != null) {
            let audios = []
            for (let i in script) {
                let data = script[i]
                data.label = script[i].name
                data.value = script[i].id
                audios.push(data)
            }
            setAudio(audios)
            console.log("Audio Script: ", audio)
        }
    }, script)

    useEffect(() => {
        let mounted = true
        if (audioFlow != null) {
            let data = {
                label: audioFlow.script_name,
                value: audioFlow.script_id
            }
            setSelectedAudio(audioFlow.script_name)
            let ad = audio.filter((item) => item.id == audioFlow.script_id)
            setSelectedScript(...ad)
            console.log("Ad: ", selectedScript)

        }
        console.log("selected audio file: ", selectedAudio)
        return () => {
            mounted = false
        }
    }, [audioLoading, audioFlow, audio])

    const sendAudioScript = () => {
        console.log("Selected Script: ", selectedScript)
        console.log("Selected Node: ", selectedNode)
        let key = JSON.parse(localStorage.getItem("campDetail"));
        let data = {
            script_id: selectedScript.id,
            node_id: selectedNode.id,
            campaign_id:key.key
        }
        updateNodeRedux(prevChart,_sendAudioScript,data)

        // setTimeout(() => {
        //     _sendAudioScript(data)
        // }, 2000)
    }

    const createScript = () => {
        Alert.warning("Let us first save your Flow", 3000);
        updateNodeRedux(prevChart)
        setTimeout(() => {
            history.push({ pathname: "/audio-script/add-script", state: { flow: true } })
        }, 2000)
    }



    return (
        <div className="audio-activity-box-form">
            {!audioLoading ? <SelectPicker data={audio}
                placeholder="Choose Audio Script"
                block
                cleanable={false}
                // value={'Test'}
                valueKey={'label'}
                value={selectedScript != undefined ? selectedScript.label : undefined}
                // defaultValue={selectedScript!=undefined?selectedScript.label:undefined}

                onSelect={(a, b, c) => setSelectedScript(b)} /> :
                <Paragraph rows={1} rowHeight={25} active style={{ width: '100%' }} />

            }
            <div className="audio-player-box pt-4">
                {selectedScript != null && !audioLoading &&
                    <AudioPlayer
                        src={`${selectedScript.audiofile}`}
                        autoPlayAfterSrcChange={false}
                    />}
            </div>
            <div className="pt-4"><h5 className="text-center">OR</h5></div>

            <div className="pt-3">
                <Button appearance="ghost" onClick={createScript} block
                >Create Script</Button>
            </div>
            <div className="confirm-box">
                <Button 
                color={flowLoader?"yellow":undefined}
                appearance="primary" type="submit" size="lg" block onClick={sendAudioScript} loading={loading}
                    disabled={selectedScript == null || flowLoader } >{flowLoader?"Flow is being Saved...":"Confirm"}</Button>
            </div>
        </div>
    )
}







const mapStateToProps = (state) => {
    return {
        script: state.AudioReducer.audio,
        loading: state.AudioReducer.loading,
        audioFlow: state.AudioFlowReducer.data,
        audioLoading: state.AudioFlowReducer.loading,
        flowLoading: state.NodeReducer.loading,
        error: state.AudioFlowReducer.error
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        _fetchScript: () => dispatch(action.handleFetchScript()),
        _sendAudioScript: (data) => dispatch(action.sendAudioScript(data))
    }
}


Audio = connect(mapStateToProps, mapDispatchToProps)(Audio)


export default reduxForm({
    form: 'Activity-Start-Form',
})(Audio)