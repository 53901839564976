import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button } from "rsuite"
import { motion } from "framer-motion"
import Chance from "chance"
import * as action from "../../../../redux/actions/flowchart";
import {getRuleId} from './helper';
import { Alert } from "rsuite";


export const Keypad = (props) => {
    const { flow, _updateNodeRedux, language, selectedNode ,chart } = props

    const [key, setKey] = useState(null)
    const [nodes, setNodes] = useState(null)

    const pressedKey = (e) => {
        e.persist()
        console.log("Pressed: ", e)
        if (e.target.ariaLabel == null) {
            setKey(e.target.parentElement.ariaLabel)
            console.log("Pressed key: ", e.target.parentElement.ariaLabel)

        }
        else {
            setKey(e.target.ariaLabel)
        }
    }

    const updateNode = (e) => {
        let chance = new Chance()
        let nodeId = chance.string({ length: 36, casing: 'upper', alpha: true, numeric: true });
        let linkId = chance.string({ length: 36, casing: 'upper', alpha: true, numeric: true });
        let newNode = chart;
        getRuleId(chart,selectedNode.id)
        .then((res)=>{
            console.log('==Rule Node Found Success===',res)

            let oneNode1 = {
                id: nodeId,
                type: key,
                orientation: 0,
                ports: {
                    port1: {
                        id: 'port1',
                        type: 'top',
                        properties: {
                            custom: 'property'
                        },
                        position: {
                            x: 100,
                            y: 64
                        }
                    },
                    port2: {
                        id: "port2",
                        type: "bottom",
                        properties: {
                            custom: "property",
                        },
                    },
                },
                position: {
                    x: res.rulePosition.x,
                    y: res.rulePosition.y + 100
                },
                properties: {
                    custom: 'property',
                    node_type: "Rule Child",
                    rule_type: "Keypad",
                    number: key,
                    rule_node_id: res.ruleId
                },
                size: {
                    width: 200,
                    height: 64
                }
    
            }
    
            let link = {
                id: linkId,
                from: {
                    nodeId: nodeId,
                    portId: 'port1'
                },
                to: {
                    nodeId: res.ruleId,
                    portId: 'port2'
                }
            }
    
            newNode = {
                ...chart,
                nodes: {
                    ...chart.nodes,
                    [nodeId]: oneNode1
                },
                links: {
                    ...chart.links,
                    [linkId]: link
                }
            }
            _updateNodeRedux(newNode,null,"reload")
            console.log("New Nodes:  ", newNode)
        })
        .catch((err)=>{
            Alert.error('Something went wrong. Refreshing your session',2000)
            window.location.reload()
        })
  
    }

    return (
        <div className="keypad-container">
            <motion.div
                initial={{ height: '0px' }}
                animate={{ height: key != null ? '100px' : '0px' }}
                className={key != null ? "node-display" : ""}>
                {key != null && <>
                    <p>{key}</p>
                    <p>Pressed Key</p>
                </>}
            </motion.div>

            <div className="keypad-box">
                <div aria-label="1" onClick={(e) => pressedKey(e)} >
                    <p>1</p>
                </div>
                <div aria-label="2" onClick={(e) => pressedKey(e)}>
                    <p>2</p>
                    <p>A B C</p>
                </div>
                <div aria-label="3" onClick={(e) => pressedKey(e)}>
                    <p>3</p>
                    <p>D E F</p>
                </div>
                <div aria-label="4" onClick={(e) => pressedKey(e)}>
                    <p>4</p>
                    <p>G H I</p>
                </div>
                <div aria-label="5" onClick={(e) => pressedKey(e)}>
                    <p>5</p>
                    <p>J K L</p>
                </div>
                <div aria-label="6" onClick={(e) => pressedKey(e)}>
                    <p>6</p>
                    <p>M N O</p>
                </div>
                <div aria-label="7" onClick={(e) => pressedKey(e)}>
                    <p>7</p>
                    <p>P Q R S</p>
                </div>
                <div aria-label="8" onClick={(e) => pressedKey(e)}>
                    <p>8</p>
                    <p>T U V</p>
                </div>
                <div aria-label="9" onClick={(e) => pressedKey(e)} >
                    <p>9</p>
                    <p>W X Y Z</p>
                </div>
                <div aria-label="*" onClick={(e) => pressedKey(e)}>
                    <p>*</p>
                </div>
                <div aria-label="0" onClick={(e) => pressedKey(e)} >
                    <p>0</p>
                    <p>+</p>
                </div>
                <div aria-label="#" onClick={(e) => pressedKey(e)} >
                    <p>#</p>
                </div>
            </div>
            {key != null && <>
                <div className="py-3">
                    <Button appearance="primary" block size="lg" onClick={updateNode} >Create Rule</Button>
                </div>
                <div className="py-1">
                    <Button appearance="subtle" block size="lg" onClick={() => setKey(null)} >Clear</Button>
                </div>
            </>}
        </div>
    )
}

const mapStateToProps = (state) => ({
    flow: state.NodeReducer.flow
})

const mapDispatchToProps = dispatch => {
    return {
        _updateNodeRedux: (data,callback,cbParam) => dispatch(action.updateNodeRedux(data,callback,cbParam)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Keypad)
