/** @format */

import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Field, reduxForm, reset } from "redux-form";
import { Input, Button, SelectPicker, Icon } from "rsuite";
import { photos } from "../../../constant/images"
import { connect } from "react-redux";
import { GiSettingsKnobs } from "react-icons/all";
import * as action from "../../../redux/actions/audio";
import * as changeAction from "../../../redux/actions/_change"
import AudioPlayer from "react-h5-audio-player";
import TTSWrapper from "./HOC";
import * as voiceAction from "../../../redux/actions/_voices"
import * as actionTypes from "../../../redux/actionTypes";
import history from "../../../helpers/history";
import LangWrapper from "../LangWrapper";

const renderInput = (props) => {
  console.log("Props: ", props);
  return (
    <Input
      {...props.input}
      className="field-input"
      placeholder={props.placeholder}
      name={props.input.name}
      size="lg"
    />
  );
};

const required = value => (value || typeof value === 'number' ? undefined : 'Required')

let Example = (props) => {
  const {
    handleSubmit,
    valid,
    _ttswdl,
    loading,
    data,
    setMain,
    handleChange,
    field,
    text,
    resetFn,
    _uploadAudio,
    dispatch,
    _resetTTS,
    fData,
    fLoading,
    setOption,
    language,
    voiceData,
    _handleChange,
    _getVoices,
    setField,
    voiceLoading,
    langLoading
  } = props;
  const [scriptName, setScriptName] = useState("");
  const [selectedLang, setSelectedLang] = useState(null);
  const [voice, setVoice] = useState(null)
  const [gender, setGender] = useState('MALE')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  // const [text,setText]=useState('')
  // const [field,setField]=useState([])
  useEffect(() => {
    console.log("Props: ", props);
  }, []);

  const handleForm = (values) => {
    console.log("Forms: ", values);
    setFirstName(values?.first_name || "")
    setLastName(values?.last_name || "")
    let data = {
      // first_name: values?.first_name || "",
      // last_name: values?.last_name || "",
      ...values,
      text: text,
      voice_name: voice.original_name
    };
    _ttswdl(data);
  };

  const onSubmit = () => {
    const data = {
      name: scriptName,
      text_message: text,
      lang_code: selectedLang.code,
      lang_name: selectedLang.label,
      audio_type: "Text Template",
      audio_file: null,
      length_in_seconds: null,
      voice_name: voice.original_name
    };
    console.log("Data to be send: ", data);
    _uploadAudio(data, 3);
  };

  useEffect(() => {
    console.log("Text", text);
  }, data);

  const resetData = () => {
    setScriptName("");
    setSelectedLang(null);
    setVoice(null)
    setGender('MALE')
    fData == null && resetFn();
    setField([])
    _resetTTS();
    dispatch(reset("expample-form"));
  };

  useEffect(() => {
    if (language !== null && voice !== null && data !== null && fData == null) {
      let data = {
        voice,
        selectedLang,
        gender,
        firstName,
        lastName,
        script: text,
        option: 1,
        ttswdl: true
      }
      _handleChange(data)
    }
  }, [selectedLang, gender])

  useEffect(() => {
    if (language !== null && voice !== null && data !== null && fData == null) {
      let data = {
        voice,
        selectedLang,
        gender,
        script: text,
        option: 2,
        ttswdl: true,
        firstName,
        lastName,
      }
      _handleChange(data)
    }
  }, [voice])

  useEffect(() => {
    if (data == null && selectedLang !== null && fData == null) {
      let datas = {
        language_code: selectedLang.code,
        gender: gender
      }
      _getVoices(datas)
    }
  }, [selectedLang, gender])


  return (
    <div className="example-page">
      <div className="heading-container">
        <h3>Text to Speech with Data Link</h3>
        <GiSettingsKnobs
          size={25}
          className="change-option"
          onClick={() => setOption(0)}
        />
      </div>
      <div className="example-box">
        {fData == null && <>
          <div>

            <div className="input-field">
              <label>Select your Language</label>
              <SelectPicker
                data={language}
                placeholder="Select Language"
                onSelect={(a, b, c) => setSelectedLang(b)}
                block
                cleanable={false}
                renderMenu={menu => {
                  if (langLoading) {
                    return (
                      <p style={{ padding: 4, color: '#f35d5e', textAlign: 'center' }}>
                        <Icon icon="spinner" spin size="2x" />
                      </p>
                    )
                  }
                  return menu
                }}
              />
            </div>

            <div className="input-field">
              <label>Select your Gender</label>
              <div className="gender-div" >
                <motion.div className={gender == 'MALE' ? "gender-box selected" : "gender-box"} onClick={() => setGender('MALE')}
                  initial={{ x: -40 }}
                  animate={{ x: 0 }}
                  transition={{ ease: "easeOut" }}
                >
                  <img src={photos.man} />
                  <div>
                    <h5>Gender</h5>
                    <h3>MALE</h3>
                  </div>

                </motion.div>
                <motion.div className={gender == 'FEMALE' ? "gender-box selected" : "gender-box"} onClick={() => setGender('FEMALE')}
                  initial={{ x: 40 }}
                  animate={{ x: 0 }}
                  transition={{ ease: "easeOut" }}
                >
                  <img src={photos.woman} />
                  <div>
                    <h5>Gender</h5>
                    <h3>Female</h3>
                  </div>
                </motion.div>
              </div>
            </div>
            <div className="input-field" >
              <label>Select your preferred Voice</label>
              <SelectPicker
                data={voiceData?.voices || []}
                labelKey="replaced_name"
                valueKey="original_name"
                cleanable={false}
                searchable={false}
                onSelect={(a, b, c) => setVoice(b)}
                renderMenu={menu => {
                  if (voiceLoading) {
                    return (
                      <p style={{ padding: 4, color: '#f35d5e', textAlign: 'center' }}>
                        <Icon icon="spinner" spin size="2x" />
                      </p>
                    )
                  }
                  return menu
                }}
              />
            </div>
            <div className="input-field">
              <label>Enter Your Script</label>
              <div
                itemType="text"
                className="text-input"
                contentEditable={data == null}
                onKeyPress={(e) => handleChange(e)}
              ></div>
              {/* {data !== null && < div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row" }} className="pt-2" >
                <Button appearance="ghost"
                  onClick={handleSubmit(handleForm)}
                  loading={loading}
                  disabled={field.length < 1 || !valid || voice == null}
                >Convert to Audio</Button>
              </div>} */}
            </div>

            {/* <p className="type-writer" >You can type your script in the TextBox which will be shown to you in demo.
            for Eg: <p>"My first name is <span>{"{first_name}"}</span> and last name is <span>{"{last_name}"}</span>"</p>
                </p> */}

            <div className="pt-3">
              <form className="field-name-box">
                {data == null &&
                  field.length > 0 &&
                  field.map((item, index) => {
                    if (item !== null && item !== undefined)
                      return (
                        <motion.div
                          initial={{ x: 60, opacity: 0 }}
                          animate={{ x: 0, opacity: 1 }}
                        >
                          <Field
                            component={renderInput}
                            name={item}
                            placeholder={item}
                            validate={required}
                          />
                          <p>{`Field Name: ${item}`}</p>
                        </motion.div>
                      );
                  })}
              </form>
            </div>
          </div>
          <div className="audio-player-box pt-3 pb-3">
            {data != null &&
              <AudioPlayer src={data.url} autoPlay={false} autoPlayAfterSrcChange={false} />}
          </div>
          {data !== null && (
            <div className="input-field">
              <label>Save As (Script Name)</label>
              <Input
                placeholder="Script Name"
                onChange={(v) => setScriptName(v)}
                value={scriptName}
              />
            </div>
          )}
        </>}
        {data == null ? (
          <Button
            appearance="ghost"
            disabled={field.length < 1 || !valid || voice == null}
            size="lg"
            onClick={handleSubmit(handleForm)}
            loading={loading}
          >
            Give me Demo
          </Button>
        ) : (
            <Button appearance="ghost" onClick={resetData} size="lg">
              Reset
            </Button>
          )}
        <div className="pt-3">
          {fData == null ? (
            <Button
              appearance="primary"
              size="lg"
              onClick={onSubmit}
              block
              loading={fLoading}
              disabled={selectedLang == null || scriptName == ""}
            >
              Upload
            </Button>
          ) : (
              <Button
                appearance="primary"
                color="green"
                size="lg"
                onClick={() => history.push("/flow-chart")}
                block
              >
                Back to the Flow
              </Button>
            )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.TextToSpeechReducer.loading,
    data: state.TextToSpeechReducer.data,
    fData: state.AudioReducer.data,
    fLoading: state.AudioReducer.loading,
    voiceData: state.VoicesReducer.data,
    voiceLoading: state.VoicesReducer.loading,
    langLoading: state.LanguageReducer.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    _ttswdl: (data) => dispatch(action.ttswdl(data)),
    _uploadAudio: (data, option) => dispatch(action.uploadAudio(data, option)),
    _resetTTS: () => dispatch({ type: actionTypes.RESET_TTS_REDUCER }),
    _handleChange: (data) => dispatch(changeAction.handleChange(data)),
    _getVoices: (data) => dispatch(voiceAction.getVoices(data))

  };
};

Example = connect(mapStateToProps, mapDispatchToProps)(Example);

export default reduxForm({ form: "expample-form", })(LangWrapper(TTSWrapper(Example)));
