import React, { useEffect, useState } from 'react'
import { isoLangs } from "../AudioScript/AudioScriptAdd/LanguageCode"
import * as action from "../../redux/actions/_language"
import * as voiceAction from "../../redux/actions/_voices"
import { connect } from "react-redux"

const LangWrapper = WrapperComponent => {

    const UpdatedComponent = (props) => {
        const [language, setLanguage] = useState([])
        const { data, _getLanguage, _getVoices } = props
        const renderLanguage = () => {
            console.log("Lensldk: ",)
            let datas = []
            for (let i in data) {
                let lang = {
                    value: i,
                    label: data[i].name,
                    code: data[i].short_code
                }
                datas.push(lang)
            }
            setLanguage(datas)
        }

        useEffect(() => {
            console.log("Props: ", props)
            if (data !== null) {
                renderLanguage()
            }
        }, [data])
        useEffect(() => {
            _getLanguage()
            let defaultData = {
                language_code: "en-IN",
                gender: "MALE"
            }
            _getVoices(defaultData)
        }, [])
        return (
            <WrapperComponent
                language={language}
                {...props}
            />
        )
    }

    const mapStateToProps = (state) => {
        return {
            data: state.LanguageReducer.data
        }
    }

    const mapDispatchToProps = (dispatch) => {
        return {
            _getLanguage: () => dispatch(action.getLanguage()),
            _getVoices: (data) => dispatch(voiceAction.getVoices(data))

        }
    }
    return connect(mapStateToProps, mapDispatchToProps)(UpdatedComponent)
}


export default LangWrapper