/** @format */

import * as actionTypes from "../actionTypes";
import { authApi } from "../actions/api";
import * as API from "../../ApiUrl";
import {
  updateFlowChartSuccess,
  updateFlowChartFail,
  updateNodeReduxSuccess,
  nodeStart,
  nodeOff,
  flowLoaderStart,
  flowLoaderOff,
  resetFlowRedux,
} from "../actions/flowchart";
import { store } from "../store/index";

export const updateNodeRedux = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.UPDATE_NODE_REDUX) {
    const state = store.getState();
    let data = action.payload.data;
    let callback = action.payload.callback;
    let cbParam = action.payload.cbParam;
    console.log("updateNodeRedux:", data);
    dispatch(updateNodeReduxSuccess(data));
    dispatch({ type: actionTypes.CREATE_NODE,payload:{callback,cbParam} });
  }
};

// fetch flow from server
export const fetchFlowDetail = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.FETCH_FLOW_DETAIL) {
    let key = JSON.parse(localStorage.getItem("campDetail"));
    let token = localStorage.getItem("token");
    console.log("Token: ", token);
    console.log("Key:  ", key);
    let param1 = {};
    if (key) {
      param1.campaign_id = key.key;
    }

    console.log("Body Param: ", param1);
    let onSuccess = actionTypes.FETCH_FLOW_DETAIL_SUCCESS;
    let onError = actionTypes.FETCH_FLOW_DETAIL_FAIL;
    dispatch(
      authApi("GET", API.ADD_FLOW, param1, onSuccess, onError, null, token)
    );
    dispatch(nodeStart());
  }
};

//update fetched flow in redux
export const fetchFlowDetailSuccess = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.FETCH_FLOW_DETAIL_SUCCESS) {
    let data = action.payload;
    console.log("fetchFlowDetailSuccess: ", data);
    // dispatch(updateFlowChartSuccess(data.msg));
    dispatch(updateNodeReduxSuccess(data.msg));
    dispatch(nodeOff());
  }
};

export const fetchFlowDetailFail = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.FETCH_FLOW_DETAIL_FAIL) {
    dispatch(nodeOff());
  }
};

export const createNode = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.CREATE_NODE) {
    let key = JSON.parse(localStorage.getItem("campDetail"));
    const state = store.getState();
    const flow = state.NodeReducer.flow;
    let callback=action.payload.callback;
    let cbParam=action.payload.cbParam;
    // let node=action.payload.data
    let data = {
      campaign_id: key.key,
      flowdata: { ...flow },
    };
    console.log("createNode", data);

    dispatch(flowLoaderStart());
    let onSuccess = actionTypes.CREATE_NODE_SUCCESS;
    let onError = actionTypes.CREATE_NODE_FAIL;
    dispatch(
      authApi("POST", API.ADD_FLOW, null, onSuccess, onError, data, null,callback,cbParam)
    );
  }
};

export const createNodeSuccess = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.CREATE_NODE_SUCCESS) {

    let data = action.payload;
    let callback=action.callback;
    let cbParam=action.cbParam
    console.log("createNodeSuccess:", cbParam);
    if(cbParam=="reload"){
      window.location.reload();
    }else{
      dispatch({type:actionTypes.FETCH_FLOW_DETAIL})
    }
    // setTimeout(() => {
     
    // }, 1500);
    dispatch(flowLoaderOff());

    dispatch(callback(cbParam))
  }
};

export const createNoteFail = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.CREATE_NODE_FAIL) {
    dispatch(flowLoaderOff());
  }
};

export const deleteNode = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.DELETE_NODE) {
    let selectedNode = action.payload.data;
    const state = store.getState();
    let flow = state.NodeReducer.flow;
    console.log("Seleted node to Delete: ", selectedNode);
    // let nodes=Object.entries(flow.nodes)
    console.log("Flow Object: ", flow);
    console.log(flow.nodes.hasOwnProperty(selectedNode.id));
    if (flow.nodes.hasOwnProperty(selectedNode.id)) {
      // delete flow.nodes[selectedNode.id]
    }
    console.log("Node after delete: ", flow.nodes);
  }
};

export const resetFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === actionTypes.RESET_FLOW) {
    dispatch(resetFlowRedux());
  }
};

export const flowMdl = [
  fetchFlowDetail,
  fetchFlowDetailSuccess,
  fetchFlowDetailFail,
  updateNodeRedux,
  createNode,
  createNodeSuccess,
  createNoteFail,
  deleteNode,
  resetFlow,
];
