import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Badge, Placeholder } from "rsuite";
import Stats from "./Stats";
import * as action from "../../redux/actions/result";
import history from "../../helpers/history";
import ScriptTable from "./ScriptTable";
import { Helmet } from "react-helmet";

const { Paragraph } = Placeholder;
function Result(props) {
  const { _fetchResult, data, _resetResult, overallData, loading } = props;
  const [overallresultedit, setoverallresultedit] = useState({});
  console.log("overalldata", overallData);
  // const { campaignInfo } = props.history.location.state
  useEffect(() => {
    _resetResult();
    // console.log("History: ", history);
    // console.log("History Props: ", props);
    // _fetchResult(props.match.params.campaign_id)
  }, []);

  // useEffect(() => {
  //   // console.log("Data Receved: ", data);
  // }, [data]);

  useEffect(() => {
    // console.log("rajeev", overallresultedit);
    _fetchResult({ id: props.match.params.campaign_id });
  }, [
    props.match.params.campaign_id,
    // overallresultedit.limit,
    // overallresultedit.offset,
    // overallresultedit.searchInput,
  ]);

  const editoveralltable = (p) => {
    // console.log(p);
    setoverallresultedit(p);
    _fetchResult({ ...p, id: props.match.params.campaign_id });

    // _fetchResult({ ...overallresultedit, id: props.match.params.campaign_id });
  };

  return (
    <div className="result-section">
      <Helmet>
        <title>Result</title>
      </Helmet>
      <div className="head-box">
        <h1>{history.location.state?.campaignInfo.name || "Result"}</h1>
        <Badge
          content={
            <h4 style={{ margin: 0, color: "#fff" }}>
              {history.location.state?.campaignInfo.status[0] || ""}
            </h4>
          }
          style={{
            backgroundColor:
              history.location.state?.campaignInfo.status[0] == "Live"
                ? "#179225"
                : history.location.state?.campaignInfo.status[0] == "Paused" ||
                  history.location.state?.campaignInfo.status[0] == "Scheduled"
                ? "#FFCD37"
                : history.location.state?.campaignInfo.status[0] == "Cancelled"
                ? "#FB4E3E"
                : "#CED2CF",
          }}
        />
      </div>
      <Stats data={data != null ? data[0].call_details : null} />
      {data != null ? (
        <ScriptTable
          data={data[1].scripts}
          // overallData={overallData}
          campaignId={props.match.params.campaign_id}
          editoveralltable={(p) => editoveralltable(p)}
          // loading={loading}
        />
      ) : (
        <Placeholder.Graph height={600} active />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    data: state.ResultReducer.data,
    overallData: state.ResultReducer.overallData,
    loading: state.ResultReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    _fetchResult: (id) => dispatch(action.handleFetchResult(id)),
    _resetResult: () => dispatch(action.resetResult()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Result);
