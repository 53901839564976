import * as actionTypes from "../actionTypes"



export const handleFetchResult = (data) => ({
    type: actionTypes.FETCH_RESULT,
    payload: { data }
})


export const handleSuccess = (data) => ({
    type: actionTypes.UPDATE_FETCH_RESULT_SUCCESS,
    payload: { data }
})



export const handleFail = (data) => ({
    type: actionTypes.UPDATE_FETCH_RESULT_FAIL,
    payload: { data }
})

export const loaderStart = () => ({
    type: actionTypes.FETCH_RESULT_START
})


export const loaderOff = () => ({
    type: actionTypes.FETCH_RESULT_OFF
})

export const resetResult = () => ({
    type: actionTypes.RESET_RESULT_REDUX
})


export const handleFetchOverallResult = (data) => ({
    type: actionTypes.FETCH_OVERALL_RESULT,
    payload: { data }
})

export const updateOverallSuccess = (data) => ({
    type: actionTypes.UPDATE_OVERALL_RESULT_SUCCESS,
    payload: { data }
})

export const updateOverallFail = (data) => ({
    type: actionTypes.UPDATE_OVERALL_RESULT_FAIL,
    payload: { data }
})

export const overallLoaderStart = () => ({
    type: actionTypes.FETCH_OVERALL_RESULT_START
})


export const overallLoaderOff = () => ({
    type: actionTypes.FETCH_OVERALL_RESULT_FINISH
})