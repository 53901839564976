/** @format */

import React, { useState, useEffect, useRef, useReducer } from "react";
import {
  VscDebugStart,
  FaRegFileAudio,
  RiLinksLine,
  FiBell,
  FiSliders,
  AiOutlineStop,
  BiTrash,
  VscSave,
} from "react-icons/all";
import { FlowChartWithState, actions } from "@mrblenny/react-flow-chart";
import { images } from "../../constant";
import styled from "styled-components";
import {
  DateRangePicker,
  DatePicker,
  Input,
  Button,
  IconButton,
  Icon,
  Loader,
} from "rsuite";
import { Start, Audio, Rule } from "./Activity-Detail";
import { connect } from "react-redux";
import * as action from "../../redux/actions/flowchart";
import * as audio from "../../redux/actions/audio";
import * as rule from "../../redux/actions/rule";
import { motion } from "framer-motion";
import { cloneDeep, mapValues } from "lodash";
const REACT_FLOW_CHART = "react-flow-chart";
const { photos } = images;
const Outer = styled.div`
  padding: 0px 30px;
  padding-top: 5px;
  font-size: 14px;
  background: white;
  cursor: move;
`;

function FlowChart(props) {


  const {
    _fetchFlowDetail,
    _updateNodeRedux,
    _createNode,
    flow,
    reduxNode,
    flowLoader,
    nodeLoader,
    _deleteNode,
    _fetchAudioFlow,
    _fetchRules,
  } = props;
  const [oldState, setOldState] = useState(true);
  const [chart, setChart] = useState(null);
  const stateActions = mapValues(actions, (func) => (...args) => {
    setChart(func(...args));
    console.log('Chart Argss........',...args)
  });
  const [selectedNode, setNode] = useState(0);
  const [selectedType, setSelectedType] = useState(null);


  useEffect(() => {
    _fetchFlowDetail();
    console.log("Flow Chart State: ", chart);
    console.log("Flow Chart Props: ", flow);
  }, []);

  useEffect(() => {
    console.log("Chart is updated: ", chart);
  }, [chart]);

  function isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  const DragItem = ({ type, ports, properties, value, icon }) => {
    return (
      <Outer
        className="drag-item"
        draggable={true}
        onDragStart={(event) => {
          event.dataTransfer.setData(
            REACT_FLOW_CHART,
            JSON.stringify({ type, ports, properties, value }, icon)
          );
        }}
      >
        {icon}
        <h4>{type}</h4>
      </Outer>
    );
  };

  const sendNode = () => {

    console.log("Chart Nodes: ", chart.nodes);

    _updateNodeRedux(chart);
  };

  useEffect(() => {
      if (flow !== null) {
        setChart(flow);
        console.log("Chart Flow: ", flow);
        setTimeout(() => {
          console.log("Chart Flow asdfa", chart);
        }, 1500);
      }
    }, [flow, nodeLoader] );

  const selectedNodes = (node, id) => {
    console.log("Selected Node: ", node);
    console.log("Id: ", id);
    setNode(id);
    setSelectedType(node);
    if (node.type === "Audio Play") {
      _fetchAudioFlow(node.id);
    }
    if (node.type === "Rule") {
      _fetchRules(node.id);
    }
  };

  const deleteNode = (node) => {
    _deleteNode(node);
  };

  const NodeInnerCustom = ({ node, config }) => {
 
    if (node.type === "Start") {
      return (
        <div
          className="node-item-box start-box"
          onClick={() => selectedNodes(node, 1)}
        >
          <img src={photos.start} />
          <h4>Start</h4>
          <BiTrash className="trash-btn" onClick={() => deleteNode(node)} />
        </div>
      );
    }
    if (node.type === "Audio Play") {
      return (
        <div
          className="node-item-box audio-box "
          onClick={() => selectedNodes(node, 2)}
        >
          <img src={photos.volume} />
          <h4>{node.type}</h4>
          <BiTrash className="trash-btn" onClick={() => deleteNode(node)} />
        </div>
      );
    }

    if (node.type === "Rule") {
      return (
        <div
          className="node-item-box rule-box "
          onClick={() => selectedNodes(node, 3)}
        >
          <img src={photos.rule} />
          <h4>{node.type}</h4>
          <BiTrash className="trash-btn" onClick={() => deleteNode(node)} />
        </div>
      );
    }

    if (node.type === "System Action") {
      return (
        <div
          className="node-item-box tts-box "
          onClick={() => selectedNodes(node, 4)}
        >
          <img src={photos.schoolbell} />
          <h4>{node.type}</h4>
          <BiTrash className="trash-btn" onClick={() => deleteNode(node)} />
        </div>
      );
    }
    if (node.type === "End") {
      return (
        <div
          className="node-item-box end-box "
          onClick={() => selectedNodes(node, 5)}
        >
          <img src={photos.hungup} />
          <h4>{node.type}</h4>
          <div className="trash-btn" onClick={() => deleteNode(node)}>
            <BiTrash />
          </div>
        </div>
      );
    } else {
      console.log("Rule Child Node: ",node)
      return (
        <div
          className="node-item-box tts-box "
          onClick={() => selectedNodes(node, 5)}
        >
          <img src={photos.speak} />
          <h4>{node.type!==""?node.type:node.properties.rule_child_name }</h4>
          <div className="trash-btn">
            <BiTrash className="trash-btn" onClick={() => deleteNode(node)} />
          </div>
        </div>
      );
    }
  };

  const activityDetail = () => {
    console.log("Slected Node: ", selectedNode);
    switch (selectedNode) {
      case 1:
        return <Start prevChart={chart} updateNodeRedux={_updateNodeRedux} flowLoader={flowLoader}   />;
      case 2:
        return (
          <Audio selectedNode={selectedType} prevChart={chart} updateNodeRedux={_updateNodeRedux} flowLoader={flowLoader} />
        );
      case 3:
        return <Rule selectedNode={selectedType} _fetchRules={_fetchRules} chart={chart} />;
    }
  };

  return (
    <div className="flow-chart-container">
      <div className="row">
        <div className="col-3 ">
          <div className="action-box">
            <div className="action-heading-box">
              <h5>Action Directory</h5>
            </div>
            <p>Drag and Drop to Create your own Flow.</p>
            <div className="drag-container">
              <div className="drag-item-box">
                <DragItem
                  type="Start"
                  ports={{
                    port2: {
                      id: "port2",
                      type: "bottom",
                      properties: { custom: "property" },
                    },
                  }}
                  icon={<img src={photos.start} />
                  }
                  properties={{ custom: "property", node_type: "Start" }}
                />
              </div>

              <div className="drag-item-box">
                <DragItem
                  type="Audio Play"
                  ports={{
                    port1: {
                      id: "port1",
                      type: "top",
                      properties: { custom: "property" },
                    },
                    port2: {
                      id: "port2",
                      type: "bottom",
                      properties: { custom: "property" },
                    },
                  }}
                  icon={<img src={photos.volume} />
                  }
                  properties={{ custom: "property", node_type: "Audio" }}
                />
              </div>
              <div className="drag-item-box">
                <DragItem
                  type="Rule"
                  ports={{
                    port1: {
                      id: "port1",
                      type: "top",
                      properties: { custom: "property" },
                    },
                    port2: {
                      id: "port2",
                      type: "bottom",
                      properties: { custom: "property" },
                    },
                  }}
                  icon={<img src={photos.rule} />}
                  properties={{ custom: "property", node_type: "Rule" }}
                />
              </div>

              <div className="drag-item-box">
                <DragItem
                  type="System Action"
                  ports={{
                    port1: {
                      id: "port1",
                      type: "left",
                      properties: { custom: "property" },
                    },
                    port2: {
                      id: "port2",
                      type: "right",
                      properties: { custom: "property" },
                    },
                  }}
                  icon={<img src={photos.schoolbell} />}
                  properties={{
                    custom: "property",
                    node_type: "System Action",
                  }}
                />
              </div>

              <div className="drag-item-box">
                <DragItem
                  type="End"
                  ports={{
                    port1: {
                      id: "port1",
                      type: "top",
                      properties: { custom: "property" },
                    },
                  }}
                  icon={<img src={photos.hungup} />
                  }
                  properties={{ custom: "property", node_type: "End" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={selectedNode === 0 ? "col-9" : "col-6"}>
          <div className="process-box">
            <div className="process-heading-box">
              <h5>Process Flow</h5>
            </div>
            {chart != null && !nodeLoader && (
              <FlowChartWithState
                initialValue={chart}
                callbacks={stateActions}
                style={{ backgroundColor: "#FFF" }}
                config={{
                  snapToGrid: true,
                }}
                Components={{
                  NodeInner: NodeInnerCustom,
                }}
              />
            )}
         
            {nodeLoader && (
              <Loader
                speed="fast"
                size="lg"
                className="node-loader"
                content="Processing Your Flow"
              />
            )}
            <IconButton
              icon={<VscSave />}
              appearance="primary"
              loading={flowLoader}
              onClick={() => sendNode()}
              size="lg"
              className="draft"
              circle
            />
          </div>
        </div>
        {selectedNode !== 0 && (
          <motion.div
            className="col-3"
            initial={{ x: 50 }}
            animate={{ x: 0 }}
            transition={{ ease: "easeOut", duration: 0.2 }}
          >
            <div className="activity-box">
              <div className="activity-heading-box">
                <h5>Activity Detail</h5>
              </div>
              {activityDetail()}
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    flow: state.NodeReducer.flow,
    reduxNode: state.FlowChartReducer.data,
    flowLoader: state.FlowChartReducer.loading,
    nodeLoader: state.NodeReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    _fetchFlowDetail: () => dispatch(action.fetchFlowChart()),
    _updateNodeRedux: (data,callback,cbParam) => dispatch(action.updateNodeRedux(data,callback,cbParam)),
    _createNode: (data) => dispatch(action.createNode(data)),
    _deleteNode: (node) => dispatch(action.deleteNode(node)),
    _fetchAudioFlow: (data) => dispatch(audio.fetchAudioFlow(data)),
    _fetchRules: (data) => dispatch(rule.fetchRules(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlowChart);
