import * as actionTypes from "../actionTypes"



export const fetchRules = (data) => ({
    type: actionTypes.FETCH_RULE,
    payload: { data }
})

export const loaderStart = () => ({
    type: actionTypes.FETCH_RULE_START
})


export const loaderOff = () => ({
    type: actionTypes.FETCH_RULE_OFF
})


export const editRule = (data) => ({
    type: actionTypes.EDIT_RULE,
    payload: { data }
})

export const updateEditRuleSuccess = (data) => ({
    type: actionTypes.UPDATE_EDIT_RULE_SUCCESS,
    payload: { data }
})

export const updateEditRuleFail = (data) => ({
    type: actionTypes.UPDATE_EDIT_RULE_FAIL,
    payload: { data }
})

export const editRuleStart = () => ({
    type: actionTypes.EDIT_RULE_START,
})

export const editRuleFinish = () => ({
    type: actionTypes.EDIT_RULE_FINISH,
})