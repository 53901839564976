/** @format */

export const LOGIN_API_URI = "/api/login/";
export const GET_OTP = "/api/campaigns/verify_phone_no/";
export const VERIFY_OTP = "/api/campaigns/verify_code/";
export const INSERT_SIM = "/api/campaigns/insert_sim/";
export const GET_SIM = "/api/campaigns/get_sim/";
export const GET_CAMPAIGN_LIST = "/api/campaigns/get_campaign_data/";
export const GET_CAMPAIGN_LIST2 = "/api/campaigns/campaign/";
export const UPDATE_SIM_STATUS = "/api/campaigns/update_sim_status/";
export const GET_SCRIPT = "/api/campaigns/script/";
export const INSERT_SCRIPT = "/api/insert_script/";
export const USER_PROFILE = "/api/user_profile/";
export const CREATE_CAMPAIGN_LINE = "/api/campaigns/insert_campaign_line/";
export const UPDATE_CAMPAIGN = "/api/campaigns/update_campaign_detail/";
export const FORMAT = "/api/file_format/";
export const GET_RESPONSE = "/api/campaigns/response_type/";
// export const TEXT_TO_SPEECH= BASE_URI+"text_to_speech/";
export const GET_CAMPAIGN_LINE = "/api/campaigns/campaign_line/";
export const CALL_RESPONSE = "/api/campaigns/call_responses/";

//@author Himanshu

export const ADD_FLOW = "/api/campaigns/add-flow/";
export const CREATE_NODE = "/api/flow/create-node/";
export const UPLOAD_AUDIO = "/api/campaigns/script/";
export const CAMPAIGN_DETAIL = "/api/flow/add-campaign-details/";
export const PATCH_CAMPAIGN = "/api/campaigns/campaign-details/";
export const ADD_SCRIPT = "/api/campaigns/add-script/";
export const TEXT_TO_SPEECH = "/api/campaigns/gtts/";
export const TTSWDL = "/api/campaigns/get-sample-audio/";
export const TIMEZONE = "/api/campaigns/timezone/";
export const FETCH_RULES = "/api/flow/get-rule-details/";
export const GET_RESULT = "/api/manage-calls/get-result/";
export const OVERALL_RESULT2 = "/api/manage-calls/overall-results-paginated/";
export const LANGUAGE_LIST = "/api/campaigns/get-languages/";
export const GET_VOICES = "/api/campaigns/get-voices/";
export const DUPLICATE_CAMPAIGN = "/api/campaigns/duplicate-campaign/";
export const EDIT_CAMPAIGN = "/api/campaigns/edit-campaign/";
export const OVERALL_RESULT = "/api/manage-calls/overall-results/";
export const GET_SIMCARDS = "/api/campaigns/sim_detail/";
