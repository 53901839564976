import React, { useEffect } from 'react'
import { photos } from "../../constant/images"
import { Placeholder } from "rsuite"
export default function Stats(props) {
    const { data } = props
    const { Paragraph } = Placeholder
    useEffect(() => {
        console.log("Stats: ", data)
    }, data)

    return (
        <>
            {data ? <div className="stats-section" >
                <div className="stat-box" >
                    <div className="stat-info total-calls" >
                        <p>{data.total_calls}</p>
                        <p>Calls</p>
                    </div>
                    <div className="stat-icon">
                        <img src={photos.phone1} />
                    </div>
                </div>

                <div className="stat-box" >
                    <div className="stat-info total-pickup" >
                        <p>{data.total_pickup}</p>
                        <p>Picked Up</p>
                    </div>
                    <div className="stat-icon">
                        <img src={photos.phone2} />
                    </div>
                </div>
                <div className="stat-box" >
                    <div className="stat-info total-blocked" >
                        <p>{data.no_pickup}</p>
                        <p>Not Picked Up</p>
                    </div>
                    <div className="stat-icon">
                        <img src={photos.blocked} />
                    </div>
                </div>

                <div className="stat-box" >
                    <div className="stat-info total-response" >
                        <p>{data.no_of_gave_response}</p>
                        <p>Response</p>
                    </div>
                    <div className="stat-icon">
                        <img src={photos.phone3} />
                    </div>
                </div>
            </div> :
                <div className="stats-section" >
                    <Placeholder.Graph active />
                    <Placeholder.Graph active />
                    <Placeholder.Graph active />
                    <Placeholder.Graph active />
                </div>
            }
        </>
    )
}
