/** @format */

import React, { useRef, createRef } from "react";
import {
  Table,
  Input,
  Tag,
  TimePicker,
  Tooltip,
  Popover as PopOver,
} from "antd";
import { Badge, Button as But, Icon, Modal as RModal } from "rsuite";
import { Link, Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { Button } from "@material-ui/core";
import * as API from "../../../../ApiUrl";
import { Modal } from "react-bootstrap";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import moment from "moment";
import { DatePicker, Select } from "antd";
import Loader from "react-loader-spinner";
import LoadingBar from "react-top-loading-bar";
import Moment from "moment";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Swal from "sweetalert2";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import history from "../../../../helpers/history";
import { TiFlowMerge, FcFlowChart } from "react-icons/all";
import * as action from "../../../../redux/actions/flowchart";
import * as status from "../../../../redux/actions/start";
import * as camps from "../../../../redux/actions/_campaign";
import { connect } from "react-redux";
import { photos } from "../../../../constant/images";
import { BsThreeDots } from "react-icons/all";
import {
  Whisper,
  Tooltip as ToolTip,
  ButtonGroup,
  IconButton,
  Input as TextField,
  Icon as IconBut,
  Dropdown,
  Popover,
} from "rsuite";

// const loadingRef = useRef(null)

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const { RangePicker } = DatePicker;
const customColors = ["#F35D5E"];
const { Picker } = TimePicker;
var showModl = false;
var showAudio = false;
var audio = true;
var camp;

class UsersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      campaignList: "",
      campaignList1: null,
      rowData: null,
      searchInput: "",
      showModal: false,
      audioShow: true,
      insertKeword: false,
      audioName: "",
      audioId: "",
      token: "",
      loading: false,
      campName: "",
      totalRecord: "",
      getSimData: "",
      startDate: "",
      endDat: "",
      campaignId: "",
      loadPercent: 0,
      tokenExp: false,
      rows: [
        {
          response: "",
          responseType: "",
        },
      ],
      audioUrl: "",
      startTime: "",
      endTime: "",
      responseCampaign: "",
      getResponseType: [],
      errorMsg: false,
      showAudioModel: false,
      campaignDetailView: false,
      campaignDeteil: "",
      spinRateValue: "",
      timeBetwwenCall: "",
      loadStatus: null,
      showDeletingModal: false,
      showDuplicateCampaignModal: false,
      duplicateCampaignName: "",
      duplicateCampaignId: 0,
      editingKey: "",
      showDeleteCampaignModal: false,
      //  table data
      pageNumber: 1,
      numofTotalData: "",
      statusfilter: "",
      next_table_start_from: "",
      table_row_limit: 5,
    };
    this.onRow = this.onRow.bind(this);
    this.statusRef = createRef();
    this.loadingRef = createRef();
    this.triggerRef = createRef();
    this.triggerRef.current = [];
    this.actionRef = createRef();
    this.actionRef.current = [];
  }
  closePop = () => this.triggerRef.current.close();

  async componentDidMount() {
    const token = await localStorage.getItem("token");
    console.log("token", token);
    this.setState({ token: token });

    this.getCampaignList();
    // this.getCampaignList2();
    this.userProfile();
    this.getScript();
    this.getResponse();
    this.setState({ campaignDetailView: false });
  }

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  showStatus = (e) => {
    e.persist();
    console.log("Event: ", e);
    this.statusRef.current.open();
  };

  // state = {
  //   filteredInfo: null,
  //   sortedInfo: null,
  //   data: originalData,
  //   searchInput: ""
  // };
  onRow(record) {
    // const expandedRowKeys = this.state.expandedRowKeys;
  }
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      this.setState({ errorMsg: false });
      return;
    }
  };
  handleChangeRow = (idx) => (e) => {
    const { name, value } = e.target;

    const rows = [...this.state.rows];
    rows[idx][name] = value;
    this.setState({
      rows,
    });
  };
  handleAddRow = () => {
    const item = {
      response: "",
      responseType: "",
    };
    this.setState({
      rows: [...this.state.rows, item],
    });
  };
  onChangeStart = (c) => {
    let demoTime = Moment(c).format("HH:mm:ss");
    this.setState({
      startTime: demoTime,
    });
  };
  onChangeEnd = (c) => {
    let demoTime = Moment(c).format("HH:mm:ss");

    this.setState({
      endTime: demoTime,
    });
  };
  onChangeDate = (value, dateString) => {
    this.setState({
      endDat: value[1],
    });
    this.setState({
      startDate: value[0],
    });
  };
  update = (r) => {
    this.showLoader();
    let data = {
      campaign: this.state.campaignId,
      start_date: this.state.startDate,
      end_date: this.state.endDat,
      script: this.state.audioId,
      call_start_time: this.state.startTime,
      call_end_time: this.state.endTime,
      response_type: this.state.responseCampaign,
      keyword: this.state.rows,
      spin_rate: this.state.spinRateValue,
      time_between_calls: this.state.timeBetwwenCall,
    };
    const config = {
      headers: { Authorization: `Bearer ${this.state.token}` },
    };
    axios
      .post(API.UPDATE_CAMPAIGN, data, config)
      .then((res) => {
        this.hideLoader();
        this.getCampaignList();
        Swal.fire({
          icon: "success",
          title: "Script Added Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        // console.log("error", err);
        this.hideLoader();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something Went Wrong",
        });
      });
  };
  getResponse = () => {
    const config = {
      headers: { Authorization: `Bearer ${this.state.token}` },
    };
    axios
      .get(API.GET_RESPONSE, config)
      .then((res) => {
        // console.log("responsetype", res.data);
        this.setState({ getResponseType: res.data });
      })
      .catch((err) => {
        // console.log("error", err);
      });
  };
  getScript = () => {
    const config = {
      headers: { Authorization: `Bearer ${this.state.token}` },
    };
    axios
      .get(API.GET_SCRIPT, config)
      .then((res) => {
        // console.log("scrript", res);

        for (let resp of res.data) {
          resp.action = ["select"];
          resp.created_date = Moment(resp.created_date).format("DD-MM-YYYY");
        }
        this.setState({ getSimData: res.data });
      })
      .catch((err) => {
        // console.log("error", err);
      });
  };


  ///@rajeev ranjan singh

  getCampaignList = async () => {
    console.log("getcompain");

    const {
      pageNumber = 1,
      searchInput,
      statusfilter,
      next_table_start_from,
      table_row_limit,
    } = this.state;

    this.showLoader();
    const config = {
      headers: { Authorization: `Bearer ${this.state.token}` },
      onDownloadProgress: (e) => {
        // console.log("Page Loading: ", e);
        const progress = e.lengthComputable ? (e.loaded / e.total) * 100 : 0;
        let percent = parseInt(progress).toFixed(2);
        this.setState({ loadPercent: parseInt(percent) });
      },
    };

    let query = "?";
    // if (pageNumber) query += "p=" + pageNumber;
    if (table_row_limit) query += "limit=" + table_row_limit;
    if (searchInput) query += "&search=" + searchInput;
    if (statusfilter) query += "&status=" + statusfilter;
    if (next_table_start_from) query += "&offset=" + next_table_start_from;

    try {
      const { data, status } = await axios.get(
        `${API.GET_CAMPAIGN_LIST2}${query}`,
        config
      );
      console.log("rajeev", data, status);

      if (status == 401) {
        localStorage.clear();
        window.location = "/sign-in";
      }
      const { results } = data;
      let result = results.map((p) => ({
        ...p,
        startDate: p.call_start_date,
        endDate: p.call_end_date,
        totalRecords: p.total_records,
        give_response: p.gave_response,
        status: [p.status],
        is_edited: p.is_edited,
        key: p.id,
      }));

      for (let resp of result) {
        if (resp.call_start_date) {
          resp.startDate = Moment(resp.startDate).format("DD-MM-YYYY");
        }
        if (resp.endDate) {
          resp.endDate = Moment(resp.endDate).format("DD-MM-YYYY");
        }
        // console.log("campaignlistdata", resp);
        resp.script = "Add Flow";

        // if(resp.script === 'None') {
        //   resp.script = 'Add Flow'
        //   }
      }
      this.setState({
        campaignList: result,
        campaignList1: result,
        numofTotalData: data.count,
      });
      //     console.log("c ------",this.state.campaignList);
      this.triggerRef.current = new Array(data.count);
      this.actionRef.current = new Array(data.count);
    } catch (error) {
      console.log("err--------", error.response);
      if (error.response.status == 401) {
        localStorage.clear();
        window.location = "/sign-in";
      }
    }
    //     this.hideLoader();
    //   })
    //   .catch((err) => {
    this.hideLoader();
    //     console.log("error", err);
    //   });
  };

  userProfile = () => {
    fetch(`${API.USER_PROFILE}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
        "Content-type": "application/json;",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.code == "token_not_valid") {
        }
        localStorage.setItem("user", JSON.stringify(res));
      });
  };
  goToDetail = (e) => {
    // console.log("detailclickevent", e);
  };
  onClickRow = (r) => {
    return {
      onClick: () => {
        this.setState({
          rowData: r,
        });
        // return <Redirect to='/dashboard' />
        // console.log("row value===", r);
        this.setState({ campaignDeteil: r });
        camp = r;
        localStorage.setItem("campDetail", JSON.stringify(r));
        // console.log("cam value===", this.state.campaignDeteil, camp);
        if (showModl === true) {
          this.setState({
            showModal: true,
            campName: r.name,
            totalRecord: r.totalRecords,
            campaignId: r.key,
            campaignDeteil: r,
          });
        } else if (showAudio === true) {
          this.setState({ showAudioModel: true, audioUrl: r.script_url });
        } else if (showModl === false || showAudio === false) {
          this.setState({ campaignDetailView: true, campaignDeteil: r });
          // console.log("redirect", this.state.campaignDeteil);
        }
      },
    };
  };
  onClick = (r) => {
    return {
      onClick: () => {
        this.setState({
          audioName: r.name,
          audioId: r.id,
        });
        this.audio();
      },
    };
  };
  show(r) {
    if (r === "Add Script") {
      showModl = true;
    } else {
      showAudio = true;
    }
    // this.setState({showModal:true})
  }
  showModal = (e) => {
    this.setState({ showModal: true });
  };

  hideModal = (e) => {
    this.setState({ showModal: false });
    showModl = false;
  };
  hideModalAudio = (e) => {
    // console.log('audio', this.state.showAudioModel);
    this.setState({ showAudioModel: false });
    showAudio = false;
  };
  timeBtwnCall = (e) => {
    // console.log("time", e.target.value);
    this.setState({ timeBetwwenCall: e.target.value });
  };
  spinRate = (e) => {
    console.log("spin", e.target.value);
    this.setState({ spinRateValue: e.target.value });
  };
  confirmUpdate = (e) => {
    if (
      this.state.endDat == "" ||
      this.state.startDate == "" ||
      this.state.audioId == "" ||
      this.state.startTime == "" ||
      this.state.endTime == "" ||
      this.state.responseCampaign == "" ||
      this.state.timeBetwwenCall == "" ||
      this.state.spinRateValue == ""
    ) {
      this.setState({ errorMsg: true });
    } else {
      this.setState({ errorMsg: false });
      this.setState({ showModal: false });
      showModl = false;
      this.update();
    }
  };
  audio = (e) => {
    if (this.state.audioShow === true) {
      this.setState({ audioShow: false });
    } else if (this.state.audioShow === false) {
      this.setState({ audioShow: true });
    }
  };
  handleChange = (pagination, filters, sorter, extra) => {
    console.log(
      "pagination====",
      filters,
      filters.status?.join(","),
      pagination
    );
    const { current } = pagination;

    this.setState({
      // pageNumber: current,
      next_table_start_from: (current - 1) * pagination.pageSize,
      table_row_limit: pagination.pageSize,
    });
    if (filters.status) {
      this.setState({
        statusfilter: filters.status?.join(","),
      });
    } else {
      this.setState({
        statusfilter: "",
      });
    }
    // this.getCampaignList2();
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  handleChangeSearch = (event) => {
    let { searchInput, campaignList, campaignList1 } = this.state;
    this.setState({
      searchInput: event.target.value,
      next_table_start_from: 0,
    });
   
  };
  responseHandleChange = (value) => {
    console.log(value);

    this.setState({ responseCampaign: value.value });
    if (value.label === "keyword") {
      this.setState({ insertKeword: true });
    } else {
      this.setState({ insertKeword: false });
    }
  };
  globalSearch = () => {
    let { searchInput, campaignList } = this.state;
    let newList = [];
    if (searchInput !== "") {
      newList = campaignList.filter((item, index) => {
        const lc = item.name.toLowerCase();
        const filter = searchInput.toLowerCase();
        return lc.includes(filter);
      });
      this.setState({ campaignList: newList });
    } else {
      this.setState({ campaignList });
    }
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  setAgeSort = () => {
    this.setState({
      sortedInfo: {
        order: "descend",
        columnKey: "age",
      },
    });
  };
  showLoader = () => {
    this.setState({ loading: true });
  };
  hideLoader = () => {
    this.setState({ loading: false });
  };

  navigateFlow = () => {
    history.push({
      pathname: "/flow-chart",
      state: { campaignInfo: camp },
    });
    this.props.ResetFlow();
  };

  handleStatus = (status) => {
    this.setState({ loadStatus: status });
    let data = {
      status: status,
    };
    this.props.handleCampaign(data);
    this.getCampaignList();
  };

  confirmDeletion = (msg) => {
    this.triggerRef.current[msg].close();
    this.setState({ showDeletingModal: true });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.detail?.status !== this.props.detail?.status) {
      console.log(
        prevProps.detail,
        prevProps.detail?.status,
        this.props.detail,
        "update 1"
      );
      this.getCampaignList();
      this.setState({ showDeletingModal: false });
    }
    if (prevState.campaignList.length !== this.state.campaignList.length) {
      console.log(
        prevProps.detail,
        prevProps.detail?.archieved,
        this.props.detail,
        "update 3"
      );
      this.getCampaignList();
    }
    if (prevProps.campData !== this.props.campData) {
      console.log(prevProps.campData, this.props.campData, "update 2");
      this.getCampaignList();
      this.setState({ showDuplicateCampaignModal: false });
    }
    if (
      // prevState.pageNumber !== this.state.pageNumber ||
      prevState.searchInput !== this.state.searchInput ||
      prevState.table_row_limit !== this.state.table_row_limit ||
      prevState.next_table_start_from !== this.state.next_table_start_from
    ) {
      this.getCampaignList();
    }
    if (prevState.statusfilter !== this.state.statusfilter) {
      this.setState({ next_table_start_from: 0 });
      this.getCampaignList();
    }
  }

  handleSelectAction(eventKey, item) {
    console.log("Handle Selct Action: ", eventKey, item.key);
    this.actionRef.current[item.key].hide();
    if (eventKey == 1) {
      this.setState({
        showDuplicateCampaignModal: true,
        duplicateCampaignId: item.key,
      });
    }
    if (eventKey == 2) {
      history.push({ pathname: "/campaign-add", state: { item } });
    }
    if (eventKey == 3) {
      this.setState({ showDeleteCampaignModal: true });
    }
  }

  handleCampaignDeletion() {
    let data = {
      archieved: true,
    };
    this.props.handleCampaign(data);
    this.getCampaignList();
    this.setState({ showDeleteCampaignModal: false });
  }

  handleCampaignDuplication() {
    let data = {
      campaign_id: this.state.duplicateCampaignId,
      name: this.state.duplicateCampaignName,
    };
    this.props._duplicateCampaign(data);
    this.getCampaignList();
  }
  showTotal = (total) => {
    return `Total ${total} Campaigns`;
  };

  //rajeev fixes

  getPageData = () => {
    const { campaignList, searchInput } = this.state;
  
    return { data: campaignList };
  };

  render() {
    // console.log(this.state.campaignList);
    if (this.state.campaignDetailView == true) {
      // console.log("ha", this.state.campaignDeteil);
    }
    const triggerRef = React.createRef();
    let { data, searchInput, numofTotalData } = this.state;
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    //rajeev
    const { data: campaignList } = this.getPageData();

    const MoreActions = ({ onSelect, item, ...rest }) => {
      // console.log("action", item);
      return (
        <Popover {...rest} full>
          <Dropdown.Menu onSelect={onSelect}>
            <Dropdown.Item eventKey={1} icon={<IconBut icon="copy" />}>
              Duplicate
            </Dropdown.Item>
            <Dropdown.Item
              eventKey={2}
              icon={<IconBut icon="edit" />}
              disabled={
                item.status[0] == "Live" ||
                item.status[0] == "Cancelled" ||
                item.campaign_type == "Inbound"
              }
            >
              Edit
            </Dropdown.Item>
            <Dropdown.Item eventKey={3} icon={<IconBut icon="trash-o" />}>
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      );
    };

    const columns = [
      {
        title: "ID",
        width: 80,
        dataIndex: "id",
        key: "id",
        // sorter: (a, b) => a.name.length - b.name.length,
        // sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
        // ellipsis: true,
      },
      {
        title: "Campaign Name",
        width: 150,
        dataIndex: "name",
        key: "name",
        // sorter: (a, b) => a.name.length - b.name.length,
        // sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
        // ellipsis: true,
      },
      {
        title: "Records",
        width: 100,
        dataIndex: "totalRecords",
        key: "totalRecords",
        // sorter: (a, b) => a.totalRecords - b.totalRecords,
        // sortOrder: sortedInfo.columnKey === "totalRecords" && sortedInfo.order,
        ellipsis: true,
        // width: 80,
      },
      {
        title: "Status",
        key: "status",
        filters: [
          {
            text: "LIVE",
            value: "Live",
          },
          {
            text: "PAUSED",
            value: "Paused",
          },
          {
            text: "DRAFT",
            value: "Draft",
          },{
            text: "CANCELLED",
            value: "Cancelled",
          },
        ],
        onFilter: (value, record) => {
          // valueable code by @rajeev
          // this.setState({ statusfilter: value });
          return true;
          // value.replace(/\s+/g, "") === record.status[0].replace(/\s+/g, "")
        },
        dataIndex: "status",
        render: (status) => (
          <span>
            {status.map((tag, index) => {
              let color = tag.length > 5 ? "#63BC7B" : "#FFCD37";
              if (tag === "Ongoing") {
                color = "volcano";
              }
              if (tag == "Live") {
                color = "#179225";
              }
              if (tag == "Scheduled" || tag == "Paused") {
                color = "#FFCD37";
              }
              if (tag == "Draft") {
                color = "#CED2CF";
              }
              if (tag == "Cancelled") {
                color = "#FB4E3E";
              }
              return (
                <>
                  <Whisper
                    trigger={["focus", "click", "active"]}
                    placement="top"
                    onBlur={() => this.triggerRef.current[index].close()}
                    ref={(ref) => (this.triggerRef.current[0] = ref)}
                    speaker={
                      <ToolTip
                        visible={!this.state.showDeletingModal}
                        preventOverflow
                      >
                        <div // ref={this.statusRef}
                          id={`status-${index}`}
                          className="popover-content"
                        >
                          <ButtonGroup justified>
                            <div className="status-group">
                              <IconButton
                                icon={<IconBut icon="play" />}
                                color="green"
                                appearance="ghost"
                                onClick={() => this.handleStatus("Live")}
                                loading={
                                  this.props.statusLoading &&
                                  this.state.loadStatus == "Live"
                                }
                                disabled={tag == "Live" || tag == "Cancelled"}
                              />
                            </div>
                            <div className="status-group">
                              <IconButton
                                icon={<IconBut icon="pause" />}
                                color="yellow"
                                appearance="ghost"
                                onClick={() => this.handleStatus("Paused")}
                                loading={
                                  this.props.statusLoading &&
                                  this.state.loadStatus == "Paused"
                                }
                                disabled={tag == "Paused" || tag == "Cancelled"}
                              />
                            </div>
                            <div className="status-group">
                              <IconButton
                                icon={<IconBut icon="stop" />}
                                color="red"
                                appearance="ghost"
                                onClick={() => this.confirmDeletion(index)}
                                disabled={
                                  tag == "Cancelled" || tag == "Cancelled"
                                }
                                loading={
                                  this.props.statusLoading &&
                                  this.state.loadStatus == "Cancelled"
                                }
                              />
                            </div>
                          </ButtonGroup>
                        </div>
                      </ToolTip>
                    }
                  >
                    <Badge
                      content={tag.toUpperCase()}
                      style={{ backgroundColor: color }}
                    />
                  </Whisper>
                </>
              );
            })}
          </span>
        ),
      },
      {
        title: "Start Date",
        dataIndex: "startDate",
        key: "startDate",
        // sorter: (a, b) => a.startDate - b.startDate,
        // sortOrder: sortedInfo.columnKey === "startDate" && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: "End Date",
        dataIndex: "endDate",
        key: "endDate",
        // sorter: (a, b) => a.endDate - b.endDate,
        // sortOrder: sortedInfo.columnKey === "endDate" && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: "Response",
        align: "center",
        dataIndex: "give_response",
        key: "give_response",
        // sorter: (a, b) => a.response - b.response,
        // sortOrder: sortedInfo.columnKey === "give_response" && sortedInfo.order,
        ellipsis: true,
        render: (item, data) => {
          // console.log("respon: ", item, data);
          return (
            <div className="table-response">
              {item > 0 && <img src={photos.phone3} />}
              <p>{item}</p>
            </div>
          );
        },
      },
      {
        title: "Pickup",
        dataIndex: "pickup",
        key: "pickup",
        // sorter: (a, b) => a.pickup - b.endDate,
        // sortOrder: sortedInfo.columnKey === "endDate" && sortedInfo.order,
        ellipsis: true,
        render: (item, data) => {
          // console.log("respon: ", item, data);
          return (
            <div className="table-response">
              {item > 0 && <img src={photos.phone2} />}
              <p>{item}</p>
            </div>
          );
        },
      },
      {
        title: "No Pickup",
        dataIndex: "no_pickup",
        key: "no_pickup",
        // sorter: (a, b) => a.no_pickup - b.no_pickup,
        // sortOrder: sortedInfo.columnKey === "no_pickup" && sortedInfo.order,
        ellipsis: true,
        render: (item, data) => {
          // counsole.log("respon: ", item, data)
          return (
            <div className="table-response">
              {item > 0 && <img src={photos.blocked} />}
              <p>{item}</p>
            </div>
          );
        },
      },
      {
        title: "Flow",
        dataIndex: "is_edited",
        key: "is_edited",
        // fixed:'right',
        width: 90,

        render: (is_edited, item) => {
          return (
            <Link onClick={() => this.navigateFlow()}>
              <span>
                <But
                  appearance="ghost"
                  color={is_edited ? undefined : "green"}
                  disabled={
                    item.status[0] == "Live" || item.status[0] == "Cancelled"
                  }
                >
                  {is_edited ? "Edit Flow" : "Add Flow"}
                </But>
              </span>
            </Link>
          );
        },
      },
      {
        title: "Result",
        dataIndex: "[key,name]",
        width: 80,
        key: "result",
        render: (key, name) => {
          return (
            <div>
              <div onClick={this.goToDetail}>
                <Link
                  to={{
                    pathname: `/result/${name.key}`,
                    state: { campaignInfo: name },
                  }}
                >
                  <But appearance="primary">Result </But>
                </Link>
              </div>
            </div>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "[key,name]",
        width: 80,
        key: "action",
        align: "center",
        render: (key, item) => {
          return (
            <div className="more-action-btn-container">
              <Whisper
                placement="leftStart"
                trigger="click"
                ref={(ref) => (this.actionRef.current[item.key] = ref)}
                speaker={
                  <MoreActions
                    onSelect={(a) => this.handleSelectAction(a, item)}
                    id={item.key}
                    item={item}
                  />
                }
              >
                <BsThreeDots size={22} />
              </Whisper>
            </div>
          );
        },
      },
    ];

    return (
      <div style={{ padding: 0 }}>
        {this.state.loading && (
          <LoadingBar
            ref={this.loadingRef}
            color="#F35D5E"
            progress={this.state.loadPercent}
            onLoaderFinished={() => this.setState({ loadPercent: 0 })}
            height={3}
            className="top-loading-bar"
          />
        )}
        <div className="campaign-add-button">
          <div>
            <TiFlowMerge size={36} />
            <p>Campaigns</p>
          </div>
          <Link to="/campaign-add">
            <But appearance="primary" size="lg">
              <Icon icon="plus" /> Add Campaign
            </But>
          </Link>
        </div>
        <div style={{ padding: 24 }}>
          <Input
            placeholder="Search for Campaign"
            value={searchInput || ""}
            name="searchInput"
            onChange={this.handleChangeSearch}
            style={{ marginBottom: 15 }}
          />

          <div>
            <Table
              columns={columns}
              dataSource={campaignList}
              onRow={this.onClickRow}
              onChange={this.handleChange}
              // scroll={{ y: 350 }}
              scroll={{ x: "calc(600px + 50%)", y: 450 }}
              pagination={{
                defaultPageSize: 5,
                current:
                  this.state.next_table_start_from /
                    this.state.table_row_limit +
                  1,
                onChange: this.cancel,
                total: numofTotalData,
                showTotal: this.showTotal,
                pageSizeOptions: [5, 10, 15, 20],
              }}
              loading={this.state.loading}
            />
          </div>
        </div>
        <div></div>
        <RModal
          backdrop="static"
          show={this.state.showDeletingModal}
          onHide={() => this.setState({ showDeletingModal: false })}
          size="xs"
        >
          <RModal.Body>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <Icon icon="remind" style={{ color: "#ffb300", fontSize: 28 }} />
            </div>
            Once a campaign is Cancelled, there will be no way to make it live
            again. Are you sure you want to cancel the Campaign?
          </RModal.Body>
          <RModal.Footer>
            <But
              onClick={() => this.setState({ showDeletingModal: false })}
              appearance="subtle"
            >
              No
            </But>
            <But
              onClick={() => this.handleStatus("Cancelled")}
              appearance="primary"
              color="red"
              loading={
                this.props.statusLoading && this.state.loadStatus == "Cancelled"
              }
            >
              Cancel Campaign
            </But>
          </RModal.Footer>
        </RModal>
        <RModal
          backdrop="static"
          show={this.state.showDuplicateCampaignModal}
          onHide={() => this.setState({ showDuplicateCampaignModal: false })}
          size="xs"
        >
          <RModal.Body>
            <div className="duplicate-camp-modal">
              <label>Enter Duplicate Campaign Name</label>
              <TextField
                size="lg"
                block
                placeholder="Campaign Name"
                onChange={(v) => this.setState({ duplicateCampaignName: v })}
              />
            </div>
          </RModal.Body>
          <RModal.Footer>
            <But
              onClick={() =>
                this.setState({ showDuplicateCampaignModal: false })
              }
              appearance="subtle"
            >
              No
            </But>
            <But
              onClick={() => this.handleCampaignDuplication()}
              appearance="primary"
              color="green"
              loading={this.props.campLoading}
            >
              Duplicate
            </But>
          </RModal.Footer>
        </RModal>

        <RModal
          backdrop="static"
          show={this.state.showDeleteCampaignModal}
          onHide={() => this.setState({ showDeleteCampaignModal: false })}
          size="xs"
        >
          <RModal.Body>
            <div className="delete-camp-modal">
              <label>You want to delete this Campaign</label>
            </div>
          </RModal.Body>
          <RModal.Footer>
            <But
              onClick={() => this.setState({ showDeleteCampaignModal: false })}
              appearance="subtle"
            >
              Cancel
            </But>
            <But
              onClick={() => this.handleCampaignDeletion()}
              appearance="primary"
              color="red"
              loading={this.props.campLoading}
            >
              Ok
            </But>
          </RModal.Footer>
        </RModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    statusLoading: state.StartReducer.loading,
    campLoading: state.CampaignReducer.loading,
    campData: state.CampaignReducer.data,
    statusData: state.StartReducer.data,
    detail: state.StartReducer.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ResetFlow: () => dispatch(action.resetFlow()),
    handleCampaign: (data) => dispatch(status.handleCampaign(data)),
    _duplicateCampaign: (data) => dispatch(camps.duplicateCampaign(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
