import * as actionTypes from "../actionTypes"
import { authApi, postApiRequest, apiRequest } from "../actions/api"
import * as API from "../../ApiUrl"
import { loaderStart, loaderFail, updateLangSuccess, updateLangFail } from "../actions/_language"
import history from "../../helpers/history"
import { Alert } from 'rsuite'


export const getLanguage = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.GET_LANGUAGE) {
        dispatch(loaderStart())
        let onSuccess = actionTypes.GET_LANGUAGE_SUCCESS;
        let onError = actionTypes.GET_LANGUAGE_FAIL;
        dispatch(apiRequest('GET', API.LANGUAGE_LIST, null, onSuccess, onError, null, null))
        dispatch(loaderStart())
    }
}

export const getLanugageSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.GET_LANGUAGE_SUCCESS) {
        let data = action.payload;
        if (data) {
            dispatch(updateLangSuccess(data))
        }
        dispatch(loaderFail())
    }
}

export const getLanguageFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.GET_LANGUAGE_FAIL) {
        let data = action.payload;
        if (data) {
            dispatch(updateLangFail(data))
        }
        dispatch(loaderFail())
    }
}


export const langMdl = [
    getLanguage,
    getLanugageSuccess,
    getLanguageFail
]