import React, { useState, useEffect } from 'react'
import { Uploader, Button, Notification, Progress, SelectPicker, Message, Input } from "rsuite"
import { GiSettingsKnobs } from "react-icons/all"
import AudioPlayer from 'react-h5-audio-player';
import { connect } from "react-redux"
import * as action from "../../redux/actions/audio"
import * as flow from "../../redux/actions/flowchart"
import { ProgressBar } from "react-bootstrap"
import { motion } from "framer-motion"
import history from "../../helpers/history"
function UploadExternal(props) {
    const [percent, setPercent] = useState(0)
    const [name, setName] = useState('')
    const [Audio, setAudio] = useState(null)
    const { show, setOption, _handleAudio, audio, _uploadAudio, loading, _percent, data, _resetFlow } = props
    const [selectedLang, setLang] = useState(null)
    const { Line } = Progress

    const handleUpload = (e) => {
        setAudio(e.blobFile)
        console.log("Upload: ", e)
        if (e.blobFile.type === "audio/mpeg") {
            _handleAudio(e)
        }
        else {
            Notification.error({
                title: "Please upload audio file."
            })
        }
    }
    const selectLanguage = (a, b, c) => {
        setLang(b)
    }

    const uploadAudio = () => {
        const data = {
            file: Audio,
            lang: selectedLang,
            name
        }
        console.log('Langgg:', selectedLang)
        _uploadAudio(data, 1)
    }

    const onReset = () => {
        _resetFlow()
        setName('')
        setAudio(null)
        setLang(null)
    }
    return (
        <div className="upload-external-page" >
            <div className="row">
                <div className="col-4">
                    <div className="page-container">
                        <div className="audio-elements">
                            <div className="heading-container" >
                                <h3>Upload External Audio</h3>
                                <GiSettingsKnobs size={25} className="change-option" onClick={() => setOption(0)} />
                            </div>
                            <div className="input-element-container" >
                                {audio == null && <div className="upload-audio-box" >
                                    <Uploader draggable autoUpload={true} multiple={false}
                                        onUpload={(e) => handleUpload(e)} accept="audio" onProgress={(e) => setPercent(e)} >
                                        <div>Click or Drag Audio File to this area to upload</div>
                                    </Uploader>
                                </div>}

                                {audio != null && <motion.div className="audio-player-box"
                                    initial={{ y: 50, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{ duration: 0.3, ease: 'easeOut' }}
                                >
                                    <AudioPlayer src={audio.src} autoPlay={false} autoPlayAfterSrcChange={false} />
                                </motion.div>}
                                {audio != null && <motion.div
                                    initial={{ y: 50, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{ duration: 0.3, ease: 'easeOut', delay: 0.3 }}
                                >
                                    <div className="input-field ">
                                        <label>Save as (Script Name)</label>
                                        <Input placeholder="Audio Name" value={name} onChange={(v) => setName(v)} />
                                    </div>
                                    {/* <SelectPicker data={language} placeholder="Select Language" className="select-picker" onSelect={(a, b, c) => selectLanguage(a, b, c)} block /> */}
                                </motion.div>}
                                {data != null && data.msg == 'Success' &&
                                    <motion.div
                                        initial={{ y: 50, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        transition={{ duration: 0.3, ease: 'easeOut' }}
                                        className="pt-3"
                                    >
                                        <Message
                                            type="success"
                                            title={<p>Your Audio has been uploaded Successfully!</p>}
                                            showIcon
                                        />
                                    </motion.div>
                                }
                            </div>


                        </div>

                        <motion.div className="btn-container-box">
                            {audio != null && _percent == 0 &&
                                <motion.div
                                    initial={{ y: 50, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{ duration: 0.3, ease: 'easeOut', delay: 0.6 }}
                                >
                                    <Button appearance="subtle" block size="lg" onClick={onReset} >Reset</Button>
                                    <Button appearance="primary" block size="lg" className="navigat-btn" onClick={() => uploadAudio(Audio)} disabled={name == ''} >Upload</Button>
                                </motion.div>}
                            {audio != null && _percent > 0 && data == null && <ProgressBar now={_percent} label={`${_percent}%`} animated />}
                            {/* {_percent==100} */}
                            {data != null && data.msg == 'Success' && <motion.div
                            >
                                <Button appearance="subtle" block size="lg" onClick={onReset} >Reset</Button>
                                <Button appearance="primary" block size="lg" onClick={() => history.push('/flow-chart')} >Back to the Flow</Button>
                            </motion.div>
                            }
                        </motion.div>
                    </div>
                </div>
                <div className="col-8">
                    <div className="banner-box">
                        <img src={require("../../image/recording.png")} />

                    </div>
                </div>
            </div>


        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        audio: state.AudioReducer.tempAudio,
        loading: state.AudioReducer.loading,
        _percent: state.AudioReducer.percent,
        data: state.AudioReducer.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        _handleAudio: (data) => dispatch(action.handleAudio(data)),
        _uploadAudio: (data, option) => dispatch(action.uploadAudio(data, option)),
        _resetFlow: () => dispatch(flow.resetFlowRedux())
    }
}

// const enhanceUpload=LangWrapper(UploadExternal)
// UploadExternal = connect(mapStateToProps, mapDispatchToProps)(UploadExternal)

export default connect(mapStateToProps, mapDispatchToProps)(UploadExternal)