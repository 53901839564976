import * as actionTypes from "../actionTypes"


export const handleAudio=(data)=>({
    type:actionTypes.UPLOAD_AUDIO,
    payload:{data}
})

export const updateAudioSuccess=(data)=>({
    type:actionTypes.UPDATE_AUDIO_SUCCESS,
    payload:{data}
})

export const updateAudioFail=(data)=>({
    type:actionTypes.UPDATE_AUDIO_FAIL,
    payload:{data}
})

export const loaderStart=()=>({
    type:actionTypes.UPLOAD_AUDIO_START
})

export const loaderOff=()=>({
    type:actionTypes.UPLOAD_AUDIO_OFF
})

export const uploadAudio=(data,option)=>({
    type:actionTypes.UPLOAD_AUDIO_BACKEND,
    payload:{data,option}
})


// Fetch Script


export const handleFetchScript=()=>({
    type:actionTypes.FETCH_SCRIPT
})


export const updateFetchScriptSuccess=(data)=>({
    type:actionTypes.UPDATE_FETCH_SCRIPT_SUCCESS,
    payload:{data}
})

export const updateFetchScriptFail=(data)=>({
    type:actionTypes.UPDATE_FETCH_SCRIPT_FAIL,
    payload:{data}
})



//Post Audio Acitivity Detail------


export const sendAudioScript=(data)=>({
    type:actionTypes.ADD_SCRIPT,
    payload:{data}
})

// text to speech

export const textToSpeech=(data)=>({
    type:actionTypes.TEXT_TO_SPEECH,
    payload:{data}
})


export const updateTextToSpeechSuccess=(data)=>({
    type:actionTypes.UPDATE_TEXT_TO_SPEECH_SUCCESS,
    payload:{data}
})

export const updateTextToSpeechFail=(data)=>({
    type:actionTypes.UPDATE_TEXT_TO_SPEECH_FAIL,
    payload:{data}
})


export const ttsLoaderStart=()=>({
    type:actionTypes.TEXT_TO_SPEECH_START,
})
export const ttsLoaderOff=()=>({
    type:actionTypes.TEXT_TO_SPEECH_OFF,
})


//text to speech with data link

export const ttswdl=(data)=>({
    type:actionTypes.TTSWDL,
    payload:{data}
})

export const updateTTSWDLSuccess=(data)=>({
    type:actionTypes.UPDATE_TTSWDL_SUCCESS,
    payload:{data}
})

export const updateTTSWDLFail=(data)=>({
    type:actionTypes.UPDATE_TTSWDL_FAIL,
    payload:{data}
})

export const ttswdlStart=(data)=>({
    type:actionTypes.TTSWDL_START
})
export const ttswdlOff=(data)=>({
    type:actionTypes.TTSWDL_OFF
})

export const fetchAudioFlow=(data)=>({
    type:actionTypes.FETCH_SELECTED_AUDIO_FLOW,
    payload:{data}
})

export const fetchAudioLoaderStart=()=>({
    type:actionTypes.FETCH_SELECTED_AUDIO_FLOW_START
})

export const fetchAudioLoaderOff=()=>({
    type:actionTypes.FETCH_SELECTED_AUDIO_FLOW_OFF
})



