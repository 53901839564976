import React, { useEffect, useState } from 'react'
import { Button, Table, Whisper, Tooltip, Input } from "rsuite"
import { photos } from "../../constant/images"
import { VscAdd } from "react-icons/all"
import { connect } from "react-redux"
import * as action from "../../redux/actions/audio"
import AudioPlayer from 'react-h5-audio-player';
import moment from "moment"
import history from "../../helpers/history"
function AudioScriptView(props) {
    const { _fetchScript, script, loading, setShow, show } = props;
    const { Column, HeaderCell, Cell, Pagination } = Table;
    const [displayLength, setDisplayLength] = useState(8)
    const [page, setPage] = useState(1)
    const [sortColumn, setSortColumn] = useState(null)
    const [sortType, setSortType] = useState(null)
    const [search, setSearch] = useState('')
    const [altData, setAltData] = useState(null)

    const handleChangePage = (dataKey) => {
        console.log("Data key Page: ", dataKey)
        setPage(dataKey)
    }
    const handleChangeLength = (dataKey) => {
        console.log("Data key Length: ", dataKey)
        setPage(1)
        setDisplayLength(dataKey)
    }
    const handleSortColumn = (sortColumn, sortType) => {
        setTimeout(() => {
            setSortColumn(sortColumn)
            setSortType(sortType)
        }, 500)
    }

    const getData = (data) => {
        if (sortType && sortColumn) {
            return data.sort((a, b) => {
                let x = a[sortColumn];
                let y = b[sortColumn];
                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }
        return data.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        }).reverse();
    }

    const AudioCell = ({ rowData, dataKey, ...props }) => {
        return (
            <Cell {...props}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                    {/* <audio controls src={rowData[dataKey]} ></audio> */}
                    <AudioPlayer src={rowData[dataKey]}
                        showJumpControls={false}
                        showSkipControls={false}
                        layout="horizontal-reverse"
                        autoPlayAfterSrcChange={false}
                        showFilledProgress={false}
                        showDownloadProgress={false}
                        customIcons={{
                            play: <img src={photos.play} style={{ height: 25 }} />,
                            pause: <img src={photos.pause} style={{ height: 25 }} />
                        }
                        }
                    />
                </div>
            </Cell>
        )
    }

    const CreatedDate = ({ rowData, dataKey, ...props }) => {
        return (
            <Cell {...props} >
                <p style={{ margin: 0 }} >{moment(rowData[dataKey]).format('MMMM Do YYYY')}</p>
            </Cell>
        )
    }

    const ScriptCell = ({ rowData, dataKey, ...props }) => {
        return (
            <Cell {...props} >
                <Whisper
                    trigger={["hover", "focus", "click", "active"]}
                    speaker={
                        <Tooltip visible >
                            <div className="audio-msg" >
                                <h4>Script</h4>
                                <p>{rowData[dataKey]}</p>
                            </div>
                        </Tooltip>
                    }
                >
                    <img src={photos.chat} style={{ height: 25 }} />
                </Whisper>
            </Cell>
        )
    }

    useEffect(() => {
        _fetchScript()
    }, [])

    useEffect(() => {
        console.log("Fetched Audio Script: ", script)
        setAltData(script)
    }, [script])


    const handleSearchScript = (value) => {
        setSearch(value)
        let newList = [];
        if (value !== "") {
            newList = altData.filter((item, index) => {
                const lc = item.name.toLowerCase();
                const filter = value.toLowerCase();
                return lc.includes(filter);
            });
            setAltData(newList)
        } else {
            setAltData(script)
        }
    }

    return (
        <div className="audioScriptView" >
            <div className="audioScriptView__header">
                <h3>Audio Scripts</h3>
                <div className="audioScriptView__btn__box" onClick={() => history.push('/audio-script/add-script')} >
                    <Button appearance="ghost" size="lg" > <VscAdd size={22} className="add-icon"
                    /> Add Script</Button>
                </div>
            </div>
            <div className="audioScriptView__search__box" >
                <Input block size="lg" placeholder="Search Script" onChange={(v) => handleSearchScript(v)} />
            </div>
            <div className="audioScriptView__table" >
                <Table
                    data={altData !== null ? getData(altData) : []}
                    loading={loading}
                    rowHeight={50}
                    autoHeight
                    sortColumn={sortColumn}
                    sortType={sortType}
                    onSortColumn={handleSortColumn}

                >
                    <Column width={70} sortable >
                        <HeaderCell>ID</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>
                    <Column sortable >
                        <HeaderCell>Type</HeaderCell>
                        <Cell dataKey="audio_type" />
                    </Column>
                    <Column minWidth={300} flexGrow={1} sortable >
                        <HeaderCell>Name</HeaderCell>
                        <Cell dataKey="name" />
                    </Column>
                    <Column width={200} sortable >
                        <HeaderCell>Created Date</HeaderCell>
                        <CreatedDate dataKey="created_date" />
                    </Column>
                    <Column sortable >
                        <HeaderCell>Script</HeaderCell>
                        {/* <Cell dataKey="text_message" /> */}
                        <ScriptCell dataKey="text_message" />
                    </Column>
                    <Column sortable >
                        <HeaderCell>Length in (sec)</HeaderCell>
                        <Cell dataKey="length_in_seconds" />
                    </Column>
                    <Column sortable >
                        <HeaderCell>Audio</HeaderCell>
                        <AudioCell dataKey="audiofile" />
                    </Column>

                </Table>
                {altData != null && <Pagination
                    lengthMenu={[
                        {
                            value: 8,
                            label: 8
                        },
                        {
                            value: 15,
                            label: 15,
                        },
                        {
                            value: 20,
                            label: 20,
                        },
                    ]}
                    activePage={page}
                    displayLength={displayLength}
                    total={altData.length}
                    onChangeLength={handleChangeLength}
                    onChangePage={handleChangePage}
                />}
            </div>
        </div>
    )
}



const mapStateToProps = (state) => {
    return {
        script: state.AudioReducer.audio,
        loading: state.AudioReducer.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        _fetchScript: () => dispatch(action.handleFetchScript()),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AudioScriptView)