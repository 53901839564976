/** @format */

import React, { useState, useEffect } from "react";
import { SelectPicker, HelpBlock } from "rsuite";
import Keypad from "./Keypad";
import VoiceNote from "./VoiceNote";
import EditRule from "./EditRule"
import UserKeypadInput from "./UserKeypadInput";
import UserVoiceInput from "./UserVoiceInput"
import { connect } from "react-redux";
import { images } from "../../../../constant";
import { motion } from "framer-motion";
import { BiDotsHorizontalRounded } from "react-icons/all"
// import {} from "react-icons/all"
const { photos } = images;
const options = [
  { label: "Voice Response", value: 1 },
  { label: "Keypad", value: 2 },
  {label:"User Keypad Input",value:4},
  {label:"User Voice Input",value:5},
];
const voiceResponse = [
  { label: "Voice Response", value: 1 },
];
const keypad = [
  { label: "Keypad", value: 2 },
];

function Rule(props) {
  const { ruleFlow, selectedNode, _fetchRules, chart } = props;
  const [selectedOption, setOption] = useState(0);
  const [editItem, setEditItem] = useState(null)

  const selectOptions = (a) => {
    console.log("Options: ", a);
    setOption(a);
  };

  const renderOptions = () => {
    switch (selectedOption) {
      case 1:
        return <VoiceNote selectedNode={selectedNode} chart={chart}  />;
      case 2:
        return <Keypad selectedNode={selectedNode} chart={chart}  />;
      case 4:
        return <UserKeypadInput selectedNode={selectedNode} chart={chart}  />;
      case 5:
        return <UserVoiceInput selectedNode={selectedNode} chart={chart}  />;
      case 3:
        return <EditRule selectedNode={selectedNode} item={editItem} setOption={setOption} _fetchRules={_fetchRules} />

      default:
        return <div />;
    }
  };

  const removeOption = () => {
    setOption(0);
  };

  useEffect(() => {
    console.log("Rule FLow: ", ruleFlow)
  }, [ruleFlow])


  const editRule = (item) => {
    setOption(3)
    setEditItem(item)
  }

  return (
    <div className="rule-main-box">
      {selectedOption !== 3 && ruleFlow&& ruleFlow.rule_type!=="User Keypad Input" && ruleFlow.rule_type!=='User Voice Input'?<div className="py-3">
        <label>Create Rule</label>
        <SelectPicker
          data={ruleFlow && ruleFlow.length == 0 ? options : ruleFlow && ruleFlow.rule_type == 'Voice Response' ? voiceResponse : keypad}
          block
          searchable={false}
          onSelect={(a) => selectOptions(a)}
          onClean={() => removeOption()}
        />
        <HelpBlock>{ruleFlow && ruleFlow.length == 0 ? "" : ruleFlow && ruleFlow.rule_type == 'Voice Response' ? "* You can only create Voice Response Type with this rule." : "* You can only create Keypad Response Type with this rule."}</HelpBlock>
      </div>:
     ruleFlow?<div className="info-msg-box" >
        <img src={photos.info} />
        <h3>{`You can't create any other rule child with ${ruleFlow?.rule_type || 'Rule'}`}</h3>
      </div>:
      <div className="info-msg-box" >
      <img src={photos.info} />
      <h3>Please Wait...</h3>
    </div>
    }
      <div>{renderOptions()}</div>
      {selectedOption === 0 && (
        <div className="rules-detail-box">
          <label>{ruleFlow && ruleFlow.length > 0 && "Your Rules:"}</label>
          {ruleFlow != null && ruleFlow.rule_type == 'Voice Response' &&
            ruleFlow.child_nodes.map((item, index) => {
              return (
                <motion.div
                  className="child-box"
                  initial={{ y: index * 40, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                >
                  <div className="edit-btn-box" onClick={() => editRule(item)} >
                    <BiDotsHorizontalRounded size={20} />
                  </div>
                  <div className="info-box">
                    <p>{item?.keyword || ""}</p>
                    <p>{item?.user_input.toString() || ""}</p>
                  </div>
                  <div className="asset-box">
                    <img src={photos.voiceRecording} />
                  </div>
                </motion.div>
              );
            })}
          {ruleFlow != null && ruleFlow.rule_type == 'Keypad' &&
            ruleFlow.child_nodes.map((item, index) => {
              return (
                <motion.div
                  className="child-box"
                  initial={{ y: index * 40, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                >
                  <div className="info-box">
                    <p>{item}</p>
                  </div>
                  <div className="asset-box">
                    <img src={photos.dialpad} />
                  </div>
                </motion.div>
              );
            })}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ruleFlow: state.RuleReducer.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Rule);
