/** @format */

import React, { useState } from "react";
import { Input, TagPicker, Button, SelectPicker } from "rsuite";
import { connect } from "react-redux";
import moment from "moment";
import * as action from "../../../../redux/actions/flowchart";
import Chance from "chance";
import LangWrapper from "../../../Audio-Script-V2/LangWrapper";
import {getRuleId} from './helper';
import { Alert } from "rsuite";

export let VoiceNote = (props) => {
  const { flow, _updateNodeRedux, language, loading, selectedNode, chart } = props;
  const [nodes, setNodes] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [selectedLang, setLang] = useState(null);
  const [tag, setTag] = useState(null);


  const selectLanguage = (a, b, c) => {
    setLang(b);
  };

  const updateNode = (e) => {

    let chance = new Chance();
    let nodeId = chance.string({
      length: 36,
      casing: "upper",
      alpha: true,
      numeric: true,
    });
    let linkId = chance.string({
      length: 36,
      casing: "upper",
      alpha: true,
      numeric: true,
    });
    let newNode = chart;
    
    getRuleId(chart,selectedNode.id)
    .then((res)=>{
      console.log('==Rule Node Found Success===',res)

      let oneNode1 = {
        id: nodeId,
        type: keyword,
        orientation: 0,
        ports: {
          port1: {
            id: "port1",
            type: "top",
            properties: {
              custom: "property",
            },
            position: {
              x: 100,
              y: 64,
            },
          },
          port2: {
            id: "port2",
            type: "bottom",
            properties: {
              custom: "property",
            },
          },
        },
        position: {
          x: res.rulePosition.x,
          y: res.rulePosition.y + 100
      },
        properties: {
          custom: "property",
          node_type: "Rule Child",
          rule_type: "Voice Response",
          lang_code: selectedLang.code,
          lang_name: selectedLang.label,
          keyword: keyword,
          user_input: tag !== null ? tag : [],
          rule_node_id: res.ruleId,
        },
        size: {
          width: 200,
          height: 64,
        },
      };
  
      let link = {
        id: linkId,
        from: {
          nodeId: nodeId,
          portId: "port1",
        },
        to: {
          nodeId: res.ruleId,
          portId: "port2",
        },
      };
  
      newNode = {
        ...chart,
        nodes: {
          ...chart.nodes,
  
          [nodeId]: oneNode1,
        },
        links: {
          ...chart.links,
          [linkId]: link,
        },
      };
      _updateNodeRedux(newNode,null,'reload');
      console.log("New Nodes:  ", newNode);
    })
    .catch((err)=>{
      Alert.error('Something went wrong. Refreshing your session',2000)
            window.location.reload()
      console.log('====Err===',err)
    })

   
  };

  const insertKeyword = (v) => {
    setKeyword(v);
    let k = [];
    k.push(v);
    setTag(k);
  };

  return (
    <div className="voicenote-container">
      {/* <div className="voice-box" aria-label="Yes" onClick={()=>createNode('Yes')} >
                <p>Yes</p>
                <p>Yes, <span>Yeah, </span> <span>Yup, </span><span>Hmm</span> </p>
            </div>
            <div className="voice-box" aria-label="No" onClick={()=>createNode('No')} >
                <p>No</p>
                <p>No, <span>Nope, </span> <span>Nah, </span><span>Ahan</span> </p>
            </div> */}
      <div className="py-2">
        <Input
          placeholder="Keyword"
          block
          size="lg"
          value={keyword}
          onChange={(v) => setKeyword(v)}
        />
      </div>
      <div className="py-2 tag-picker">
        <TagPicker
          creatable
          placeholder="Synonyms"
          block
          size="lg"
          onChange={(v) => setTag(v)}
        />
      </div>
      <div className="py-2 tag-picker">
        <SelectPicker
          data={language}
          placeholder="Select Language"
          size="lg"
          onSelect={(a, b, c) => selectLanguage(a, b, c)}
          block
        />
      </div>
      <div className="py-5">
        <Button
          appearance="primary"
          block
          size="lg"
          onClick={updateNode}
          loading={loading}
          disabled={keyword == "" || selectedLang == null}
        >
          Create Response
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  flow: state.NodeReducer.flow,
  loading: state.NodeReducer.loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    _updateNodeRedux: (data,callback,cbParam) => dispatch(action.updateNodeRedux(data,callback,cbParam)),
  };
};

VoiceNote = connect(mapStateToProps, mapDispatchToProps)(VoiceNote);

export default LangWrapper(VoiceNote);
