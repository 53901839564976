/** @format */

import * as actionTypes from "../actionTypes";
import { updateObject } from "./utility";

const initialState = {
  loading: false,
  data: null,
  flow: null,
  error: null,
};

const showLoader = (action, state) => {
  return updateObject(state, {
    loading: true,
  });
};

const hideLoader = (action, state) => {
  return updateObject(state, {
    loading: false,
  });
};

const updateDataSuccess = (action, state) => {
  return {
    data: action.payload.data,
  };
};

const updateDataFail = (action, state) => {
  return {
    error: action.payload.data,
  };
};

const updateNodeRedux = (action, state) => {
  return {
    flow: action.payload.data,
  };
};

const resetFlowRedux = (action, state) => {
  return {
    flow: null,
  };
};

const NodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NODE_START:
      return showLoader(action, state);
    case actionTypes.NODE_OFF:
      return hideLoader(action, state);
    case actionTypes.UPDATE_NODE_SUCCESS:
      return updateDataSuccess(action, state);
    case actionTypes.UPDATE_NODE_FAIL:
      return updateDataFail(action, state);
    case actionTypes.UPDATE_NODE_REDUX_SUCCESS:
      return updateNodeRedux(action, state);
    case actionTypes.RESET_FLOW_REDUX:
      return resetFlowRedux(action, state);

    default:
      return state;
  }
};

export default NodeReducer;
