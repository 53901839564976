import React, { useState } from "react";
import TTSWrapper from "./HOC";
import LangWrapper from "../LangWrapper";
import { CgTranscript } from "react-icons/all";
import { SelectPicker, Button, Input } from "rsuite";
import { connect } from "react-redux";
import * as action from "../../../redux/actions/audio";
import history from "../../../helpers/history";
function Main(props) {
  const {
    handleChange,
    field,
    language,
    _uploadAudio,
    text,
    loading,
    data,
  } = props;
  const [selectedLang, setLang] = useState(null);
  const [name, setName] = useState("");

  const selectLanguage = (a, b, c) => {
    setLang(b);
  };

  const handleSubmit = () => {
    let data = {
      name,
      text_message: text,
      lang_code: selectedLang.code,
      lang_name: selectedLang.label,
      audio_type: "Text Template",
      audio_file: null,
      length_in_seconds: null,
    };
    console.log("Data: ", data);
    _uploadAudio(data, 3);
  };

  return (
    <div className="tts-main">
      <div className="tts-main-box">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h2>Script</h2>
          <CgTranscript size={40} style={{ marginTop: 7 }} />
        </div>
        <div>
          <div className="py-3">
            <Input
              placeholder="Script Name"
              block
              size="lg"
              onChange={(v) => setName(v)}
            />
          </div>
          <div
            itemType="text"
            className="text-input"
            contentEditable
            onKeyPress={(e) => handleChange(e)}
          ></div>
          <div className="pt-3">
            <SelectPicker
              data={language}
              block
              onSelect={(a, b, c) => selectLanguage(a, b, c)}
            />
          </div>
          <div className="pt-5">
            {data == null ? (
              <Button
                block
                size="lg"
                appearance="primary"
                onClick={handleSubmit}
                loading={loading}
                disabled={
                  field.length !== 2 && selectedLang != null && name !== ""
                }
              >
                Upload Script
              </Button>
            ) : (
              <Button
                block
                size="lg"
                appearance="primary"
                onClick={() => history.goBack()}
              >
                Back to the Flow
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.AudioReducer.loading,
    data: state.AudioReducer.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    _uploadAudio: (data, option) => dispatch(action.uploadAudio(data, option)),
  };
};

Main = connect(mapStateToProps, mapDispatchToProps)(Main);

export default TTSWrapper(LangWrapper(Main));
