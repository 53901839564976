import React from "react";
import { Route, Redirect } from "react-router-dom";

function ProtectedRoute({
  layout: Layout,
  component: Component,
  ...restOfProps
}) {
  const isAuthenticated = localStorage.getItem("token");
//   console.log("this", isAuthenticated);

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to="/sign-in" />
        )
      }
    />
  );
}

export default ProtectedRoute;
