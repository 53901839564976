import React, { useEffect, useState, Fragment } from "react";
import { Table, Button, IconButton, SelectPicker } from "rsuite";
import { photos } from "../../constant/images";
import { CSVLink } from "react-csv";
import { GrDocumentCsv } from "react-icons/all";
import { connect } from "react-redux";

import { Input, Popover as PopOver } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
function ScriptTable(props) {
  const { Column, HeaderCell, Cell, Pagination } = Table;
  const [displayLength, setDisplayLength] = useState(10);
  const [displayLength2, setDisplayLength2] = useState(10);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [page2, setPage2] = useState(1);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [csvOverallData, setOverallData] = useState([]);
  const [searchInput, setsearchInput] = useState("");
  const [filterData, setfilterData] = useState("")
  const [statusOptions, setstatusOptions] = useState([])
  
  const { data, overallData, editoveralltable, loading: loading2,campaignId } = props;

const getStatusOptions=async ()=>{
  let token=localStorage.getItem('token');
  try {
    const {data:options}=await axios.get(`/api/campaigns/status_options/`,{headers:{Authorization:'Bearer'+" "+token}})
    setstatusOptions(options)
    // console.log("overall-=--",options)
  } catch (error) {
    // console.log("error overall---",error)
  }
}


const changestatus=(e)=>{
  setfilterData(e)
  setPage2(1)
  console.log("status value",e)

}








  useEffect(() => {
    getStatusOptions()

    // console.log("Script Table: ", data);
  }, 1);
  // console.log(" overallData", overallData);
  const handleChangePage = (dataKey) => {
    // console.log("Data key Page: ", dataKey);
    setPage(dataKey);
  };
  const handleChangePage2 = (dataKey) => {
    // console.log("Data key Page: ", dataKey);
    setPage2(dataKey);
  };
  useEffect(() => {
    // console.log("pafe", page);
    editoveralltable({
      limit: displayLength,
      offset: (page2 - 1) * displayLength,
      searchInput: searchInput,
      filter:filterData
    });
  }, [searchInput, displayLength, page2,filterData]);

  const handleChangeLength = (dataKey) => {
    // console.log("Data key Length: ", dataKey);
    setPage(1);
    setDisplayLength(dataKey);
  };
  const handleChangeLength2 = (dataKey) => {
    // console.log("Data key Length: ", dataKey);
    setPage2(1);
    setDisplayLength2(dataKey);
  };
  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);

    setTimeout(() => {
      setSortColumn(sortColumn);
      setSortType(sortType);
      setLoading(false);
    }, 500);
  };

  const downloadData = (index) => {
    setCsvData(null);
    // console.log("Index: ", index);
    let csv = [];
    for (let i of data[index].table_data) {
      let csvObject = {
        date: i.call_date,
        firstName: i.first_name,
        lastName: i.last_name,
        phoneNo: i.phone_no,
        responseGroup: i.response_group,
        responseKeyword: i.response_keyword,
      };
      csv.push(csvObject);
    }
    console.log("CSV Data: ", csv);
    setCsvData(csv);
  };

  const handleChangeSearch = (e) => {
    setsearchInput(e.target.value);
    setPage2(1)
    // setfilterData("")
  };

  const downloadOverallData = () => {
    setOverallData(null);
    let csv = [];
    for (let i of overallData) {
      let csvObject = {
        date: i.call_date,
        leadId: i.lead_id,
        firstName: i.first_name,
        mobileNo: i.phone,
        status: i.status,
        noOfAttemps: i.no_of_attempts,
      };
      csv.push(csvObject);
    }
    setOverallData(csv);
  };

  const getData = (data) => {
    // return data;
    if (sortType && sortColumn) {
      return data.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === "string") {
          x = x.charCodeAt();
        }
        if (typeof y === "string") {
          y = y.charCodeAt();
        }
        if (sortType === "asc") {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return data.filter((v, i) => {
      const start = displayLength * (page - 1);
      const end = start + displayLength;
      return i >= start && i < end;
    });
  };

  const AudioCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <div>
          <audio controls src={rowData[dataKey]}></audio>
        </div>
      </Cell>
    );
  };

  return (
    <Fragment>
      <div className="script-table">
        {data != null &&
          data.length > 0 &&
          data.map((item, index) => {
            return (
              <div style={{ marginBottom: 30 }}>
                {(item.script.script_type == "Text" ||
                  item.script.script_type == "Text Template") && (
                  <div className="script-box">
                    <img src={photos.voice} />
                    <div className="script-info">
                      <p>{item.script.script_name}</p>
                      <p>{item.script.script_message}</p>
                    </div>
                  </div>
                )}
                {item.script.script_type == "Recorded" && (
                  <div className="script-box">
                    <img src={photos.voiceRecording} />
                    <div className="script-info">
                      <p>{item.script.script_name}</p>
                      <p>{item.script.script_message}</p>
                    </div>
                  </div>
                )}
                <div collapsible header="Table">
                  <Table
                    wordWrap
                    data={getData(item.table_data)}
                    rowHeight={100}
                    autoHeight
                    loading={loading}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    onSortColumn={handleSortColumn}
                  >
                    <Column width={100} sortable>
                      <HeaderCell>Date</HeaderCell>
                      <Cell dataKey="call_date" />
                    </Column>
                    <Column width={140} sortable>
                      <HeaderCell>First Name</HeaderCell>
                      <Cell dataKey="first_name" />
                    </Column>
                    <Column width={140} sortable>
                      <HeaderCell>Last Name</HeaderCell>
                      <Cell dataKey="last_name" />
                    </Column>
                    <Column width={200} sortable>
                      <HeaderCell>Phone No</HeaderCell>
                      <Cell dataKey="phone_no" />
                    </Column>
                    <Column width={160} sortable>
                      <HeaderCell>Response Keyword</HeaderCell>
                      <Cell dataKey="response_keyword" />
                    </Column>
                    <Column width={160} sortable>
                      <HeaderCell>Response Group</HeaderCell>
                      <Cell dataKey="response_group" />
                    </Column>
                    <Column width={350} sortable>
                      <HeaderCell>Script</HeaderCell>
                      <AudioCell dataKey="recorded_file" />
                    </Column>
                  </Table>
                  <div className="download-box">
                    <CSVLink
                      data={csvData}
                      filename={`Voicemate-${item.script.script_name}-Result.csv`}
                    >
                      <Button
                        appearance="ghost"
                        size="sm"
                        onClick={() => downloadData(index)}
                      >
                        <img src={photos.csv} className="csv-icon" /> Download
                      </Button>
                    </CSVLink>
                  </div>
                  <div className="background-wallpaper">
                    <img src={photos.csv} />
                  </div>

                  <Pagination
                    lengthMenu={[
                      {
                        value: 10,
                        label: 10,
                      },
                      {
                        value: 20,
                        label: 20,
                      },
                      {
                        value: 30,
                        label: 30,
                      },
                      {
                        value: 50,
                        label: 50,
                      },
                      {
                        value: 100,
                        label: 100,
                      },
                    ]}
                    activePage={page}
                    displayLength={displayLength}
                    total={item.table_data.length}
                    onChangeLength={handleChangeLength}
                    onChangePage={handleChangePage}
                  />
                </div>
              </div>
            );
          })}
      </div>
      {overallData !== null && (
        <div className="overall-table">
          <div className="overall-table__header">
            <h3>Overall Result</h3>
          </div>
          <div style={{ padding: 24 }}>
            <Input
              placeholder="Search"
              value={searchInput || ""}
              name="searchInput"
              onChange={handleChangeSearch}
              style={{ marginBottom: 15 }}
            />
          </div>
          <Table
            autoHeight
            loading={loading2}
            data={overallData.results}
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={handleSortColumn}
          >
            <Column
              width={100}
              //  sortable
            >
              <HeaderCell>Date</HeaderCell>
              <Cell dataKey="call_date" />
            </Column>
            <Column
              // width={140}
              //  sortable
            >
              <HeaderCell>VM ID</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column
              width={140}
              //  sortable
            >
              <HeaderCell>Lead ID</HeaderCell>
              <Cell dataKey="lead_id" />
            </Column>
            <Column
              flexGrow={1}
              // sortable
            >
              <HeaderCell>First Name</HeaderCell>
              <Cell dataKey="first_name" />
            </Column>
            <Column
              flexGrow={1}
              // sortable
            >
              <HeaderCell>Mobile Number</HeaderCell>
              <Cell dataKey="phone" />
            </Column>
            <Column
              flexGrow={1}
              //  sortable
            >
              <HeaderCell>
                <SelectPicker
                  data={statusOptions}
                  valueKey="id"
                  labelKey="name"
                  appearance="Status"
                  placeholder="Status"
                  style={{ width: 224 }}
                  cleanable={true}
                  value={filterData}
                  onClean={()=>changestatus("")}
                  onSelect={(e)=>changestatus(e)}
                />
              </HeaderCell>
              {/* <Cell dataKey={["status"."name"]} /> */}
              <Cell>
                {(rowData, rowIndex) => {
                  return <div>{rowData.status.name}</div>;
                }}
              </Cell>
            </Column>
            <Column
              flexGrow={1}
              // sortable
            >
              <HeaderCell>Sub Status</HeaderCell>
              <Cell dataKey="substatus" />
            </Column>
            <Column
              flexGrow={1}
              //  sortable
            >
              <HeaderCell>Attempts</HeaderCell>
              <Cell dataKey="no_of_attempts" />
            </Column>
          </Table>
          <div className="download-box">
            <CSVLink
              data={csvOverallData}
              filename={`Voicemate-Overall-Result.csv`}
            >
              <Link to={`/api/manage-calls/download-overall-results/${campaignId}`} target="_blank">
              <Button
                appearance="ghost"
                size="sm"
                // onClick={downloadOverallData}
              >
                <img src={photos.csv} className="csv-icon" /> Download
              </Button>
              </Link>
            </CSVLink>
          </div>
          <div className="background-wallpaper">
            <img src={photos.csv} />
          </div>
          <Pagination
            lengthMenu={[
              {
                value: 10,
                label: 10,
              },
              {
                value: 20,
                label: 20,
              },
              {
                value: 30,
                label: 30,
              },
              {
                value: 50,
                label: 50,
              },
              {
                value: 100,
                label: 100,
              },
            ]}
            activePage={page2}
            displayLength={displayLength2}
            total={overallData.count}
            onChangeLength={handleChangeLength2}
            onChangePage={handleChangePage2}
          />
        </div>
      )}
    </Fragment>
  );
}
const mapStateToProps = (state) => {
  return {
    overallData: state.ResultReducer.overallData,
    loading: state.ResultReducer.loading,
  };
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     _fetchResult: (id) => dispatch(action.handleFetchResult(id)),
//     _resetResult: () => dispatch(action.resetResult()),
//   };
// };

export default connect(mapStateToProps, null)(ScriptTable);
