/** @format */

import * as actionTypes from "../actionTypes";

//update node in redux

export const updateNodeRedux = (data,callback,cbParam) => ({
  type: actionTypes.UPDATE_NODE_REDUX,
  payload: { data,callback,cbParam },
});

export const updateNodeReduxSuccess = (data) => ({
  type: actionTypes.UPDATE_NODE_REDUX_SUCCESS,
  payload: { data },
});

// fetch flowchart from database
export const fetchFlowChart = (data) => ({
  type: actionTypes.FETCH_FLOW_DETAIL,
  payload: { data },
});

export const updateFlowChartSuccess = (data) => ({
  type: actionTypes.UPDATE_FLOW_DETAIL_SUCCESS,
  payload: { data },
});

export const updateFlowChartFail = (data) => ({
  type: actionTypes.UPDATE_FLOW_DETAIL_FAIL,
  payload: { data },
});

// post particular node
export const createNode = (data,callback,cbParam) => ({
  type: actionTypes.CREATE_NODE,
  payload: { data,callback,cbParam },
});

export const updateNodeSuccess = (data) => ({
  type: actionTypes.UPDATE_NODE_SUCCESS,
  payload: { data },
});

export const updateNodeFail = (data) => ({
  type: actionTypes.UPDATE_NODE_FAIL,
  payload: { data },
});

//LOADER

export const flowLoaderStart = () => ({
  type: actionTypes.FLOW_LOADER_START,
});

export const flowLoaderOff = () => ({
  type: actionTypes.FLOW_LOADER_OFF,
});

export const nodeStart = () => ({
  type: actionTypes.NODE_START,
});

export const nodeOff = () => ({
  type: actionTypes.NODE_OFF,
});

export const deleteNode = (data) => ({
  type: actionTypes.DELETE_NODE,
  payload: { data },
});

export const resetFlow = () => ({
  type: actionTypes.RESET_FLOW,
});

export const resetFlowRedux = () => ({
  type: actionTypes.RESET_FLOW_REDUX,
});
