import * as actionTypes from "../actionTypes"

export const getVoices = (data) => ({
    type: actionTypes.GET_VOICES,
    payload: { data }
})

export const getVoicesSuccess = (data) => ({
    type: actionTypes.UPDATE_GET_VOICES_SUCCESS,
    payload: { data }
})

export const getVoicesFail = (data) => ({
    type: actionTypes.UPDATE_GET_VOICES_FAIL,
    payload: { data }
})

export const getVoicesStart = (data) => ({
    type: actionTypes.GET_VOICES_START,
    payload: { data }
})

export const getVoicesFinish = (data) => ({
    type: actionTypes.GET_VOICES_FINISH,
    payload: { data }
})

export const resetVoice = () => ({
    type: actionTypes.RESET_VOICE
})