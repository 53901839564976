/** @format */

import React, { useState, useEffect } from "react";
import { GiSettingsKnobs } from "react-icons/all";
import { Input, SelectPicker, Button, Message, Icon } from "rsuite";
import LangWrapper from "./LangWrapper";
import * as action from "../../redux/actions/audio";
import * as flow from "../../redux/actions/flowchart";
import * as voiceAction from "../../redux/actions/_voices"
import * as changeAction from "../../redux/actions/_change"
import { connect } from "react-redux";
import AudioPlayer from "react-h5-audio-player";
import { motion } from "framer-motion";
import { images } from "../../constant";
import history from "../../helpers/history";
import * as Scroll from 'react-scroll';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
function TextToSpeech(props) {
  const {
    show,
    setOption,
    language,
    loading,
    _textToSpeech,
    data,
    _uploadAudio,
    audioData,
    _resetAudio,
    _getVoices,
    voiceData,
    _handleChange,
    voiceLoading,
    _resetVoice,
    langLoading,
    comingFromFlow
  } = props;
  const { photos } = images;
  const [selectedLang, setLang] = useState(null);
  const [scriptName, setScriptName] = useState("");
  const [script, setScript] = useState("");
  const [loadings, setLoading] = useState(false)
  const [gender, setGender] = useState('MALE')
  const [voice, setVoice] = useState(null)
  const [reset, setReset] = useState(false)
  const selectLanguage = (a, b, c) => {
    setLang(b);
    console.log("Selected Lang: ", b);
  };
  useEffect(() => {
    Events.scrollEvent.register('begin', function (to, element) {
      console.log('begin', arguments);
    });

    Events.scrollEvent.register('end', function (to, element) {
      console.log('end', arguments);
    });
    console.log("Props receiving: ", props);
    let defaultData = {
      language_code: "en-IN",
      gender: "MALE"
    }
    _getVoices(defaultData)
  }, []);

  useEffect(() => {
    if (data !== null) {
      scroll.scrollToBottom();
      // var scroll = Scroll.animateScroll;
      // scroll.scrollToBottom({ to: "btns-container", smooth: true })
    }
  }, [data])

  const getData = (data) => {
    return data
  }

  const onSubmit = () => {
    let data = {
      text: script,
      voice_name: voice.original_name,
      name: scriptName,
    };
    _textToSpeech(data);
  };
  useEffect(() => {
    if (language !== null && voice !== null && data !== null && !reset) {
      let data = {
        voice,
        selectedLang,
        gender,
        script,
        option: 1,
        ttswdl: false
      }
      _handleChange(data)
    }
  }, [selectedLang, gender])
  useEffect(() => {
    if (language !== null && voice !== null && data !== null && !reset) {
      let data = {
        voice,
        selectedLang,
        gender,
        script,
        option: 2,
        ttswdl: false
      }
      _handleChange(data)
    }
  }, [voice])

  useEffect(() => {
    if (data == null && selectedLang !== null && data == null && audioData == null) {
      let datas = {
        language_code: selectedLang.code,
        gender: gender
      }
      _getVoices(datas)
    }
  }, [selectedLang, gender])

  const handleConfirm = () => {
    console.log("Data for audio file: ", data);
    let datas = {
      audio_file: data.audiofile_path,
      name: scriptName,
      length_in_seconds: data.duration,
      audio_type: "Text",
      text_message: script,
      lang_code: selectedLang.code,
      voice_name: voice.original_name
    };

    _uploadAudio(datas, 2);
  };

  const onReset = () => {
    setReset(true)
    setLoading(true)
    setTimeout(() => {
      setLang(null)
      setScriptName("")
      setScript("")
      setVoice(null)
      _resetVoice()
      _resetAudio()
      getData(language)
      setLoading(false)
      setReset(false)
    }, 500)

  }

  return (
    <div className="text-to-speech-page">
      <div className="row">
        <div className="col-6">
          <motion.div
            className="form-box"
          // animate={{ height: data != null ? "80vh" : "100%" }}
          >
            <div className="input-elements">
              <div className="heading-container">
                <h3>Text To Speech</h3>
                <GiSettingsKnobs
                  size={25}
                  className="change-option"
                  onClick={() => setOption(0)}
                />
              </div>
              <div className="input-fields-box" >
                <div className="input-field">
                  <label>Script Name</label>
                  <Input
                    placeholder="Script Name"
                    value={scriptName}
                    onChange={(value) => setScriptName(value)}
                  />
                </div>
                <div className="input-field">
                  <label>Select your Language</label>
                  {!loadings && <SelectPicker
                    data={getData(language)}
                    placement="bottomStart"
                    placeholder="Select Language"
                    className="select-picker"
                    onSelect={(a, b, c) => selectLanguage(a, b, c)}
                    cleanable={false}
                    block
                    renderMenu={menu => {
                      if (langLoading) {
                        return (
                          <p style={{ padding: 4, color: '#f35d5e', textAlign: 'center' }}>
                            <Icon icon="spinner" spin size="2x" />
                          </p>
                        )
                      }
                      return menu
                    }}
                  />}
                </div>
                <div className="input-field">
                  <label>Select your Gender</label>
                  <div className="gender-div" >
                    <motion.div className={gender == 'MALE' ? "gender-box selected" : "gender-box"} onClick={() => setGender('MALE')}
                      initial={{ x: -40 }}
                      animate={{ x: 0 }}
                      transition={{ ease: "easeOut" }}
                    >
                      <img src={photos.man} />
                      <div>
                        <h5>Gender</h5>
                        <h3>MALE</h3>
                      </div>

                    </motion.div>
                    <motion.div className={gender == 'FEMALE' ? "gender-box selected" : "gender-box"} onClick={() => setGender('FEMALE')}
                      initial={{ x: 40 }}
                      animate={{ x: 0 }}
                      transition={{ ease: "easeOut" }}
                    >
                      <img src={photos.woman} />
                      <div>
                        <h5>Gender</h5>
                        <h3>Female</h3>
                      </div>
                    </motion.div>
                  </div>
                </div>
                <div className="input-field" >
                  <label>Select your preferred Voice</label>
                  <SelectPicker
                    data={voiceData?.voices || []}
                    labelKey="replaced_name"
                    valueKey="original_name"
                    cleanable={false}
                    searchable={false}
                    onSelect={(a, b, c) => setVoice(b)}
                    renderMenu={menu => {
                      if (voiceLoading) {
                        return (
                          <p style={{ padding: 4, color: '#f35d5e', textAlign: 'center' }}>
                            <Icon icon="spinner" spin size="2x" />
                          </p>
                        )
                      }
                      return menu
                    }}
                  />
                </div>
                <div className="input-field">
                  <label>Enter your Question</label>
                  <Input
                    componentClass="textarea"
                    rows={4}
                    placeholder="Eg: Hi we are calling from XYZ Pvt. Ltd."
                    value={script}
                    onChange={(value) => setScript(value)}
                  />
                  {data !== null && < div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row" }} className="pt-2" >
                    <Button appearance="ghost" onClick={onSubmit}>Convert to Audio</Button>
                  </div>}
                </div>
                {data != null && (
                  <div className="audio-player-box">
                    <AudioPlayer src={data.url} autoPlay={false} autoPlayAfterSrcChange={false} />
                  </div>
                )}
                {data != null && (
                  <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.3, ease: "easeOut" }}
                    className="pt-3"
                  >
                    <div className="msg-box" >
                      <img src={photos.success} />
                      <p className="heading">{audioData == null ? "Script Conversion Complete!" : "Upload Complete!"}</p>
                    </div>
                  </motion.div>
                )}
              </div>

            </div>

            {data == null && (
              <div className="btn-container">
                <Button
                  block
                  appearance="primary"
                  size="lg"
                  loading={loading}
                  onClick={onSubmit}
                  disabled={script == '' || scriptName == '' || selectedLang == null || voice == null}
                >
                  Convert to Audio
                </Button>
              </div>
            )}
            {data != null && (
              <motion.div className="btns-container">
                <div>
                  <Button block appearance="ghost" size="lg" onClick={onReset}>
                    Reset
                  </Button>
                </div>
                <div>
                  {audioData == null && (
                    <Button
                      block
                      appearance="primary"
                      size="lg"
                      loading={loading}
                      onClick={handleConfirm}
                    >
                      Great! Upload it
                    </Button>
                  )}
                  {audioData != null && comingFromFlow && (
                    <Button
                      block
                      appearance="primary"
                      size="lg"
                      loading={loading}
                      onClick={() => history.push("/flow-chart")}
                    >
                      Back to the Flow
                    </Button>
                  )}
                  {audioData != null && !comingFromFlow && (
                    <Button
                      block
                      appearance="primary"
                      size="lg"
                      loading={loading}
                      onClick={() => history.goBack()}
                    >
                      Go Back
                    </Button>
                  )}
                </div>
              </motion.div>
            )}
          </motion.div>
        </div>
        <div className="col-6">
          <div className="banner-box">
            <img src={photos.audioConversion} />
          </div>
        </div>
      </div>
    </div >
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.TextToSpeechReducer.loading,
    data: state.TextToSpeechReducer.data,
    audioData: state.AudioReducer.data,
    voiceData: state.VoicesReducer.data,
    voiceLoading: state.VoicesReducer.loading,
    langLoading: state.LanguageReducer.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    _textToSpeech: (data) => dispatch(action.textToSpeech(data)),
    _uploadAudio: (data, option) => dispatch(action.uploadAudio(data, option)),
    _resetAudio: () => dispatch(flow.resetFlowRedux()),
    _getVoices: (data) => dispatch(voiceAction.getVoices(data)),
    _handleChange: (data) => dispatch(changeAction.handleChange(data)),
    _resetVoice: () => dispatch(voiceAction.resetVoice())
  };
};

// const enhanceUpload=LangWrapper(UploadExternal)
TextToSpeech = connect(mapStateToProps, mapDispatchToProps)(TextToSpeech);

export default LangWrapper(TextToSpeech);
