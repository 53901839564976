/** @format */

import React, { useEffect, useState, Fragment } from "react";
import { DateRangePicker,DatePicker, Input,Button,Placeholder,SelectPicker,RadioGroup,Radio,CheckPicker} from "rsuite";
import { reduxForm, Field, change, reset } from "redux-form";
import { connect } from "react-redux";
import moment from "moment";
import * as action from "../../../redux/actions/start";
import axios from "axios";
const ranges = [
  {
    label: "Now",
    value: new Date(),
  },
];
const required = (value) =>
  value || typeof value === "number" ? undefined : "Required";
const renderDateRangePicker = (props) => {
  console.log("Render Input Props: ", props);
  return (
    <div>
      <DateRangePicker
        {...props}
        onChange={props.input.onChange}
        value={props.input.value}
        cleanable={false}
        block
        placeholder={props.placeholder}
        name={props.input.name}
        placement={props.placement}
        size="sm"
      />
      <p>{props.meta.touched ? props.meta.error : ""}</p>
    </div>
  );
};

const renderDatePicker = (props) => {
  return (
    <div>
      <DatePicker
        {...props}
        onChange={props.input.onChange}
        value={props.input.value}
        placeholder={props.placeholder}
        name={props.input.name}
        format="HH:mm"
        placement={props.placement}
        ranges={ranges}
        size="sm"
        block
        cleanable={false}
      />
      <p>{props.meta.touched ? props.meta.error : ""}</p>
    </div>
  );
};

const renderInput = (props) => {
  return (
    <div>
      <Input
        {...props.input}
        placeholder={props.placeholder}
        name={props.input.name}
        size="sm"
        block
      />
      <p>{props.meta.touched ? props.meta.error : ""}</p>
    </div>
  );
};

const renderSelectPicker = (props) => {
  console.log("Select Picker form: ", props);
  return (
    <div>
      <SelectPicker
        {...props}
        placeholder={props.placeholder}
        cleanable={false}
        name={props.input.name}
        block
        defaultValue={props.defaultValue}
        data={props.data}
        labelKey={props.labelKey}
        valueKey={props.valueKey}
        onSelect={(a, b, c) => props.onSelect(b)}
        size="sm"
      />
    </div>
  );
};

const renderCheckPicker=(props)=>{
  return(
    <div>
      <CheckPicker
      {...props}
      placeholder={props.placeholder}
      name={props.input.name}
      block
      defaultValue={props.defaultValue}
      data={props.data}
      labelKey={props.labelKey}
      valueKey={props.valueKey}
      onClean={props.onClean}
      onSelect={(a, b, c) => props.onSelect(b)}
      size="sm"
      />
    </div>
  )
}

let Start = (props) => {
  const {
    handleSubmit,
    valid,
    _handleCampaign,
    loading,
    _fetchCampaign,
    detail,
    dispatch,
    _fetchTimezone,
    timezone,
    prevChart,
    updateNodeRedux,
    flowLoader,
    _fetchSimCards,
    SimCards,
    pristine,
    submitting,
  } = props;
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [campaignName, setCampaignName] = useState("");
  const [totalRecord, setTotalRecord] = useState("");
  const [campaignType, setCampaignType] = useState(null)
  const [selectedSimCards,setSelectedSimCards]=useState([])
  const { Paragraph } = Placeholder;


  useEffect(()=>{
    _fetchCampaign();
  },[])

  const getSelectedSimCards=(item)=>{
    let data=selectedSimCards;
    data.push(item.id)
    setSelectedSimCards(data)
  }

  useEffect(() => {
    if (detail != null) {
      if(detail.campaign_type=="Inbound"){
        _fetchSimCards()
        
      }
      if(detail.campaign_type=="Outbound"){
        _fetchTimezone();
      }
      dispatch(change("Activity-Start-Form", "spin_rate", detail.spin_rate));
      dispatch(
        change(
          "Activity-Start-Form",
          "time_between_calls",
          detail.time_between_calls
        )
      );
      dispatch(
        change("Activity-Start-Form", "retry_times", detail.retry_times)
      );
      dispatch(
        change("Activity-Start-Form", "sim_cards", detail.sim_cards)
      );
      setCampaignName(detail.name);
      setTotalRecord(detail.total_record);
      let dateRange = [];
      dateRange.push(
        moment(
          detail.call_start_date != null ? detail.call_start_date : undefined
        ).format()
      );
      dateRange.push(
        moment(
          detail.call_end_date != null ? detail.call_end_date : undefined
        ).format()
      );
      dispatch(change("Activity-Start-Form", "date", dateRange));
      const fullDate = new Date();
      const startTime = moment(fullDate).format("L");
      const endTime = moment(fullDate).format("L");
      if (detail.call_start_time != null) {
        dispatch(
          change(
            "Activity-Start-Form",
            "start_time",
            moment(startTime + " " + detail.call_start_time).format()
          )
        );
      } else {
        dispatch(
          change("Activity-Start-Form", "start_time", moment().format())
        );
      }
      if (detail.call_end_time != null) {
        dispatch(
          change(
            "Activity-Start-Form",
            "end_time",
            moment(endTime + " " + detail.call_end_time).format()
          )
        );
      } else {
        dispatch(change("Activity-Start-Form", "end_time", moment().format()));
      }
    }
  }, detail);



  const handleForm = (value) => {
    console.log("Form Values: ", value);
    let key = JSON.parse(localStorage.getItem("campDetail"));
    console.log(
      "Selected Timezone: ",
      selectedTimezone?.id ||
      timezone.map((r) => {
        if (r.id == detail.timezone) {
          return r.id;
        }
      })
    );
    let data = {
      campaign_id: key.key,
      call_start_date: moment(value.date[0]).format("YYYY-MM-DD"),
      call_end_date: moment(value.date[1]).format("YYYY-MM-DD"),
      call_start_time: moment(value.start_time).format("H:mm:ss"),
      call_end_time: moment(value.end_time).format("H:mm:ss"),
      spin_rate: parseInt(value.spin_rate),
      time_between_calls: parseInt(value.time_between_calls),
      retry_times: parseInt(value.retry_times),
      timezone:
        selectedTimezone?.id ||
        selectedTimezone?.id ||
        timezone.map((r) => {
          if (r.id == detail.timezone) {
            return r.id;
          }
        })[0],
    };
    console.log("New Form Values: ", data);
    updateNodeRedux(prevChart, _handleCampaign, data);

  };

  const handleInboundData=(value)=>{
    let data={
      sim_cards:selectedSimCards,
      retry_times: parseInt(value.retry_times),
    }
    updateNodeRedux(prevChart, _handleCampaign, data);
  }

  return (
    <div className="start-activity-box-form">

      <Fragment>
        {detail!==null&& detail.campaign_type == "Outbound" && 
        <Fragment>
          <p>
            Campaign Name: <span>{campaignName}</span>
          </p>
          <p>
            Total Record: <span>{totalRecord}</span>
          </p>
          {detail != null ? (
            <form onSubmit={handleSubmit(handleForm)}>
              <div className="py-1">
                <label>Timezone</label>
                <Field component={renderSelectPicker} placeholder="Select Timezone" labelKey="name" valueKey="name"
                  defaultValue={props.detail != null ? props.detail.timezone_name : undefined}
                  onSelect={setSelectedTimezone}
                  data={timezone != null ? timezone : undefined}
                  name="timzone"
                />
              </div>
              <div className="py-1">
                <label>Campaign Date Range</label>
                <Field component={renderDateRangePicker} placement="leftStart" showOneCalendar name="date" className="date-range-picker"
                  validate={required}
                />
              </div>

              <div className="time-range">
                <label>Start Time</label>
                <Field component={renderDatePicker} validate={required} placeholder="Start Time" placement="leftStart" showMeridian
                  name="start_time"
                />
                <label>End Time</label>
                <Field
                  component={renderDatePicker}
                  validate={required}
                  placeholder="End Time"
                  placement="leftStart"
                  showMeridian
                  name="end_time"
                />
              </div>
              <div className="activity-input">
                <label>Spin Rate</label>
                <Field
                  component={renderInput}
                  validate={required}
                  placeholder="Spin Rate"
                  name="spin_rate"
                />
              </div>
              <div className="activity-input">
                <label>Time Between Calls</label>
                <Field
                  component={renderInput}
                  validate={required}
                  placeholder="Time Between Call in (Min.)"
                  name="time_between_calls"
                />
              </div>
              <div className="activity-input">
                <label>Retry Times</label>
                <Field
                  component={renderInput}
                  validate={required}
                  placeholder="Retry Times"
                  name="retry_times"
                />
              </div>
              <div className="confirm-box">
                <Button
                  appearance="primary"
                  type="submit"
                  size="lg"
                  color={flowLoader ? "yellow" : undefined}
                  loading={loading}
                  disabled={!valid && selectedTimezone == null || flowLoader}
                  block
                >
                  {flowLoader ? "Flow is being Saved..." : "Confirm"}
                </Button>
              </div>
            </form>
          ) : (
              <div>
                <Paragraph
                  rows={8}
                  active
                  rowMargin={40}
                  columns={1}
                  rowHeight={20}
                />
              </div>
            )}
        </Fragment>}
        {detail!=null&&detail.campaign_type=="Inbound"&&
        <Fragment>
            <p>
            Campaign Name: <span>{campaignName}</span>
          </p>
          <form onSubmit={handleSubmit(handleInboundData)} >
            <div className="activity-input">
            <label>Select Sim Cards</label>
          <Field component={renderCheckPicker} placeholder="Sim Cards" labelKey="phone_no" valueKey="id"
                  defaultValue={props.detail != null ? props.detail.sim_cards : undefined}
                  onSelect={getSelectedSimCards}  onClean={()=>setSelectedSimCards([])}
                  data={SimCards || []}
                  name="sim_cards"
                />  
            </div>
            <div className="activity-input">
            <div className="activity-input">
                <label>Retry Times</label>
                <Field
                  component={renderInput}
                  validate={required}
                  placeholder="Retry Times"
                  name="retry_times"
                />
              </div>
            </div>
            <div className="confirm-box">
                <Button
                  appearance="primary"
                  type="submit"
                  size="lg"
                  color={flowLoader ? "yellow" : undefined}
                  loading={loading}
                  disabled={flowLoader}
                  block
                >
                  {flowLoader ? "Flow is being Saved..." : "Confirm"}
                </Button>
              </div>
          
          </form>

        </Fragment>
        }
      </Fragment>
    </div>


  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.StartReducer.loading,
    detail: state.StartReducer.data,
    timezone: state.StartReducer.timezone,
    SimCards:state.SimCardReducer.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    _handleCampaign: (data) => dispatch(action.handleCampaign(data)),
    _fetchCampaign: () => dispatch(action.fetchCampaign()),
    _fetchTimezone: () => dispatch(action.fetchTimezone()),
    _fetchSimCards:()=>dispatch(action.fetchSimCards())
  };
};

Start = connect(mapStateToProps, mapDispatchToProps)(Start);

export default reduxForm({
  form: "Activity-Start-Form",
  enableReinitialize: true,
  // initialValues:{
  //     spin_rate:
  // }
})(Start);
